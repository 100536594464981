import { Flip, ToastContainer } from "react-toastify";
import { FC } from "react";

const NotificationBanner: FC = (): JSX.Element => (
    <ToastContainer
        transition={Flip}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        autoClose={false}
        icon={false}
    />
);

export default NotificationBanner;
