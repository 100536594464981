import { FC } from "react";

const TwitterIcon: FC = () => (
    <svg
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13.6466 2.30915C13.3026 2.76805 12.8868 3.16835 12.4151 3.49459C12.4151 3.61447 12.4151 3.73435 12.4151 3.86088C12.4189 6.03783 11.5494 8.12523 10.0014 9.65515C8.45344 11.1851 6.35657 12.0295 4.18087 11.9992C2.92305 12.0034 1.68133 11.7162 0.552995 11.16C0.492155 11.1335 0.452914 11.0733 0.453145 11.0069V10.9336C0.453145 10.838 0.530633 10.7604 0.626218 10.7604C1.86263 10.7197 3.05493 10.291 4.03442 9.53504C2.9153 9.5125 1.90838 8.84953 1.44499 7.83013C1.42158 7.77445 1.42887 7.71059 1.46422 7.66162C1.49957 7.61265 1.55787 7.58565 1.61806 7.59038C1.95818 7.62456 2.30171 7.59291 2.62987 7.49714C1.39445 7.24069 0.466177 6.21506 0.333326 4.95975C0.328604 4.89953 0.35559 4.8412 0.404536 4.80584C0.453481 4.77047 0.517311 4.76318 0.572965 4.7866C0.904497 4.93289 1.26238 5.00994 1.62472 5.01303C0.542198 4.30252 0.0746225 2.95111 0.486429 1.72308C0.528937 1.60376 0.631062 1.5156 0.755276 1.49102C0.87949 1.46644 1.00747 1.50906 1.09218 1.60321C2.55297 3.15793 4.56012 4.08437 6.69043 4.18721C6.63586 3.96947 6.60902 3.74571 6.61055 3.52123C6.63047 2.3442 7.35875 1.29567 8.45443 0.866533C9.55011 0.437395 10.7963 0.712577 11.6097 1.56325C12.1641 1.45763 12.7001 1.2714 13.2006 1.01049C13.2373 0.987595 13.2838 0.987595 13.3205 1.01049C13.3433 1.04717 13.3433 1.09368 13.3205 1.13036C13.078 1.68553 12.6685 2.15116 12.1489 2.46232C12.6039 2.40956 13.0509 2.30223 13.4802 2.14265C13.5164 2.11804 13.5639 2.11804 13.6 2.14265C13.6303 2.15651 13.653 2.183 13.6619 2.21509C13.6709 2.24717 13.6653 2.28157 13.6466 2.30915Z"
            fill="#65849A"
        />
    </svg>
);

export default TwitterIcon;
