import {
    AlertBannerIcon,
    AlertErrorIcon,
    AlertInfoIcon,
    AlertSuccessIcon,
    AlertWarningIcon,
} from "icons/ts/AlertIcon";
import { toast, ToastOptions } from "react-toastify";

export enum BannerType {
    Success,
    Info,
    Warning,
    Error,
    Notification,
}

export interface BannerInformation {
    level: BannerType;
    msg: string;
}

export const displaySuccessBanner = (msg: string): void => {
    const content = (
        <div data-testid="banner">
            <AlertSuccessIcon />
            {msg}
        </div>
    );
    toast.success(content, { toastId: "successBanner", autoClose: 3000 });
};

export const displayInfoBanner = (msg: string): void => {
    const content = (
        <div data-testid="banner">
            <AlertInfoIcon />
            {msg}
        </div>
    );
    toast.info(content, { toastId: "infoBanner", autoClose: 3000 });
};

export const displayWarningBanner = (msg: string): void => {
    const content = (
        <div data-testid="banner">
            <AlertWarningIcon />
            {msg}
        </div>
    );
    toast.warning(content, {
        toastId: "warningBanner",
        autoClose: 3000,
    });
};

export const displayErrorBanner = (msg: string): void => {
    const content = (
        <div data-testid="banner">
            <AlertErrorIcon />
            {msg}
        </div>
    );
    toast.error(content, { toastId: "errorBanner", autoClose: false });
};

export const displayCustomBanner = (
    content: JSX.Element,
    toastProps?: ToastOptions
): void => {
    toast(content, {
        toastId: "customBanner",
        autoClose: 3000,
        ...toastProps,
    });
};

const displayNotificationBanner = (msg: string): void => {
    const content = (
        <div data-testid="banner">
            <AlertBannerIcon />
            {msg}
        </div>
    );
    toast.dark(content, { toastId: "notificationBanner", autoClose: 3000 });
};

export default displayNotificationBanner;
