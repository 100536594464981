/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import { TableToggleCommonProps } from "react-table";
import { forwardRef, useRef, useEffect } from "react";

const TableCheckbox = forwardRef<HTMLInputElement, TableToggleCommonProps>(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <input
                className="mhxs"
                type="checkbox"
                ref={resolvedRef}
                {...rest}
            />
        );
    }
);

export default TableCheckbox;
