import { FC } from "react";

const BackIcon: FC = () => (
    <svg
        width="12"
        height="11"
        viewBox="0 0 12 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.29369 0.739957C5.35628 0.676853 5.44148 0.641357 5.53036 0.641357C5.61924 0.641357 5.70444 0.676853 5.76703 0.739957L5.90036 0.87329C5.96547 0.936007 6.00167 1.0229 6.00036 1.11329V3.49996H11.0004C11.1845 3.49996 11.3337 3.6492 11.3337 3.83329V7.16662C11.3337 7.35072 11.1845 7.49996 11.0004 7.49996H6.00036V9.88662C6.00167 9.97701 5.96547 10.0639 5.90036 10.1266L5.76703 10.26C5.70444 10.3231 5.61924 10.3586 5.53036 10.3586C5.44148 10.3586 5.35628 10.3231 5.29369 10.26L0.767028 5.73329C0.702577 5.6728 0.666016 5.58835 0.666016 5.49996C0.666016 5.41157 0.702577 5.32711 0.767028 5.26662L5.29369 0.739957Z"
            fill="#007DB3"
        />
    </svg>
);

export default BackIcon;
