import { FC } from "react";

const IVerifyLogo: FC = () => (
    <>
        <svg width="35" height="36" viewBox="0 0 35 36">
            <path
                d="M5.23828 22.0449H0.5C0.223858 22.0449 0 22.2688 0 22.5449V35.1081C0 35.3843 0.223857 35.6081 0.5 35.6081H5.23828C5.51442 35.6081 5.73828 35.3843 5.73828 35.1081V22.5449C5.73828 22.2688 5.51442 22.0449 5.23828 22.0449Z"
                fill="#E44968"
            />
            <path
                d="M16.6782 35.6081C16.468 35.6081 16.2802 35.4766 16.2083 35.279L9.61913 17.1574C9.54728 16.9598 9.35949 16.8283 9.14923 16.8283H0.5C0.223858 16.8283 0 16.6044 0 16.3283V11.59C0 11.3139 0.223858 11.09 0.5 11.09H13.2147C13.425 11.09 13.6128 11.2216 13.6846 11.4192L18.7446 25.3346C18.9042 25.7734 19.5249 25.7734 19.6844 25.3346L28.7282 0.464261C28.8 0.266667 28.9878 0.135132 29.1981 0.135132H34.2862C34.6331 0.135132 34.8746 0.479909 34.7561 0.806L22.2207 35.279C22.1489 35.4766 21.9611 35.6081 21.7508 35.6081H16.6782Z"
                fill="white"
            />
        </svg>
    </>
);

export default IVerifyLogo;
