import { DEFAULT_PAGE_SIZE, MAXIMUM_PAGE_SIZE } from "main/app/types";
import { FC } from "react";
import Pagination from "./Pagination";

interface TableFooterProps {
    totalPages: number;
    currentPage: number;
    pageSize: number;
    paginate: (pageIndex: number) => void;
    setPageSize: (pageSize: number) => void;
}

const TableFooter: FC<TableFooterProps> = (props: TableFooterProps) => {
    const { totalPages, currentPage, pageSize, paginate, setPageSize } = props;
    const pageSizeRanges = [20, 30, 40, DEFAULT_PAGE_SIZE, MAXIMUM_PAGE_SIZE];

    return (
        <div className="sticky z-10 bottom-0 mt-0 h-[60px] grid lg:grid-cols-4 grid-cols-3 gap-4 color-bg-light-m-1">
            <>
                <div className="lg:block hidden" />
                <div className="col-span-2 flex justify-center gap-x-2 items-center">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        paginate={paginate}
                    />
                </div>

                <div className="justify-self-end flex gap-x-2 items-center pr-4">
                    <div className="dashboard-dropdown-label">
                        Rows per page:
                    </div>
                    <select
                        data-testid="page-size-select"
                        className="h-8 input-border rounded-sm"
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {pageSizeRanges.map((pSize) => (
                            <option key={pSize} value={pSize}>
                                {pSize}
                            </option>
                        ))}
                        {pageSize && !pageSizeRanges.includes(pageSize) && (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        )}
                    </select>
                </div>
            </>
        </div>
    );
};

export default TableFooter;
