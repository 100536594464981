import NotificationBanner from "main/NotificationBanner";
import IVerifyDark from "icons/ts/iVerifyDark";
import { FC, PropsWithChildren } from "react";

const TitleLayout: FC<PropsWithChildren<unknown>> = ({
    children,
}: PropsWithChildren<unknown>) => (
    <div className="flex-col flex-1 color-bg-light">
        <NotificationBanner />
        <div className="flex-row flex-hc mtxl">
            <div className="mtl text-center">
                <IVerifyDark />
                <div className="color-mid-p-1 h6 spaced mts mbl">
                    ADMIN DASHBOARD
                </div>
                {children}
            </div>
        </div>
    </div>
);

export default TitleLayout;
