import { Link } from "components/Link";
import Tooltip from "components/Tooltip/Tooltip";
import { Button } from "components/button/Button";
import { LabeledInput, SelectInput } from "components/input/Input";
import { useConfirmationModal } from "components/modal/Modal";
import CheckmarkIcon from "icons/ts/CheckmarkIcon";
import CopyIcon from "icons/ts/CopyIcon";
import EyeClosedIcon from "icons/ts/EyeClosedIcon";
import EyeOpenIcon from "icons/ts/EyeOpenIcon";
import { AppRoutes } from "main/app/App";
import { ApiTokenExpiration, ApplicationState } from "main/app/types";
import Analytics, { Events } from "main/utils/Analytics";
import { isBeforeCurrentDate, toHumanReadableDate } from "main/utils/utils";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useApiConfig } from "./hooks";

const ApiSettings: React.FC = () => {
    const session = useSelector((state: ApplicationState) => state.session);

    const {
        loading: apiLoading,
        apiConfig,
        getApiConfig,
        generateApiConfig,
        revokeApiToken,
    } = useApiConfig();

    const { token, expiresAt } = apiConfig || {};
    const [tokenExpiration, setTokenExpiration] = useState<ApiTokenExpiration>(
        ApiTokenExpiration.ONE_YEAR
    );

    const [isTokenCopied, setIsTokenCopied] = useState<boolean>(false);
    const [showToken, setShowToken] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(
        null
    ) as MutableRefObject<HTMLInputElement>;

    const regenrateApiTokenModalProps = {
        title: "Re-generate API Key",
        message:
            "Are you sure you want to generate a new API token?\n All services using the current API key will be invalidated.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Re-generate",
    };

    const revokeApiTokenModalProps = {
        title: "Revoke API Access",
        message:
            "Are you sure you want to revoke your access to the API?\nAll services using the current API key will be revoked.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Revoke",
    };

    const {
        showModal: showRegenerateApiTokenModal,
        modal: regenerateApiTokenModal,
    } = useConfirmationModal({ ...regenrateApiTokenModalProps });

    const { showModal: showRevokeApiTokenModal, modal: revokeApiTokenModal } =
        useConfirmationModal({ ...revokeApiTokenModalProps });

    useEffect(() => {
        if (session?.portalPreferences?.apiAccess) {
            getApiConfig();
        }
    }, [getApiConfig]);

    useEffect(() => {
        const handleDoubleClick = () => {
            inputRef.current.setSelectionRange(
                0,
                inputRef.current.value.length
            );
        };

        if (inputRef.current) {
            inputRef.current.addEventListener("dblclick", handleDoubleClick);
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener(
                    "dblclick",
                    handleDoubleClick
                );
            }
        };
    }, [inputRef, token]);

    const handleApiTokenGeneration = () => {
        generateApiConfig(tokenExpiration);
        setShowToken(true);
    };

    const handleApiTokenCopy = () => {
        navigator.clipboard.writeText(token as string);
        setIsTokenCopied(true);
    };

    const apiInputIcons = useMemo(
        () => (
            <>
                {showToken ? (
                    <EyeClosedIcon
                        data-testid="eye-closed-icon"
                        onClick={() => setShowToken(false)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                ) : (
                    <EyeOpenIcon
                        data-testid="eye-open-icon"
                        onClick={() => setShowToken(true)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                )}

                {isTokenCopied ? (
                    <CheckmarkIcon
                        data-testid="checkmark-icon"
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="copied"
                        color="#09AA71"
                        className="absolute pl-1 -ml-9 mt-3"
                    />
                ) : (
                    <CopyIcon
                        data-testid="copy-icon"
                        onClick={handleApiTokenCopy}
                        className="absolute pl-1 -ml-9 mt-3 hover:cursor-pointer"
                        color="#007DB2"
                    />
                )}
                <Tooltip
                    isOpen={isTokenCopied}
                    id="copy-tooltip"
                    afterShow={() =>
                        setTimeout(() => {
                            setIsTokenCopied(false);
                        }, 5000)
                    }
                />
            </>
        ),
        [token, showToken, isTokenCopied]
    );

    const generateApiToken = () => {
        if (token) {
            showRegenerateApiTokenModal();
        } else {
            handleApiTokenGeneration();
        }
        Analytics.event(Events.generate_api_token);
    };

    const revokeApiAccess = () => {
        showRevokeApiTokenModal();
        Analytics.event(Events.revoke_api_token);
    };

    const expiresAtDate = useMemo(() => {
        if (!expiresAt) {
            return null;
        }

        const isExpired = isBeforeCurrentDate(expiresAt as string);
        const label = isExpired ? "Expired" : "Expires";
        const colorClass = isExpired ? "color-critical-lt" : "color-mid-m-1";

        return (
            <div className={`font-semibold italic ${colorClass}`}>
                {`${label} on ${toHumanReadableDate(expiresAt)}`}
            </div>
        );
    }, [expiresAt]);

    const apiSettings = useMemo(
        () => (
            <>
                <div className="flex-col my-3 rounded-md border border-light-m-1">
                    <div className="heading-one px-5 py-4 color-bg-light-p-1">
                        API settings
                    </div>
                    <div className="flex-col justify-between px-5 pt-4 w-5/12">
                        {token && (
                            <>
                                <LabeledInput
                                    ref={inputRef}
                                    name="api-token"
                                    label="API Key"
                                    value={
                                        showToken
                                            ? token
                                            : "**********************************"
                                    }
                                    error={expiresAtDate}
                                    icon={apiInputIcons}
                                />
                            </>
                        )}

                        <div className="flex flex-row gap-2 items-center">
                            <SelectInput
                                label="Expires"
                                data-testid="expires-dropdown"
                                value={tokenExpiration}
                                className="mb-0"
                                onChange={(e) =>
                                    setTokenExpiration(
                                        e.target.value as ApiTokenExpiration
                                    )
                                }
                            >
                                <option value={ApiTokenExpiration.SIX_MONTHS}>
                                    6 months
                                </option>
                                <option value={ApiTokenExpiration.ONE_YEAR}>
                                    1 year
                                </option>
                                <option value={ApiTokenExpiration.NEVER}>
                                    Never
                                </option>
                            </SelectInput>
                            <Button
                                appearance="primary"
                                label={`${
                                    token ? "Re-generate" : "Generate"
                                } API Key`}
                                className="flex-auto"
                                onClick={generateApiToken}
                                disabled={apiLoading}
                            />
                        </div>
                        {tokenExpiration === ApiTokenExpiration.NEVER && (
                            <div className="font-semibold pbm -mt-2.5 color-mid-m-1">
                                Consider choosing 1-year token to rotate
                                credentials periodically
                            </div>
                        )}

                        {token && (
                            <Button
                                appearance="alert"
                                label="Revoke API Access"
                                className="mbm flex-auto"
                                onClick={revokeApiAccess}
                                disabled={apiLoading}
                            />
                        )}
                        <p className="color-mid-m-1 text-sm font-semibold pb-4">
                            Please reference{" "}
                            <Link
                                to={{
                                    pathname: AppRoutes.apiDocsPath,
                                }}
                            >
                                API Documentation
                            </Link>
                        </p>
                    </div>
                </div>
            </>
        ),
        [
            token,
            expiresAt,
            showToken,
            tokenExpiration,
            inputRef,
            apiInputIcons,
            apiLoading,
        ]
    );

    return (
        <>
            {regenerateApiTokenModal(handleApiTokenGeneration)}
            {revokeApiTokenModal(revokeApiToken)}
            {session?.portalPreferences?.apiAccess ? apiSettings : null}
        </>
    );
};

export default ApiSettings;
