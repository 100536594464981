import Cookies from "js-cookie";
import { useState } from "react";

const SSO_ONBOARDING_COOKIE = "sso-onboarding";
export const redirectToAddMembers = (): boolean =>
    Cookies.get(SSO_ONBOARDING_COOKIE) === "true";

export const setRedirectToAddMembers = (status: boolean): void => {
    Cookies.set(SSO_ONBOARDING_COOKIE, `${status}`);
};

const HIDE_ADD_MEMBERS_HINT = "hide-add-members-hint";
export interface UseAddMembersHintHook {
    shouldHideAddMembersHint: boolean;

    setHideAddMembersHint: () => void;
}
export const useAddMembersHint = (): UseAddMembersHintHook => {
    const cookieState = () => Cookies.get(HIDE_ADD_MEMBERS_HINT) === "true";
    const [shouldHideAddMembersHint, updateHide] = useState<boolean>(
        cookieState()
    );

    const setHideAddMembersHint = () => {
        updateHide(true);
        Cookies.set(HIDE_ADD_MEMBERS_HINT, `true`, { expires: 3650 });
    };

    return { shouldHideAddMembersHint, setHideAddMembersHint };
};
