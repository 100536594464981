import LoadingBar from "components/LoadingBar/LoadingBar";
import Tooltip from "components/Tooltip/Tooltip";
import { Button } from "components/button/Button";
import { LabeledInput, SelectInput, Textarea } from "components/input/Input";
import { Modal } from "components/modal/Modal";
import InfoIcon from "icons/ts/InfoIcon";
import {
    DeviceIntegrityPeriod,
    EmailTemplateType,
    EnrollmentEmailFrequency,
    NotificationFrequency,
    InternalNotificationTopic,
    NotificationType,
    OutdatedOsEmailsFrequency,
} from "main/app/types";
import usePageTitle from "main/utils/usePageTitle";
import { cleanTextInput } from "main/utils/utils";
import { FormEvent, useEffect, useMemo, useState } from "react";
import { useNotificationsHook } from "./hooks";

const NotificationsTab: React.FC = () => {
    usePageTitle("Notifications Settings");

    const {
        loading,
        emailPreferences,
        activeEmailTemplate,
        notificationsPreferences,
        getEmailPreferences,
        getEmailTemplate,
        getNotificationsPreferences,
        updateEmailPreferences,
        updateEmailTemplate,
        updateNotificationsPreferences,
    } = useNotificationsHook();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const [currentSubject, setCurrentSubject] = useState(
        activeEmailTemplate.subject
    );
    const [currentBody, setCurrentBody] = useState(activeEmailTemplate.body);

    useEffect(() => {
        getEmailPreferences();
        getNotificationsPreferences(InternalNotificationTopic.OPEN_APP_TO_SCAN);
    }, []);

    useEffect(() => {
        setCurrentSubject(activeEmailTemplate.subject);
        setCurrentBody(activeEmailTemplate.body);
    }, [activeEmailTemplate]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        const cleanSubject = currentSubject
            ? cleanTextInput(currentSubject)
            : null;
        const cleanBody = currentBody ? cleanTextInput(currentBody) : null;

        updateEmailTemplate({
            ...activeEmailTemplate,
            subject: cleanSubject !== "" ? cleanSubject : null,
            body: cleanBody !== "" ? cleanBody : null,
        });

        setIsModalOpen(false);
    };

    const handleEmailTemplateModal = () => {
        getEmailTemplate(EmailTemplateType.ORG_ENROLLMENT);
        setIsModalOpen(true);
    };

    const emailSettings = useMemo(
        () => (
            <div className="flex-col my-3 rounded-md border border-light-m-1">
                <div className="heading-one px-5 py-4 color-bg-light-p-1">
                    Email settings
                </div>
                {emailPreferences.enrollmentEmailsFrequency && (
                    <div className="flex-row justify-between items-center px-5 py-4">
                        <div>
                            <div className="heading-three py-1">
                                Enrollment Emails
                            </div>
                            <p>
                                Set user onboarding enrollment email frequency
                            </p>
                        </div>

                        <div className="flex-row">
                            <Button
                                className="px-3 ivy-button--tertiary mr-2"
                                label="Customise email"
                                onClick={handleEmailTemplateModal}
                            />
                            <SelectInput
                                data-testid="enrollment-emails-dropdown"
                                value={
                                    emailPreferences.enrollmentEmailsFrequency
                                }
                                className="mb-0"
                                onChange={(e) =>
                                    updateEmailPreferences({
                                        ...emailPreferences,
                                        enrollmentEmailsFrequency: e.target
                                            .value as EnrollmentEmailFrequency,
                                    })
                                }
                            >
                                <option value={EnrollmentEmailFrequency.ONCE}>
                                    Once
                                </option>
                                <option value={EnrollmentEmailFrequency.WEEKLY}>
                                    Once a week
                                </option>
                                <option
                                    value={
                                        EnrollmentEmailFrequency.EVERY_SECOND_DAY
                                    }
                                >
                                    Every second day
                                </option>
                                <option value={EnrollmentEmailFrequency.NEVER}>
                                    Never
                                </option>
                            </SelectInput>
                            <div className="mt-3.5">
                                <InfoIcon
                                    body={
                                        <span className="text-sm">
                                            <p className="px-1 pt-1">
                                                Sets user onboarding enrollment
                                                email frequency to one of the
                                                following options:
                                            </p>
                                            <ul className="px-1 pb-1">
                                                <li>
                                                    <b>Once</b> &#8212; only
                                                    send email during invitation
                                                </li>
                                                <li>
                                                    <b>Once a week</b> &#8212;
                                                    email users when they are
                                                    invited & every week after
                                                    that until they onboard
                                                </li>
                                                <li>
                                                    <b>Every second day</b>{" "}
                                                    &#8212; email users when
                                                    they are invited & every
                                                    other day after that until
                                                    they onboard
                                                </li>
                                                <li>
                                                    <b>Never</b> &#8212; new
                                                    onboarded users will never
                                                    receive an onboarding email
                                                </li>
                                            </ul>
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}

                {emailPreferences.threatAlerts !== null && (
                    <div className="flex-row justify-between items-center px-5 py-4">
                        <div>
                            <div className="heading-three py-1">
                                Threat Alert Emails
                            </div>
                            <p>
                                Set if admins should receive threat alert emails
                            </p>
                        </div>
                        <SelectInput
                            data-testid="threat-alerts-dropdown"
                            value={
                                emailPreferences.threatAlerts
                                    ? "enabled"
                                    : "disabled"
                            }
                            className="mb-0 w-40 mr-7"
                            onChange={(e) =>
                                updateEmailPreferences({
                                    ...emailPreferences,
                                    threatAlerts: e.target.value === "enabled",
                                })
                            }
                        >
                            <option value="enabled">Enabled</option>
                            <option value="disabled">Disabled</option>
                        </SelectInput>
                    </div>
                )}

                {emailPreferences.outdatedOsEmailsFrequency !== null && (
                    <div className="flex-row justify-between items-center px-5 py-4">
                        <div>
                            <div className="heading-three py-1">
                                Outdated OS Emails Frequency (Beta)
                            </div>
                            <p>
                                Users will receive emails when their devices
                                have outdated OS (iOS only)
                            </p>
                        </div>
                        <SelectInput
                            data-testid="outdated-os-emails-dropdown"
                            value={emailPreferences.outdatedOsEmailsFrequency}
                            className="mb-0 w-40 mr-7"
                            onChange={(e) =>
                                updateEmailPreferences({
                                    ...emailPreferences,
                                    outdatedOsEmailsFrequency: e.target
                                        .value as OutdatedOsEmailsFrequency,
                                })
                            }
                        >
                            <option value={OutdatedOsEmailsFrequency.DAILY}>
                                Daily
                            </option>
                            <option
                                value={
                                    OutdatedOsEmailsFrequency.EVERY_SECOND_DAY
                                }
                            >
                                Every second day
                            </option>
                            <option value={OutdatedOsEmailsFrequency.WEEKLY}>
                                Weekly
                            </option>
                            <option value={OutdatedOsEmailsFrequency.NEVER}>
                                Never
                            </option>
                        </SelectInput>
                    </div>
                )}
            </div>
        ),
        [emailPreferences]
    );

    const notificationDescriptionMessage = useMemo(() => {
        const {
            deviceIntegrityPeriod,
            notificationFrequency,
            notificationType,
        } = notificationsPreferences;

        if (deviceIntegrityPeriod === DeviceIntegrityPeriod.NEVER) {
            return <p>Scan reminder is disabled</p>;
        }

        const notificationFrequencyText =
            notificationFrequency &&
            {
                [NotificationFrequency.DAILY]: "day",
                [NotificationFrequency.EVERY_SECOND_DAY]: "2 days",
                [NotificationFrequency.WEEKLY]: "week",
            }[notificationFrequency];

        const checksFrequencyText =
            deviceIntegrityPeriod &&
            {
                [DeviceIntegrityPeriod.THREE_DAYS]: "3 days",
                [DeviceIntegrityPeriod.SEVEN_DAYS]: "7 days",
                [DeviceIntegrityPeriod.TWO_WEEKS]: "2 weeks",
            }[deviceIntegrityPeriod];

        const notificationTypeText =
            notificationType === NotificationType.PUSH ? "push" : "email";

        return (
            <p>
                Users will be notified with{" "}
                <b>
                    {notificationTypeText} notifications every{" "}
                    {notificationFrequencyText}
                </b>{" "}
                when no scans were received in the last{" "}
                <b>{checksFrequencyText}</b>
            </p>
        );
    }, [notificationsPreferences]);

    const notificationsSettings = useMemo(
        () => (
            <div className="flex-col my-3 rounded-md border border-light-m-1">
                <div className="flex-row justify-between px-5 py-4 color-bg-light-p-1">
                    <p className="heading-one">
                        Device Scan Notifications (Beta)
                    </p>
                    <div>{notificationDescriptionMessage}</div>
                </div>
                <div className="pb-5">
                    {notificationsPreferences.deviceIntegrityPeriod && (
                        <div className="flex-row justify-between items-center px-5 pt-4">
                            <div>
                                <div className="heading-three py-1">
                                    Device Scan Checks Frequency
                                </div>
                                <p>
                                    Users will receive emails or push
                                    notifications when they haven&apos;t scanned
                                    their devices for a selected interval
                                </p>
                            </div>

                            <div className="flex-row">
                                <SelectInput
                                    data-testid="device-integrity-period-dropdown"
                                    value={
                                        notificationsPreferences.deviceIntegrityPeriod
                                    }
                                    className="mb-0 w-40"
                                    onChange={(e) =>
                                        updateNotificationsPreferences({
                                            ...notificationsPreferences,
                                            deviceIntegrityPeriod: e.target
                                                .value as DeviceIntegrityPeriod,
                                        })
                                    }
                                >
                                    <option
                                        value={DeviceIntegrityPeriod.THREE_DAYS}
                                    >
                                        3 days
                                    </option>
                                    <option
                                        value={DeviceIntegrityPeriod.SEVEN_DAYS}
                                    >
                                        7 days
                                    </option>
                                    <option
                                        value={DeviceIntegrityPeriod.TWO_WEEKS}
                                    >
                                        2 weeks
                                    </option>
                                    <option value={DeviceIntegrityPeriod.NEVER}>
                                        Never
                                    </option>
                                </SelectInput>
                            </div>
                        </div>
                    )}

                    {notificationsPreferences.deviceIntegrityPeriod !==
                    DeviceIntegrityPeriod.NEVER ? (
                            <>
                                {notificationsPreferences.notificationFrequency && (
                                    <div className="flex-row justify-between items-center px-5 py-4">
                                        <div>
                                            <div className="heading-three py-1">
                                            Push Notifications Frequency
                                            </div>
                                            <p>
                                            Frequency of the notifications until
                                            users scan the device
                                            </p>
                                        </div>

                                        <div className="flex-row">
                                            <SelectInput
                                                data-testid="notification-frquency-dropdown"
                                                value={
                                                    notificationsPreferences.notificationFrequency
                                                }
                                                className="mb-0 w-40"
                                                onChange={(e) =>
                                                    updateNotificationsPreferences({
                                                        ...notificationsPreferences,
                                                        notificationFrequency: e
                                                            .target
                                                            .value as NotificationFrequency,
                                                    })
                                                }
                                            >
                                                <option
                                                    value={
                                                        NotificationFrequency.DAILY
                                                    }
                                                >
                                                Daily
                                                </option>
                                                <option
                                                    value={
                                                        NotificationFrequency.EVERY_SECOND_DAY
                                                    }
                                                >
                                                Every second day
                                                </option>
                                                <option
                                                    value={
                                                        NotificationFrequency.WEEKLY
                                                    }
                                                >
                                                Weekly
                                                </option>
                                            </SelectInput>
                                        </div>
                                    </div>
                                )}

                                {notificationsPreferences.notificationType && (
                                    <div className="flex-row justify-between items-center px-5">
                                        <div>
                                            <div className="heading-three py-1">
                                            Notification Delivery Mechanism
                                            </div>
                                            <p>
                                            Users will receive either email or
                                            push notifications in the app
                                            </p>
                                            {notificationsPreferences.notificationType ===
                                            NotificationType.PUSH && (
                                                <span className="flex-row items-center pt-1 italic color-mid-m-1">
                                                    <InfoIcon
                                                        color="#4E6A7E"
                                                        className="m-0"
                                                    />
                                                Devices with disabled push
                                                notifications will receive
                                                emails instead
                                                </span>
                                            )}
                                        </div>

                                        <div className="flex-row">
                                            <SelectInput
                                                data-testid="notification-type-dropdown"
                                                value={
                                                    notificationsPreferences.notificationType
                                                }
                                                className="mb-0 w-40"
                                                onChange={(e) =>
                                                    updateNotificationsPreferences({
                                                        ...notificationsPreferences,
                                                        notificationType: e.target
                                                            .value as NotificationType,
                                                    })
                                                }
                                            >
                                                <option
                                                    value={NotificationType.PUSH}
                                                >
                                                Push
                                                </option>
                                                <option
                                                    value={NotificationType.EMAIL}
                                                >
                                                Email
                                                </option>
                                            </SelectInput>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                </div>
            </div>
        ),
        [notificationsPreferences]
    );

    return (
        <>
            {loading ? (
                <LoadingBar />
            ) : (
                <>
                    <Modal
                        isOpen={isModalOpen}
                        closeModal={() => setIsModalOpen(false)}
                        title="Set Custom Enrollment Email"
                        message="Create a customized onboarding email and save it as a template to be used on every new onboarded member."
                    >
                        <form
                            data-testid="add-custom-email-form"
                            onSubmit={handleSubmit}
                            className="p-4"
                        >
                            <div className="flex-col">
                                <LabeledInput
                                    placeholder="Email subject"
                                    label="Subject"
                                    name="subject"
                                    type="text"
                                    value={currentSubject ?? ""}
                                    onChange={(e) =>
                                        setCurrentSubject(e.target.value)
                                    }
                                />
                                <Textarea
                                    placeholder="Email body..."
                                    label="Email Body (Max 3000 characters)"
                                    name="body"
                                    type="textarea"
                                    rows={8}
                                    value={currentBody ?? ""}
                                    onChange={(e) =>
                                        setCurrentBody(e.target.value)
                                    }
                                    maxLength={3000}
                                />
                                <div className="flex-row justify-between mt-3">
                                    <Button
                                        type="submit"
                                        appearance="primary"
                                        name="save-custom-template"
                                    >
                                        Save email template
                                    </Button>
                                    <Button
                                        className="px-3 ivy-button--tertiary-alert mr-2"
                                        label="Cancel"
                                        onClick={() => setIsModalOpen(false)}
                                    />
                                </div>
                            </div>
                        </form>
                    </Modal>

                    <div className="plm">
                        <Tooltip
                            id="notification-page-info-tooltip"
                            clickable
                        />
                        <div className="flex-col flex-vs ptm w-5/6">
                            {emailSettings}
                            {notificationsSettings}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default NotificationsTab;
