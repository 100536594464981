import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useDidMountEffect from "main/utils/useDidMountEffect";
import MultiSelectInput from "components/MultiSelectInput";
import { AlertFilters, AlertTableFilters, StatusFilter } from "./types";

export type FilterHook = {
    filterMenu: JSX.Element;
    filters: AlertTableFilters;
};

export const useFilter = (): FilterHook => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const filterParams = {
        status: Object.values(StatusFilter).includes(
            queryParams.get(AlertFilters.STATUS) as StatusFilter
        )
            ? (queryParams.get(AlertFilters.STATUS) as StatusFilter)
            : StatusFilter.NONE,
    };
    const [filters, setFilters] = useState<AlertTableFilters>(filterParams);

    useDidMountEffect(() => {
        const query = new URLSearchParams(location.search);
        if (filters.status === StatusFilter.NONE) {
            query.delete(AlertFilters.STATUS);
        } else {
            query.set(AlertFilters.STATUS, filters.status as string);
        }

        navigate({
            search: query.toString(),
        });
    }, [filters]);

    const handleStatusFilter = (selected: Record<string, boolean>) => {
        const { open, closed } = selected;
        let status = StatusFilter.NONE;

        if (open && !closed) {
            status = StatusFilter.OPEN;
        } else if (!open && closed) {
            status = StatusFilter.CLOSED;
        }

        setFilters({
            ...filters,
            status,
        });
    };

    const filterMenu = useMemo(
        () => (
            <>
                <div className="dashboard-dropdown-label">FILTERS</div>
                <MultiSelectInput
                    title={
                        // eslint-disable-next-line no-nested-ternary
                        filters.status === StatusFilter.OPEN
                            ? "Open alerts"
                            : filters.status === StatusFilter.CLOSED
                                ? "Closed alerts"
                                : "All alerts"
                    }
                    onSelect={handleStatusFilter}
                    options={[
                        {
                            label: "Open alerts",
                            value: "open",
                            selected: filters.status === StatusFilter.OPEN,
                        },
                        {
                            label: "Closed alerts",
                            value: "closed",
                            selected: filters.status === StatusFilter.CLOSED,
                        },
                    ]}
                />
            </>
        ),
        [filters]
    );

    return { filterMenu, filters };
};
