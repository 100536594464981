import { FC, FormEvent, useEffect, useRef } from "react";
import usePageTitle from "main/utils/usePageTitle";
import ConfigurationLayout from "main/ConfigurationLayout";
import { LabeledInput } from "components/input/Input";
import AddIcon from "icons/ts/AddIcon";
import { Button } from "components/button/Button";
import DeleteIcon from "icons/ts/DeleteIcon";
import { AppRoutes } from "main/app/App";
import { useNavigate } from "react-router-dom";
import { Member } from "main/add-members/types";
import useHistoryState from "main/utils/useHistoryState";
import { useForm } from "components/form/useForm";
import useEmailInput from "components/EmailInput/EmailInput";

const MemberManualUploadPage: FC = (): JSX.Element => {
    usePageTitle("Import Your Members to Enroll");

    const navigate = useNavigate();

    const { inputs, onChange, filled, valid, setValid, reset } = useForm({
        name: "",
        email: "",
    });

    const { emailInput, error: emailError } = useEmailInput();

    const [queuedMembers, setQueuedMembers] = useHistoryState(
        "queuedMembers",
        [] as Member[]
    );
    const addMemberForm = useRef<HTMLFormElement>(null);

    const handleAdd = (e: FormEvent) => {
        e.preventDefault();
        const memberToAdd = {
            name: inputs.name as string,
            email: inputs.email as string,
        };
        reset();

        const updatedQueuedMembers = queuedMembers.concat(memberToAdd);
        setQueuedMembers(updatedQueuedMembers);
        addMemberForm.current?.reset();
    };

    const onDelete = (remove: number) => {
        setQueuedMembers(queuedMembers.filter((_, idx) => idx !== remove));
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const members = queuedMembers.map(
            (member, index): Member => ({ ...member, id: `${index}` })
        );

        navigate(AppRoutes.memberConfirmPath, {
            state: { members, error: "", isUsingSso: false },
        });
    };

    useEffect(() => {
        setValid(filled && emailError === "");
    }, [filled, emailError]);

    return (
        <ConfigurationLayout>
            <div className="form-title min-width-large">
                Add Team Members: Manual
            </div>
            <div className="title-three flex-row flex-hc">
                <div className="mas">1. Enter Members</div>
                <div className="mas color-mid-p-1">2. Send Invitations</div>
            </div>
            <div className="form-instructions">
                You may add more users later from the dashboard.
            </div>
            <form onSubmit={handleAdd} onInput={onChange} ref={addMemberForm}>
                <div className="flex-row items-center">
                    <LabeledInput
                        placeholder="Full Name"
                        label="Full Name"
                        name="name"
                        type="text"
                        className="mrs flex-2"
                    />
                    <div className="flex-3 mrs">{emailInput}</div>
                    <Button
                        className="mb-2"
                        disabled={!valid}
                        name="add-member"
                        appearance="icon"
                        aria-label="Add"
                    >
                        <AddIcon />
                    </Button>
                </div>
            </form>
            <div className="flex-hc">
                <h2 className="label mtm color-mid-m-1">Queued to add</h2>
                <ul className="ivy-list">
                    {queuedMembers.map((member, index) => (
                        <li className="flex-row pvxs phxs" key={member.email}>
                            <div className="paragraph-two flex-2">
                                {member.name}
                            </div>
                            <div className="paragraph-five flex-3">
                                {member.email}
                            </div>
                            <div className="paragraph-five">
                                <Button
                                    onClick={() => {
                                        onDelete(index);
                                    }}
                                    type="submit"
                                    appearance="icon"
                                    className="icon-button"
                                    aria-label="Delete"
                                >
                                    <DeleteIcon />
                                </Button>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <form onSubmit={handleSubmit}>
                <Button
                    className="max-width"
                    label="Add to Organization"
                    disabled={queuedMembers.length === 0}
                />
            </form>
        </ConfigurationLayout>
    );
};

export default MemberManualUploadPage;
