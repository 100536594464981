import { AxiosError } from "axios";
import {
    ApplicationState,
    DEFAULT_PAGE,
    DEFAULT_PAGE_SIZE,
    PaginationQueryParams,
} from "main/app/types";
import { apiClient } from "main/utils/ApiClient";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import useDidMountEffect from "main/utils/useDidMountEffect";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { displaySuccessBanner } from "main/utils/displayBanner";
import { withEngSuffix } from "main/utils/utils";
import {
    AlertRow,
    AlertStatus,
    AlertTableFilters,
    PaginatedAlerts,
    StatusFilter,
} from "./types";
import { AlertsActions } from "./AlertsReducer";

export type AlertsHook = {
    loading: boolean;
    paginatedAlerts: PaginatedAlerts;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    fetchDashboardAlerts: (
        page?: number,
        pageSize?: number,
        filters?: AlertTableFilters
    ) => void;
    setCurrentPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    clearPaginatedAlerts: () => void;
};

export const useAlerts = (): AlertsHook => {
    const dispatch = useDispatch();
    const alerts = useSelector((state: ApplicationState) => state.alerts);
    const location = useLocation();
    const navigate = useNavigate();
    const { loading, execute: fetchAlerts } = useLoadingCall(
        apiClient.fetchAlerts
    );

    const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE);
    const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
    const { paginatedAlerts, totalPages } = alerts;

    useDidMountEffect(() => {
        const query = new URLSearchParams(location.search);
        query.set(PaginationQueryParams.PAGE, currentPage.toString());
        query.set(PaginationQueryParams.PAGE_SIZE, pageSize.toString());
        navigate({
            search: query.toString(),
        });
    }, [pageSize, currentPage]);

    const fetchDashboardAlerts = useCallback(
        (
            _page = DEFAULT_PAGE,
            _pSize = DEFAULT_PAGE_SIZE,
            filters: AlertTableFilters = {}
        ) => {
            const pageFilters = { ...filters };
            if (pageFilters.status === StatusFilter.NONE) {
                delete pageFilters.status;
            }

            fetchAlerts(pageFilters)
                .then((fetchedAlerts: AlertRow[]) => {
                    dispatch({
                        type: AlertsActions.FetchAlerts,
                        page: 1,
                        alerts: fetchedAlerts,
                        totalPages: 1,
                    });
                })
                .catch((e: AxiosError) => {
                    handleResponse(e, "Failed to get alerts");
                    dispatch({
                        type: AlertsActions.FetchAlerts,
                        page: 1,
                        alerts: [],
                        totalPages: 1,
                    });
                });
        },
        []
    );

    const clearPaginatedAlerts = useCallback(() => {
        dispatch({ type: AlertsActions.ClearPages });
    }, []);

    return {
        loading,
        paginatedAlerts,
        totalPages,
        currentPage,
        pageSize,
        fetchDashboardAlerts,
        setCurrentPage,
        setPageSize,
        clearPaginatedAlerts,
    };
};

export type UpdateAlertStatusHook = {
    loading: boolean;
    updateAlertStatus: (
        rowSelections: AlertRow[],
        status: AlertStatus,
        filters: AlertTableFilters
    ) => void;
};

export const useUpdateAlertStatus = (): UpdateAlertStatusHook => {
    const { loading, execute } = useLoadingCall(apiClient.updateAlertStatus);
    const { currentPage, pageSize, fetchDashboardAlerts } = useAlerts();

    const updateAlertStatus = useCallback(
        (
            rowSelections: AlertRow[],
            status: AlertStatus,
            filters: AlertTableFilters
        ) => {
            const alertIds = rowSelections.map((d) => d.scanId);
            const alertCount = alertIds.length;
            const statusType = Object.keys(AlertStatus).find(
                (key) => AlertStatus[key as keyof typeof AlertStatus] === status
            );

            execute(alertIds, statusType)
                .then(() => {
                    fetchDashboardAlerts(currentPage, pageSize, filters);
                    displaySuccessBanner(
                        `Updated ${alertCount.toLocaleString()} ${withEngSuffix(
                            "alert",
                            alertCount
                        )}`
                    );
                })
                .catch((e: AxiosError) => {
                    handleResponse(
                        e,
                        `Failed to update ${withEngSuffix(
                            "alert",
                            alertCount
                        )}. Please try again`
                    );
                });
        },
        [execute]
    );

    return { loading, updateAlertStatus };
};
