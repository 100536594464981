import Header from "components/header/Header";
import NotificationBanner from "main/NotificationBanner";
import { FC, HTMLAttributes } from "react";

export interface HeaderLayoutProps extends HTMLAttributes<HTMLDivElement> {}

const HeaderLayout: FC<HeaderLayoutProps> = (
    props: HeaderLayoutProps
): JSX.Element => {
    const { children } = props;
    return (
        <>
            <Header />
            <NotificationBanner />
            {children}
        </>
    );
};

export default HeaderLayout;
