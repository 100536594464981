import { ComponentType, FC, HTMLProps, useState } from "react";
import ReactModal from "react-modal";
import CancelButton from "components/CancelButton/CancelButton";
import { Button } from "components/button/Button";
import "./Modal.scss";

export interface ModalProps extends HTMLProps<HTMLElement> {
    title: string;
    message: string;
    isOpen: boolean;
    closeModal: () => void;
}

export interface ConfirmModalProps extends HTMLProps<HTMLElement> {
    /**
     * The Modal title
     */
    title: string;

    /**
     * The message text
     */
    message: string;

    /**
     * The alert text
     */
    alert?: string;

    /**
     * Should the modal display
     */
    isOpen: boolean;
    /**
     * When confirm is clicked
     */
    onConfirm: () => void;
    /**
     * Close the modal
     */
    closeModal: () => void;
    /**
     * Cancel the modal
     */
    cancelModal: () => void;
    /**
     * Button label to do nothing
     */
    cancelLabel: string;
    /**
     * Button label for confirm action
     */
    confirmLabel: string;
    /**
     * Possible Modal Body
     */
    body?: JSX.Element;
}

export const Modal: FC<ModalProps> = ({
    children,
    title,
    message,
    isOpen,
    closeModal,
}: ModalProps) => {
    const ModalUI = ReactModal as ComponentType<ReactModal["props"]>;

    return (
        <ModalUI isOpen={isOpen} contentLabel={title}>
            <div>
                <CancelButton
                    data-testid="close-modal"
                    className="modal-cancel color-mid"
                    onClick={closeModal}
                />

                <h2 className="title-two text-center color-mid mvl">{title}</h2>

                <p className="paragraph-one text-center mts color-dark-p-2 multiline">
                    {message}
                </p>

                {children}
            </div>
        </ModalUI>
    );
};

export const ConfirmationModal: FC<ConfirmModalProps> = ({
    title,
    message,
    alert,
    isOpen,
    onConfirm,
    closeModal,
    cancelModal,
    cancelLabel,
    confirmLabel,
    body,
}: ConfirmModalProps) => {
    const onConfirmClicked = () => {
        closeModal();
        onConfirm();
    };

    return (
        <Modal
            title={title}
            message={message}
            isOpen={isOpen}
            closeModal={cancelModal}
        >
            <>
                {alert && (
                    <p className="paragraph-three color-critical-lt text-center mtm multiline">
                        {alert}
                    </p>
                )}
                {body}
                <div className="flex-row flex-hc mvxl">
                    <Button
                        className="mrm"
                        appearance="secondary"
                        onClick={cancelModal}
                        label={cancelLabel}
                    />
                    <Button
                        data-testid="confirm-button"
                        className="ivy-button--alert"
                        onClick={onConfirmClicked}
                        label={confirmLabel}
                    />
                </div>
            </>
        </Modal>
    );
};

interface ConfirmationModalHook {
    showModal: () => void;
    modal: (onConfirm: () => void) => JSX.Element;
}

export interface ModalState {
    title: string;
    message: string;
    alert?: string;
    cancelLabel: string;
    confirmLabel: string;
    body?: JSX.Element;
    onCancelModal?: () => void;
}

export const useConfirmationModal = (
    props: ModalState
): ConfirmationModalHook => {
    const {
        title,
        message,
        alert,
        cancelLabel,
        confirmLabel,
        body,
        onCancelModal,
    } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const showModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const cancelModal = () => {
        setIsOpen(false);
        if (onCancelModal) {
            onCancelModal();
        }
    };

    const modal = (onConfirm: () => void) => (
        <ConfirmationModal
            isOpen={isOpen}
            onConfirm={onConfirm}
            closeModal={closeModal}
            cancelModal={cancelModal}
            title={title}
            message={message}
            alert={alert}
            cancelLabel={cancelLabel}
            confirmLabel={confirmLabel}
            body={body}
        />
    );

    return { showModal, modal };
};
