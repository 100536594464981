import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { apiClient } from "main/utils/ApiClient";
import LoadingBar from "components/LoadingBar/LoadingBar";
import { Button } from "components/button/Button";
import { LabeledInput } from "components/input/Input";
import { displaySuccessBanner } from "main/utils/displayBanner";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import { useConfirmationModal } from "components/modal/Modal";
import usePageTitle from "main/utils/usePageTitle";
import Analytics, { Events } from "main/utils/Analytics";
import { Link } from "components/Link";
import { Tooltip } from "react-tooltip";
import CopyIcon from "icons/ts/CopyIcon";
import CheckmarkIcon from "icons/ts/CheckmarkIcon";
import EyeClosedIcon from "icons/ts/EyeClosedIcon";
import EyeOpenIcon from "icons/ts/EyeOpenIcon";

export interface ScimTabProps {
    loading: boolean;
    scimConfig: ScimConfiguration;
    updateScimSettings: (enable: boolean) => void;
}

export interface ScimConfiguration {
    enabled?: boolean;
    scimUrl?: string;
    authHeader?: string;
}

export type ScimSettingsHook = {
    loading: boolean;
    scimConfig: ScimConfiguration;
    fetchScimSettings: () => void;
    updateScimSettings: (enabled: boolean) => void;
};

export const useScimSettings = (): ScimSettingsHook => {
    const [scimConfig, setScimConfig] = useState<ScimConfiguration>({});

    const { loading: fetchLoading, execute: fetch } = useLoadingCall(
        apiClient.scimSettings
    );

    const fetchScimSettings = useCallback(() => {
        fetch()
            .then((config: ScimConfiguration) => {
                setScimConfig(config);
            })
            .catch((e) => {
                handleResponse(e, "Failed to get SCIM settings.");
            });
    }, [fetch]);

    const { loading: updateLoading, execute: update } = useLoadingCall(
        apiClient.enableScim
    );

    const updateScimSettings = useCallback(
        (enable: boolean) => {
            update(enable)
                .then((config: ScimConfiguration) => {
                    setScimConfig(config);
                    displaySuccessBanner(
                        "Successfully updated SCIM configuration"
                    );
                })
                .catch((e) => {
                    handleResponse(e, "Failed to update SCIM settings.");
                });
        },
        [update]
    );

    return {
        loading: fetchLoading || updateLoading,
        scimConfig,
        fetchScimSettings,
        updateScimSettings,
    };
};

export const DisconnectedScimTab: React.FC<ScimTabProps> = (
    props: ScimTabProps
) => {
    usePageTitle("SCIM Settings");
    const [isScimUrlCopied, setIsScimUrlCopied] = useState<boolean>(false);
    const [isAuthHeaderCopied, setIsAuthHeaderCopied] =
        useState<boolean>(false);

    const [showAuthHeader, setShowAuthHeader] = useState<boolean>(false);
    const scimUrlInputRef = useRef<HTMLInputElement>(
        null
    ) as MutableRefObject<HTMLInputElement>;
    const authHeaderInputRef = useRef<HTMLInputElement>(
        null
    ) as MutableRefObject<HTMLInputElement>;

    const { loading, updateScimSettings, scimConfig } = props;
    const { enabled, scimUrl, authHeader } = scimConfig ?? {};

    useEffect(() => {
        const handleDoubleClick = () => {
            authHeaderInputRef.current.setSelectionRange(
                0,
                authHeaderInputRef.current.value.length
            );
        };

        if (authHeaderInputRef.current) {
            authHeaderInputRef.current.addEventListener(
                "dblclick",
                handleDoubleClick
            );
        }

        return () => {
            if (authHeaderInputRef.current) {
                authHeaderInputRef.current.removeEventListener(
                    "dblclick",
                    handleDoubleClick
                );
            }
        };
    }, [authHeaderInputRef, authHeader]);

    const modalProps = {
        title: "Disconnect SCIM",
        message:
            "Are you sure you want to disconnect SCIM and manage members manually?\nAll current members will be retained.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Disconnect",
    };

    const { showModal, modal } = useConfirmationModal({ ...modalProps });

    const enableSCIM = () => {
        updateScimSettings(true);
        setShowAuthHeader(true);
        Analytics.event(Events.enable_scim);
    };

    const disableSCIM = () => {
        updateScimSettings(false);
        Analytics.event(Events.disable_scim);
    };

    const handleCopy = (text: string, callbackFn: (b: boolean) => void) => {
        navigator.clipboard.writeText(text);
        callbackFn(true);
    };

    const inputIcons = useMemo(
        () => (
            <>
                {showAuthHeader ? (
                    <EyeClosedIcon
                        data-testid="eye-closed-icon"
                        onClick={() => setShowAuthHeader(false)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                ) : (
                    <EyeOpenIcon
                        data-testid="eye-open-icon"
                        onClick={() => setShowAuthHeader(true)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                )}

                {isAuthHeaderCopied ? (
                    <CheckmarkIcon
                        data-testid="scim-token-checkmark-icon"
                        data-tooltip-id="copy-auth-header-tooltip"
                        data-tooltip-content="copied"
                        color="#09AA71"
                        className="absolute pl-1 -ml-9 mt-3"
                    />
                ) : (
                    <CopyIcon
                        data-testid="scim-token-copy-icon"
                        onClick={() =>
                            handleCopy(
                                authHeader as string,
                                setIsAuthHeaderCopied
                            )
                        }
                        className="absolute pl-1 -ml-9 mt-3 hover:cursor-pointer"
                        color="#007DB2"
                    />
                )}
                <Tooltip
                    isOpen={isAuthHeaderCopied}
                    id="copy-auth-header-tooltip"
                    afterShow={() =>
                        setTimeout(() => {
                            setIsAuthHeaderCopied(false);
                        }, 5000)
                    }
                />
            </>
        ),
        [authHeader, showAuthHeader, isAuthHeaderCopied]
    );

    const Body = () => {
        if (enabled === undefined) {
            return <></>;
        }

        if (enabled) {
            return (
                <>
                    <LabeledInput
                        name="scim-url"
                        label="SCIM URL"
                        value={scimUrl}
                        ref={scimUrlInputRef}
                        icon={
                            <>
                                {isScimUrlCopied ? (
                                    <CheckmarkIcon
                                        data-testid="scim-url-checkmark-icon"
                                        data-tooltip-id="copy-scim-url-tooltip"
                                        data-tooltip-content="copied"
                                        color="#09AA71"
                                        className="absolute pl-1 -ml-9 mt-3"
                                    />
                                ) : (
                                    <CopyIcon
                                        data-testid="scim-url-copy-icon"
                                        onClick={() =>
                                            handleCopy(
                                                scimUrl as string,
                                                setIsScimUrlCopied
                                            )
                                        }
                                        className="absolute pl-1 -ml-9 mt-3 hover:cursor-pointer"
                                        color="#007DB2"
                                    />
                                )}
                                <Tooltip
                                    isOpen={isScimUrlCopied}
                                    id="copy-scim-url-tooltip"
                                    afterShow={() =>
                                        setTimeout(() => {
                                            setIsAuthHeaderCopied(false);
                                        }, 5000)
                                    }
                                />
                            </>
                        }
                    />
                    <LabeledInput
                        name="auth-header"
                        label="Authorization Header"
                        value={
                            showAuthHeader
                                ? authHeader
                                : "**********************************"
                        }
                        ref={authHeaderInputRef}
                        icon={inputIcons}
                    />

                    <Button
                        appearance="alert"
                        label={loading ? "Disabling..." : "Disable SCIM"}
                        onClick={showModal}
                        disabled={loading}
                    />
                </>
            );
        }

        return (
            <>
                <Button
                    label={loading ? "Enabling..." : "Enable SCIM"}
                    onClick={enableSCIM}
                    disabled={loading}
                />
            </>
        );
    };

    const scimSetupDocs = `https://docs.iverify.io/scim-provisioning-setup`;

    return (
        <>
            {modal(disableSCIM)}
            {loading && <LoadingBar />}
            <div className="plm">
                <div className="flex-col flex-vs ptm width-medium">
                    <div className="heading-one pbm">SCIM Configuration</div>
                    <Body />
                    <p className="paragraph-two mtm mbl text-center">
                        Need help?
                        <br />
                        Please reference&nbsp;
                        <Link to={{ pathname: scimSetupDocs }}>
                            SCIM Setup Documentation
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
};

const ScimTab: React.FC = () => {
    const { loading, scimConfig, fetchScimSettings, updateScimSettings } =
        useScimSettings();

    useEffect(() => {
        fetchScimSettings();
    }, [fetchScimSettings]);

    return (
        <>
            <DisconnectedScimTab
                loading={loading}
                scimConfig={scimConfig}
                updateScimSettings={updateScimSettings}
            />
        </>
    );
};

export default ScimTab;
