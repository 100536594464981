import { AxiosError } from "axios";
import { AppRoutes } from "main/app/App";
import {
    ApplicationState,
    DEFAULT_PAGE,
    DEFAULT_PAGE_SIZE,
    EnrolledFilter,
    MAXIMUM_PAGE_SIZE,
    PaginatedDevices,
    PaginationQueryParams,
    TableFilters,
} from "main/app/types";
import { DashboardData, DeviceRow } from "main/dashboard/types";
import { EmailMembersResponse, apiClient } from "main/utils/ApiClient";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import { displaySuccessBanner } from "main/utils/displayBanner";
import useDidMountEffect from "main/utils/useDidMountEffect";
import { withEngSuffix } from "main/utils/utils";
import { isPositiveInteger } from "main/utils/validate";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DashboardActions } from "./dashboardReducer";
import { useFilter } from "./useFilter";

export const removeNulls = (
    obj: Record<string, unknown>
): Record<string, unknown> =>
    Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

type MemberUploadPathHook = {
    goToMemberUpload: () => void;
};

export const useMemberUpload = (): MemberUploadPathHook => {
    const navigate = useNavigate();

    const goToMemberUpload = () => {
        navigate(AppRoutes.memberManagementPath);
    };

    return { goToMemberUpload };
};

export type DashboardHook = {
    loading: boolean;
    paginatedDevices: PaginatedDevices;
    totalPages: number;
    currentPage: number;
    pageSize: number;
    fetchDashboardRows: (
        page?: number,
        pageSize?: number,
        filters?: TableFilters
    ) => void;
    setCurrentPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
    clearPaginatedDevices: () => void;
};

export const useDashboard = (): DashboardHook => {
    const dispatch = useDispatch();
    const dashboard = useSelector((state: ApplicationState) => state.dashboard);
    const location = useLocation();
    const navigate = useNavigate();
    const { paginatedDevices, totalPages } = dashboard;
    const queryParams = new URLSearchParams(location.search);

    const initialPage = isPositiveInteger(
        queryParams.get(PaginationQueryParams.PAGE)
    )
        ? Number(queryParams.get(PaginationQueryParams.PAGE))
        : DEFAULT_PAGE;
    const initialPageSize =
        isPositiveInteger(queryParams.get(PaginationQueryParams.PAGE_SIZE)) &&
        Number(queryParams.get(PaginationQueryParams.PAGE_SIZE)) <=
            MAXIMUM_PAGE_SIZE
            ? Number(queryParams.get(PaginationQueryParams.PAGE_SIZE))
            : DEFAULT_PAGE_SIZE;

    const [currentPage, setCurrentPage] = useState<number>(initialPage);
    const [pageSize, setPageSize] = useState<number>(initialPageSize);
    const { loading, execute: fetchDashboard } = useLoadingCall(
        apiClient.fetchDashboard
    );

    useDidMountEffect(() => {
        const query = new URLSearchParams(location.search);
        query.set(PaginationQueryParams.PAGE, currentPage.toString());
        query.set(PaginationQueryParams.PAGE_SIZE, pageSize.toString());
        navigate({
            search: query.toString(),
        });
    }, [pageSize, currentPage]);

    const fetchDashboardRows = useCallback(
        (
            page = DEFAULT_PAGE,
            pSize = DEFAULT_PAGE_SIZE,
            filters: TableFilters = {}
        ) => {
            const pageFilters = { ...filters };
            if (pageFilters.enrolled === EnrolledFilter.NONE) {
                delete pageFilters.enrolled;
            }
            fetchDashboard(page, pSize, pageFilters)
                .then((fetchedDevices: DashboardData) => {
                    if (page > fetchedDevices.totalPages) {
                        setCurrentPage(DEFAULT_PAGE);
                    } else {
                        dispatch({
                            type: DashboardActions.FetchDevices,
                            page,
                            devices: fetchedDevices.devices,
                            totalPages: fetchedDevices.totalPages,
                        });
                    }
                })
                .catch((e: AxiosError) => {
                    handleResponse(e, "Failed to get dashboard rows");
                    dispatch({
                        type: DashboardActions.FetchDevices,
                        page,
                        devices: [],
                        totalPages: 0,
                    });
                });
        },
        []
    );

    const clearPaginatedDevices = useCallback(() => {
        dispatch({ type: DashboardActions.ClearPages });
    }, []);

    return {
        loading,
        paginatedDevices,
        totalPages,
        currentPage,
        pageSize,
        fetchDashboardRows,
        setCurrentPage,
        setPageSize,
        clearPaginatedDevices,
    };
};

export type PaginationHook = {
    paginationRange: (number | string)[];
};

interface UsePaginationProps {
    totalPages: number;
    currentPage: number;
    siblingCount?: number;
}

export const usePagination = (props: UsePaginationProps): PaginationHook => {
    const { totalPages, siblingCount = 1, currentPage } = props;
    const DOTS = "...";
    const range = (start: number, end: number) => {
        const length = end - start + 1;
        return Array.from({ length }, (_, idx) => idx + start);
    };

    const paginationRange = useMemo(() => {
        const visiblePages = siblingCount + 5;

        if (visiblePages >= totalPages) {
            return range(1, totalPages);
        }

        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPages
        );

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPages - 2;
        const firstPageIndex = 1;
        const lastPageIndex = totalPages;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = 3 + 2 * siblingCount;
            const leftRange = range(1, leftItemCount);

            return [...leftRange, DOTS, totalPages];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = 3 + 2 * siblingCount;
            const rightRange = range(
                totalPages - rightItemCount + 1,
                totalPages
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        const middleRange = range(leftSiblingIndex, rightSiblingIndex);
        return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }, [totalPages, siblingCount, currentPage]);

    return { paginationRange };
};

type EmailMembersHook = {
    loading: boolean;
    emailMembers: (deviceCodes: string[]) => void;
};
export const useEmailMembers = (): EmailMembersHook => {
    const { loading, execute: emailMembersCall } = useLoadingCall(
        apiClient.emailMembers
    );

    const emailMembers = useCallback(
        (deviceCodes: string[]) => {
            emailMembersCall(deviceCodes)
                .then((response: EmailMembersResponse) => {
                    displaySuccessBanner(
                        `Invitations are being sent to ${
                            response.count
                        } organization ${withEngSuffix(
                            "member",
                            response.count
                        )}`
                    );
                })
                .catch((e: AxiosError) => {
                    handleResponse(
                        e,
                        "Failed to email members please try again"
                    );
                });
        },
        [emailMembersCall]
    );

    return { loading, emailMembers };
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type unenrollDevicesHook = {
    unenrollDevices: (rowSelections: DeviceRow[]) => () => void;
};

export const useUnenrollDevices = (): unenrollDevicesHook => {
    const { execute } = useLoadingCall(apiClient.unenrollDevices);
    const dispatch = useDispatch();
    const { currentPage, pageSize, fetchDashboardRows } = useDashboard();
    const { filters } = useFilter();

    const unenrollDevices = useCallback((rowSelections: DeviceRow[]) => {
        const deviceCodes = rowSelections.map((d) => d.code);
        const deviceCount = deviceCodes.length;
        return () => {
            execute(deviceCodes)
                .then(() => {
                    dispatch({
                        type: DashboardActions.UnenrollDevices,
                        deviceCodes,
                    });
                    fetchDashboardRows(currentPage, pageSize, filters);
                    displaySuccessBanner(
                        `Unenrolled ${deviceCount.toLocaleString()} ${withEngSuffix(
                            "device",
                            deviceCount
                        )}`
                    );
                })
                .catch((e: AxiosError) => {
                    handleResponse(
                        e,
                        `Failed to unenroll ${withEngSuffix(
                            "device",
                            deviceCount
                        )}. Please try again`
                    );
                });
        };
    }, []);

    return { unenrollDevices };
};
export default useDashboard;
