import { IconProps } from "main/app/types";
import { FC } from "react";

const ChevronIcon: FC<IconProps> = (props: IconProps) => {
    const { rotate, color = "#DAE0E7" } = props;
    return (
        <svg
            width={24}
            height={24}
            preserveAspectRatio="xMidYMid meet"
            fill="none"
            style={rotate ? { transform: `rotate(${rotate}deg)` } : {}}
        >
            <path
                fill={color}
                d="M12.72 15.78a.75.75 0 0 1-.53.22h-.38a.77.77 0 0 1-.53-.22l-5.13-5.14a.5.5 0 0 1 0-.71l.71-.71a.49.49 0 0 1 .7 0L12 13.67l4.44-4.45a.5.5 0 0 1 .71 0l.7.71a.5.5 0 0 1 0 .71l-5.13 5.14Z"
            />
        </svg>
    );
};

export default ChevronIcon;
