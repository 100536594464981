import { IconProps } from "main/app/types";
import React from "react";

const BigCheckmarkIcon: React.FC<IconProps> = ({
    color = "#F6F7F9",
    ...props
}: IconProps) => (
    <svg width="64" height="64" viewBox="0 0 64 64" {...props}>
        <circle cx="32" cy="32" r="32" fill="#007DB3" />
        <path
            fill={color}
            stroke="#fff"
            d="m42.385 21.358-.005.005-15.563 15.563-6.862-6.861v-.001a1.333 1.333 0 0 0-1.893 0l-1.164 1.164-.001.001a1.334 1.334 0 0 0 0 1.892l8.981 8.981.001.001a1.334 1.334 0 0 0 1.891 0h.002l17.663-17.665h.001a1.334 1.334 0 0 0 .002-1.89l-1.165-1.182-.008-.008a1.35 1.35 0 0 0-1.88 0Z"
        />
    </svg>
);

export default BigCheckmarkIcon;
