import { ButtonHTMLAttributes, FC, ReactChild } from "react";
import { Button } from "components/button/Button";

export interface OptionButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon: ReactChild;
    label: string;
    description: string;
}

export const ManagementOptionButton: FC<OptionButtonProps> = ({
    label,
    description,
    icon,
    ...props
}: OptionButtonProps) => (
    <Button appearance="jumbo" className="mhm py-4" name={label} {...props}>
        <div className="flex-col flex-vc">
            {icon}
            <div className="title-three rubik mvxs">{label}</div>
            <div className="paragraph-two color-dark phs">{description}</div>
        </div>
    </Button>
);
