import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { apiClient, OrgLookupResponse } from "main/utils/ApiClient";
import { AppRoutes } from "main/app/App";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";

type LookupHook = {
    loading: boolean;
    lookupCompany: (company: string) => void;
};

export const useLookup = (): LookupHook => {
    const navigate = useNavigate();
    const { loading, execute: lookupOrgBySlug } = useLoadingCall(
        apiClient.lookupOrgBySlug
    );

    const lookupCompany = (company: string) => {
        const slug = company.toLowerCase().replaceAll(" ", "");

        lookupOrgBySlug(slug)
            .then((response: OrgLookupResponse) => {
                if (response.hasSSO) {
                    window.location.href = `${window.location.origin}/oauth/login?slug=${slug}`;
                } else {
                    navigate(AppRoutes.loginPath);
                }
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Unable to find company.");
            });
    };
    return { loading, lookupCompany };
};

export default useLookup;
