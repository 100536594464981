import App from "main/app/App";

import reduxStore from "main/app/store";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import "styles/app.scss";

import Analytics from "main/utils/Analytics";
import loadSentry from "main/utils/Sentry";
import { Store } from "redux";

const render = (store: Store) => {
    const container = document.getElementById("root");
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = createRoot(container!);
    root.render(
        <StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </Provider>
        </StrictMode>
    );

    Modal.setAppElement("#root");
};

Analytics.load();
loadSentry(
    import.meta.env.VITE_SENTRY_ENV,
    import.meta.env.VITE_SENTRY_RELEASE
);

reduxStore.then((store: Store) => {
    render(store);
});
