import { IconProps } from "main/app/types";
import React from "react";

const CopyIcon: React.FC<IconProps> = ({
    color = "#ffffff",
    ...props
}: IconProps) => (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path
            d="M16 14V2C16 0.89543 15.1046 0 14 0H2C0.89543 0 0 0.89543 0 2V14C0 15.1046 0.89543 16 2 16H14C15.1046 16 16 15.1046 16 14ZM2 2H14V14H2V2ZM18 16V4C19.1046 4 20 4.89543 20 6V16C20 18.2091 18.2091 20 16 20H6C4.89543 20 4 19.1046 4 18H16C17.1046 18 18 17.1046 18 16Z"
            fill={color}
        />
    </svg>
);

export default CopyIcon;
