import "components/input/Input.scss";
import { ComponentType, FC, InputHTMLAttributes, forwardRef } from "react";

/* eslint-disable react/no-unused-prop-types */
export interface InputProps
    extends InputHTMLAttributes<
        HTMLInputElement & HTMLLabelElement & HTMLSelectElement
    > {
    /**
     * Display an icon inside the input when defined
     * @example icon={<SearchIcon />}
     */
    Icon?: ComponentType<{ className?: string }>;
}

export interface TextareaProps
    extends InputHTMLAttributes<HTMLTextAreaElement & HTMLInputElement> {
    label?: string;
    rows?: number;
    cols?: number;
}

export interface LabeledInputProps extends InputProps {
    /**
     * The label text
     */
    label?: string;
    /**
     * Display this error when defined
     */
    error?: string | React.ReactNode;
    /**
     * Icon displayed inside the input
     */
    icon?: React.ReactNode;
}

/**
 * A form Label field.
 */
export const LabelWithErrorMessage: FC<LabeledInputProps> = ({
    label,
    error,
    icon,
    ...props
}: LabeledInputProps) => {
    const { children, className } = props;

    const ErrorMessage = () => (
        <>
            {icon ?? ""}
            <div className="error-message color-critical-lt py-2">
                {error ?? ""}
            </div>
        </>
    );

    return (
        <label className={`label ${className ?? ""}`}>
            {label}
            {children}
            <ErrorMessage />
        </label>
    );
};
/**
 * A form input field.
 */
export const Input = forwardRef<HTMLInputElement, InputProps>(
    ({ className, Icon, ...props }: InputProps, ref) =>
        Icon ? (
            <span className="flex justify-start items-center relative">
                <input
                    type="text"
                    {...props}
                    className={`!pl-9 color-bg-light-p-1 border border-light-m-2 tight-1 ${
                        className ?? ""
                    }`}
                />
                <Icon className="absolute w-10 !m-0 z-[10]" />
            </span>
        ) : (
            <input
                ref={ref}
                type="text"
                {...props}
                className={`color-bg-light-p-1 border border-light-m-2 tight-1 ${
                    className ?? ""
                }`}
            />
        )
);

/**
 * A form textarea field.
 */
export const Textarea: FC<TextareaProps> = ({
    label,
    className,
    ...props
}: TextareaProps) => (
    <>
        <label className="label m-0">{label}</label>
        <textarea
            {...props}
            className={`color-bg-light-p-1 border border-light-m-2 tight-1 ${
                className ?? ""
            }`}
        >
            {props.children}
        </textarea>
    </>
);

export const LabeledInput = forwardRef<HTMLInputElement, LabeledInputProps>(
    ({ label, error, icon, ...props }: LabeledInputProps, ref) => {
        const { children: labelChildren, className, ...rest } = props;
        return (
            <>
                <LabelWithErrorMessage {...{ label, className, error, icon }}>
                    <Input ref={ref} {...{ className, ...rest }} />
                    {labelChildren}
                </LabelWithErrorMessage>
            </>
        );
    }
);

export const SelectInput: FC<LabeledInputProps> = ({
    label,
    error,
    icon,
    ...props
}: LabeledInputProps) => {
    const { children: labelChildren, className, ...rest } = props;
    return (
        <>
            <LabelWithErrorMessage {...{ label, className, error, icon }}>
                <select className={`input-select ${className ?? ""}`} {...rest}>
                    {labelChildren}
                </select>
            </LabelWithErrorMessage>
        </>
    );
};
