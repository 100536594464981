import usePageTitle from "main/utils/usePageTitle";
import ApiSettings from "./ApiSettings";
import WebhookSettings from "./WebhookSettings";
import ConditionalAccess from "./ConditionalAccess";

const IntegrationsTab: React.FC = () => {
    usePageTitle("Integrations");
    return (
        <>
            <div className="plm flex-col flex-vs ptm w-5/6">
                <ApiSettings />
                <WebhookSettings />
                <ConditionalAccess />
            </div>
        </>
    );
};

export default IntegrationsTab;
