import {
    AlertScanSeverityPolicy,
    ConditionalAccessPolicy,
    ConditionalAccessProvider,
    ConditonalAccessConfig,
    DeviceIntegrityPeriodPolicy,
    OutdatedOSPeriodPolicy,
    ScreenLockEnabledPolicy,
} from "main/app/types";
import { apiClient } from "main/utils/ApiClient";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import {
    displayErrorBanner,
    displaySuccessBanner,
} from "main/utils/displayBanner";
import { useCallback, useEffect, useMemo, useState } from "react";
import handleResponse from "main/utils/HandleResponse";
import ToggleInput from "components/ToggleInput";
import { LabeledInput, SelectInput } from "components/input/Input";
import { Link } from "components/Link";
import { AppRoutes } from "main/app/App";
import { Button } from "components/button/Button";
import { useConfirmationModal } from "components/modal/Modal";

export type ConditionalAccessSettingsHook = {
    configLoading: boolean;
    policyLoading: boolean;
    conditionalAccessConfig: ConditonalAccessConfig;
    conditionalAccessConfigError: string;
    conditionalAccessPolicy: ConditionalAccessPolicy;
    getConditionalAccessConfig: () => void;
    saveConditionalAccessConfig: (config: ConditonalAccessConfig) => void;
    removeConditionalAccess: () => void;
    getConditionalAccessPolicy: () => void;
    updateConditionalAccessPolicy: (policy: ConditionalAccessPolicy) => void;
};

export const useConditionalAccessConfig = (): ConditionalAccessSettingsHook => {
    const [conditionalAccessConfig, setConditionalAccessConfig] =
        useState<ConditonalAccessConfig>({
            tenant: null,
            clientId: null,
            clientSecret: null,
            userExtension: null,
            groupId: null,
            provider: null,
            active: false,
        });

    const [conditionalAccessConfigError, setConditionalAccessConfigError] =
        useState<string>("");

    const [conditionalAccessPolicy, setConditionalAccessPolicy] =
        useState<ConditionalAccessPolicy>({
            alertSeverity: AlertScanSeverityPolicy.NONE,
            outdatedOSPeriod: OutdatedOSPeriodPolicy.NEVER,
            deviceIntegrityPeriod: DeviceIntegrityPeriodPolicy.NEVER,
            screenLockEnabled: false,
        });

    const {
        loading: fetchConditionalAccessConfigLoading,
        execute: fetchConditionalAccessConfig,
    } = useLoadingCall(apiClient.fetchConditionalAccessConfig);

    const getConditionalAccessConfig = useCallback(() => {
        fetchConditionalAccessConfig()
            .then((config: ConditonalAccessConfig) => {
                setConditionalAccessConfig(config);
            })
            .catch((e) => {
                handleResponse(
                    e,
                    "Failed to get conditional access configuration."
                );
            });
    }, [fetchConditionalAccessConfig]);

    const { loading: saveConditionalAccessConfigLoading, execute: post } =
        useLoadingCall(apiClient.saveConditionalAccessConfig);

    const saveConditionalAccessConfig = useCallback(
        (config: ConditonalAccessConfig) => {
            post(config)
                .then(() => {
                    setConditionalAccessConfigError("");
                    setConditionalAccessConfig(config);
                    displaySuccessBanner(
                        "Successfully saved conditional access settings."
                    );
                })
                .catch((e) => {
                    if (e.response.status === 500) {
                        displayErrorBanner(
                            "Failed to save conditional access settings."
                        );
                    } else {
                        setConditionalAccessConfigError(e.response.data.error);
                    }
                });
        },
        [post]
    );

    const {
        loading: deleteConditionalAccessLoading,
        execute: deleteConditionalAccess,
    } = useLoadingCall(apiClient.deleteConditionalAccess);

    const removeConditionalAccess = useCallback(() => {
        deleteConditionalAccess()
            .then(() => {
                displaySuccessBanner(
                    "A request has been made, Conditional Access will be removed after processing blocked members."
                );
                setConditionalAccessConfig({
                    tenant: null,
                    clientId: null,
                    clientSecret: null,
                    userExtension: null,
                    groupId: null,
                    provider: null,
                    active: false,
                });
                setConditionalAccessPolicy({
                    alertSeverity: AlertScanSeverityPolicy.NONE,
                    outdatedOSPeriod: OutdatedOSPeriodPolicy.NEVER,
                    deviceIntegrityPeriod: DeviceIntegrityPeriodPolicy.NEVER,
                    screenLockEnabled: false,
                });
            })
            .catch((e) => {
                handleResponse(
                    e,
                    "Failed to remove conditional access settings."
                );
            });
    }, [deleteConditionalAccess]);

    const {
        loading: fetchConditionalAccessPolicyLoading,
        execute: fetchConditionalAccessPolicy,
    } = useLoadingCall(apiClient.fetchConditionalAccessPolicy);

    const getConditionalAccessPolicy = useCallback(() => {
        fetchConditionalAccessPolicy()
            .then((policy: ConditionalAccessPolicy) => {
                setConditionalAccessPolicy(policy);
            })
            .catch((e) => {
                handleResponse(
                    e,
                    "Failed to get conditional access policy settings."
                );
            });
    }, [fetchConditionalAccessPolicy]);

    const { loading: updateConditionalAccessPolicyLoading, execute: update } =
        useLoadingCall(apiClient.updateConditionalAccessPolicy);

    const updateConditionalAccessPolicy = useCallback(
        (policy: ConditionalAccessPolicy) => {
            update(policy)
                .then(() => {
                    setConditionalAccessPolicy(policy);
                    displaySuccessBanner(
                        "Successfully updated conditional access policy settings."
                    );
                })
                .catch((e) => {
                    setConditionalAccessConfigError(e.response.data);
                    if (e.response.status === 500) {
                        displayErrorBanner(
                            "Failed to update conditional access policy settings."
                        );
                    }
                });
        },
        [update]
    );

    return {
        configLoading:
            fetchConditionalAccessConfigLoading ||
            saveConditionalAccessConfigLoading ||
            deleteConditionalAccessLoading,
        policyLoading:
            fetchConditionalAccessPolicyLoading ||
            updateConditionalAccessPolicyLoading,
        conditionalAccessConfig,
        conditionalAccessConfigError,
        conditionalAccessPolicy,
        getConditionalAccessConfig,
        saveConditionalAccessConfig,
        removeConditionalAccess,
        getConditionalAccessPolicy,
        updateConditionalAccessPolicy,
    };
};

const ConditionalAccess: React.FC = () => {
    const {
        configLoading,
        conditionalAccessConfig,
        conditionalAccessPolicy,
        conditionalAccessConfigError,
        getConditionalAccessConfig,
        saveConditionalAccessConfig,
        removeConditionalAccess,
        getConditionalAccessPolicy,
        updateConditionalAccessPolicy,
    } = useConditionalAccessConfig();

    const [conditionalAccessForm, setConditionalAccessForm] =
        useState<ConditonalAccessConfig>(conditionalAccessConfig);

    const [conditionalAccessToggle, setConditionalAccessToggle] =
        useState<boolean>(false);

    const [isEditingSecret, setIsEditingSecret] = useState(false);

    useEffect(() => {
        getConditionalAccessConfig();
        getConditionalAccessPolicy();
    }, [getConditionalAccessConfig, getConditionalAccessPolicy]);

    useEffect(() => {
        setConditionalAccessToggle(conditionalAccessConfig.active);
        setConditionalAccessForm(conditionalAccessConfig);
    }, [conditionalAccessConfig]);

    const removeConditionalAccessModalProps = {
        title: "Remove Conditional Access",
        message:
            "Are you sure you want to remove conditional access settings? \n All blocked users will be unblocked and registered conditional access settings will be removed.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Remove",
    };

    const deactivateConditionalAccessModalProps = {
        title: "Deactivate Conditional Access",
        message:
            "Are you sure you want to deactivate conditional access? \n All blocked users will be unblocked.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Deactivate",
    };

    const {
        showModal: showRemoveConditionalAccessModal,
        modal: removeConditionalAccessModal,
    } = useConfirmationModal({ ...removeConditionalAccessModalProps });

    const {
        showModal: showDeactivateConditionalAccessModal,
        modal: deactivateConditionalAccessModal,
    } = useConfirmationModal({ ...deactivateConditionalAccessModalProps });

    const handleConditionalAccessToggle = () => {
        const updatedConditionalAccessConfig = {
            ...conditionalAccessConfig,
            active: !conditionalAccessToggle,
        };

        if (
            conditionalAccessToggle &&
            Object.values(conditionalAccessConfig).every(
                (value) => value !== null && value !== ""
            )
        ) {
            showDeactivateConditionalAccessModal();
        } else {
            if (conditionalAccessConfig.provider !== null) {
                saveConditionalAccessConfig(updatedConditionalAccessConfig);
            }
            setConditionalAccessForm(updatedConditionalAccessConfig);
        }

        setConditionalAccessToggle(!conditionalAccessToggle);
    };

    const deactivateConditionalAccess = () => {
        const updatedConditionalAccessConfig = {
            ...conditionalAccessConfig,
            active: false,
        };

        if (
            Object.values(conditionalAccessConfig).every(
                (value) => value !== null && value !== ""
            )
        ) {
            saveConditionalAccessConfig(updatedConditionalAccessConfig);
        }
        setConditionalAccessToggle(false);
    };

    const { tenant, clientId, clientSecret, userExtension, groupId, provider } =
        conditionalAccessForm;

    const {
        alertSeverity,
        outdatedOSPeriod,
        deviceIntegrityPeriod,
        screenLockEnabled,
    } = conditionalAccessPolicy;

    const screenLockEnabledValue = useMemo(
        () =>
            screenLockEnabled
                ? ScreenLockEnabledPolicy.ENABLED
                : ScreenLockEnabledPolicy.DISABLED,
        [screenLockEnabled]
    );

    const secretDisplayValue = useMemo(() => {
        if (isEditingSecret) {
            return clientSecret;
        }
        return clientSecret ? "****************" : "";
    }, [clientSecret, isEditingSecret]);

    const conditionalAccessSettings = useMemo(
        () => (
            <div className="flex-col my-3 rounded-md border border-light-m-1">
                <div className="flex-row justify-between items-center px-5 py-4 color-bg-light-p-1">
                    <p className="heading-one">Conditional Access (Beta)</p>
                    <ToggleInput
                        onChange={handleConditionalAccessToggle}
                        name="condtitional-access-status"
                        checked={conditionalAccessToggle}
                    />
                </div>
                {!conditionalAccessToggle ? (
                    <></>
                ) : (
                    <div className="flex-col justify-between px-5 py-4">
                        <div className="flex-row justify-between gap-x-4">
                            <SelectInput
                                label="Identity Provider"
                                data-testid="ca-provider-dropdown"
                                value={provider ?? ""}
                                className="mb-0 basis-4/12"
                                onChange={(e) =>
                                    setConditionalAccessForm({
                                        ...conditionalAccessForm,
                                        provider: e.target
                                            .value as ConditionalAccessProvider,
                                    })
                                }
                            >
                                <option value="select" hidden>
                                    Select Provider
                                </option>
                                <option value={ConditionalAccessProvider.AZURE}>
                                    Azure
                                </option>
                                <option value={ConditionalAccessProvider.OKTA}>
                                    Okta
                                </option>
                            </SelectInput>
                            <LabeledInput
                                className="basis-4/12"
                                name="tenant-id"
                                label={
                                    conditionalAccessForm.provider ===
                                    ConditionalAccessProvider.OKTA
                                        ? "Tenant (Okta Domain)"
                                        : "Tenant"
                                }
                                value={tenant ?? ""}
                                onChange={(e) =>
                                    setConditionalAccessForm({
                                        ...conditionalAccessForm,
                                        tenant: e.target.value,
                                    })
                                }
                            />
                            <LabeledInput
                                className="basis-4/12"
                                name="group-id"
                                label="Group ID"
                                value={groupId ?? ""}
                                onChange={(e) =>
                                    setConditionalAccessForm({
                                        ...conditionalAccessForm,
                                        groupId: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="flex-row justify-between gap-x-4">
                            <LabeledInput
                                className="basis-4/12"
                                name="client-id"
                                label="Client ID"
                                value={clientId ?? ""}
                                error={
                                    <p className="color-mid-m-1 text-sm font-semibold">
                                        Read{" "}
                                        <Link
                                            to={{
                                                pathname:
                                                    AppRoutes.conditionalAccessDocsPath,
                                            }}
                                        >
                                            Conditional Access Setup
                                            Documentation
                                        </Link>
                                    </p>
                                }
                                onChange={(e) =>
                                    setConditionalAccessForm({
                                        ...conditionalAccessForm,
                                        clientId: e.target.value,
                                    })
                                }
                            />
                            <LabeledInput
                                className="basis-4/12"
                                name="client-secret"
                                label={
                                    conditionalAccessForm.provider ===
                                    ConditionalAccessProvider.OKTA
                                        ? "Application Private Key (JSON)"
                                        : "Client Secret"
                                }
                                value={secretDisplayValue ?? ""}
                                onFocus={() => setIsEditingSecret(true)}
                                onBlur={() => setIsEditingSecret(false)}
                                onChange={(e) =>
                                    setConditionalAccessForm({
                                        ...conditionalAccessForm,
                                        clientSecret: e.target.value,
                                    })
                                }
                            />
                            <LabeledInput
                                className="basis-4/12"
                                name="user-extension"
                                label="User Extension"
                                value={userExtension ?? ""}
                                onChange={(e) =>
                                    setConditionalAccessForm({
                                        ...conditionalAccessForm,
                                        userExtension: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="flex-col">
                            <div className="flex-row mt-4 gap-x-4">
                                <Button
                                    appearance="primary"
                                    label={
                                        configLoading ? "Verifying..." : "Save"
                                    }
                                    className="basis-4/12"
                                    onClick={() =>
                                        saveConditionalAccessConfig(
                                            conditionalAccessForm
                                        )
                                    }
                                    disabled={
                                        configLoading ||
                                        !Object.values(
                                            conditionalAccessForm
                                        ).every(
                                            (value) =>
                                                value !== null && value !== ""
                                        ) ||
                                        JSON.stringify(
                                            conditionalAccessForm
                                        ) ===
                                            JSON.stringify(
                                                conditionalAccessConfig
                                            )
                                    }
                                />
                                <Button
                                    appearance="alert"
                                    label="Remove Conditional Access"
                                    className="basis-4/12"
                                    onClick={showRemoveConditionalAccessModal}
                                    disabled={!conditionalAccessConfig.active}
                                />
                            </div>
                            {conditionalAccessConfigError && (
                                <p className="color-critical-lt font-semibold pt-3 italic">
                                    Error: {conditionalAccessConfigError}
                                </p>
                            )}
                        </div>
                    </div>
                )}

                {conditionalAccessConfig.active && (
                    <div className="py-4 mt-2 border-solid border-t-2 border-gray-100">
                        <div className="flex-row justify-between items-center px-5 pb-4">
                            <div>
                                <div className="heading-three py-1">
                                    Quarantine on alert severity
                                </div>
                                <p>
                                    Configure when devices are blocked based on
                                    alert severity
                                </p>
                            </div>
                            <SelectInput
                                data-testid="alert-severity-policy-dropdown"
                                value={alertSeverity}
                                className="mb-0 w-40 mr-7"
                                onChange={(e) =>
                                    updateConditionalAccessPolicy({
                                        ...conditionalAccessPolicy,
                                        alertSeverity: e.target
                                            .value as AlertScanSeverityPolicy,
                                    })
                                }
                            >
                                <option
                                    value={
                                        AlertScanSeverityPolicy.CRITICAL_ONLY
                                    }
                                >
                                    Critical only
                                </option>
                                <option
                                    value={
                                        AlertScanSeverityPolicy.HIGH_AND_CRITICAL
                                    }
                                >
                                    High and Critical
                                </option>
                                <option value={AlertScanSeverityPolicy.NONE}>
                                    None
                                </option>
                            </SelectInput>
                        </div>
                        <div className="flex-row justify-between items-center px-5 pb-4">
                            <div>
                                <div className="heading-three py-1">
                                    Quarantine on outdated OS
                                </div>
                                <p>
                                    Configure the time period devices are
                                    blocked based on when they were outdated
                                </p>
                            </div>
                            <SelectInput
                                data-testid="outdated-os-policy-dropdown"
                                value={outdatedOSPeriod}
                                className="mb-0 w-40 mr-7"
                                onChange={(e) =>
                                    updateConditionalAccessPolicy({
                                        ...conditionalAccessPolicy,
                                        outdatedOSPeriod: e.target
                                            .value as OutdatedOSPeriodPolicy,
                                    })
                                }
                            >
                                <option
                                    value={OutdatedOSPeriodPolicy.THREE_DAYS}
                                >
                                    Three days
                                </option>
                                <option
                                    value={OutdatedOSPeriodPolicy.SEVEN_DAYS}
                                >
                                    Seven days
                                </option>
                                <option
                                    value={OutdatedOSPeriodPolicy.TWO_WEEKS}
                                >
                                    Two weeks
                                </option>
                                <option value={OutdatedOSPeriodPolicy.NEVER}>
                                    Never
                                </option>
                            </SelectInput>
                        </div>
                        <div className="flex-row justify-between items-center px-5 pb-4">
                            <div>
                                <div className="heading-three py-1">
                                    Quarantine when no scans reported
                                </div>
                                <p>
                                    Configure the time period devices are
                                    blocked for not reporting a scan
                                </p>
                            </div>
                            <SelectInput
                                data-testid="device-integrity-policy-dropdown"
                                value={deviceIntegrityPeriod}
                                className="mb-0 w-40 mr-7"
                                onChange={(e) =>
                                    updateConditionalAccessPolicy({
                                        ...conditionalAccessPolicy,
                                        deviceIntegrityPeriod: e.target
                                            .value as DeviceIntegrityPeriodPolicy,
                                    })
                                }
                            >
                                <option
                                    value={OutdatedOSPeriodPolicy.THREE_DAYS}
                                >
                                    Three days
                                </option>
                                <option
                                    value={OutdatedOSPeriodPolicy.SEVEN_DAYS}
                                >
                                    Seven days
                                </option>
                                <option
                                    value={OutdatedOSPeriodPolicy.TWO_WEEKS}
                                >
                                    Two weeks
                                </option>
                                <option value={OutdatedOSPeriodPolicy.NEVER}>
                                    Never
                                </option>
                            </SelectInput>
                        </div>
                        <div className="flex-row justify-between items-center px-5">
                            <div>
                                <div className="heading-three py-1">
                                    Quarantine when no screen lock present
                                </div>
                                <p>
                                    Configure if devices with no screen lock set
                                    up are blocked
                                </p>
                            </div>
                            <SelectInput
                                data-testid="screen-lock-enabled-policy-dropdown"
                                value={screenLockEnabledValue}
                                className="mb-0 w-40 mr-7"
                                onChange={(e) =>
                                    updateConditionalAccessPolicy({
                                        ...conditionalAccessPolicy,
                                        screenLockEnabled:
                                            (e.target
                                                .value as ScreenLockEnabledPolicy) ===
                                            ScreenLockEnabledPolicy.ENABLED,
                                    })
                                }
                            >
                                <option value={ScreenLockEnabledPolicy.ENABLED}>
                                    Enabled
                                </option>
                                <option
                                    value={ScreenLockEnabledPolicy.DISABLED}
                                >
                                    Disabled
                                </option>
                            </SelectInput>
                        </div>
                    </div>
                )}
            </div>
        ),
        [
            configLoading,
            conditionalAccessConfig,
            conditionalAccessForm,
            conditionalAccessToggle,
            conditionalAccessPolicy,
            conditionalAccessConfigError,
            isEditingSecret,
        ]
    );

    return (
        <>
            {removeConditionalAccessModal(removeConditionalAccess)}
            {deactivateConditionalAccessModal(deactivateConditionalAccess)}
            {conditionalAccessSettings}
        </>
    );
};

export default ConditionalAccess;
