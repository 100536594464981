import { Stripe } from "@stripe/stripe-js";
import { Button } from "components/button/Button";
import LoadingBar from "components/LoadingBar/LoadingBar";
import { loadedStripePromise, AppRoutes } from "main/app/App";
import { ApplicationState } from "main/app/types";
import LoadedCheckoutForm from "components/CheckoutForm/CheckoutForm";
import useCustomerBillingDetails from "main/settings/hooks";
import usePageTitle from "main/utils/usePageTitle";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "components/Link";

export interface BillingTabProps {
    name: string;
    loading: boolean;
    invoice: boolean;
    hasPaymentMethod: boolean;
    paymentStatement: string;
    stripePromise: Promise<Stripe | null>;
}

export const DisconnectedBillingTab: FC<BillingTabProps> = (
    props: BillingTabProps
) => {
    usePageTitle("Billing Settings");
    const {
        paymentStatement,
        hasPaymentMethod,
        name,
        invoice,
        stripePromise,
        loading,
    } = props;
    const [editing, setEditing] = useState<boolean>(false);

    const onEditClick = () => {
        setEditing(true);
    };

    const onSaveBilling = () => {
        setEditing(false);
    };

    const getTabBody = () => {
        if (invoice) {
            return (
                <>
                    <div className="paragraph-three pbm color-dark-2">
                        {paymentStatement}
                    </div>
                    <div className="paragraph-three pbm color-dark-2">
                        For support, please contact us at&nbsp;
                        <Link to={{ pathname: AppRoutes.supportEmailPath }}>
                            support@iverify.io
                        </Link>
                    </div>
                </>
            );
        }

        const noPaymentMethod = !hasPaymentMethod && !loading;
        if (editing || noPaymentMethod) {
            return (
                <>
                    <div className="paragraph-three pbm color-dark-2">
                        Please provide your billing information below.
                    </div>
                    <LoadedCheckoutForm
                        stripePromise={stripePromise}
                        name={name}
                        onSaveBilling={onSaveBilling}
                        noPaymentMethod={noPaymentMethod}
                    />
                </>
            );
        }
        return (
            <>
                <div className="paragraph-three pbm color-dark-2">
                    {paymentStatement}
                </div>
                <Button
                    className="fit-content"
                    disabled={loading}
                    onClick={onEditClick}
                    label="Edit Billing"
                />
            </>
        );
    };

    return (
        <>
            {loading && <LoadingBar />}
            <div className="plm">
                <div className="flex-col flex-vs ptm">
                    <div className="heading-one pbs">Billing Information</div>
                    {getTabBody()}
                </div>
            </div>
        </>
    );
};

const BillingTab: FC = () => {
    const name = useSelector(
        (state: ApplicationState) => state.session.user.name
    );
    const {
        invoice,
        hasPaymentMethod,
        loading,
        paymentStatement,
        fetchCustomerBillingDetails,
    } = useCustomerBillingDetails();

    useEffect(() => {
        fetchCustomerBillingDetails();
    }, [fetchCustomerBillingDetails]);

    return (
        <DisconnectedBillingTab
            name={name}
            invoice={invoice}
            hasPaymentMethod={hasPaymentMethod}
            loading={loading}
            paymentStatement={paymentStatement}
            stripePromise={loadedStripePromise}
        />
    );
};

export default BillingTab;
