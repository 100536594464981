import React from "react";

interface BadgeProps {
    text: string;
    color: string;
}

const Badge: React.FC<BadgeProps> = ({ text, color }) => (
    <div className="w-[83px]">
        <div
            className={`text-white text-xs text-center font-medium me-2 py-1 w-full rounded ${color}`}
        >
            {text}
        </div>
    </div>
);

export default Badge;
