/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import { FormErrorIcon } from "icons/ts/AlertIcon";
import SuccessIcon from "icons/ts/SuccessIcon";
import { DeviceRow, DeviceInformation } from "main/dashboard/types";

export const isAscii = (email: string): boolean => {
    const isAsciiRegex = /^\p{ASCII}+$/u;
    return isAsciiRegex.test(email);
};

export const isPositiveInteger = (value: string | null): boolean => {
    const isPositiveIntegerRegex = /^[1-9]\d*$/;
    return value ? isPositiveIntegerRegex.test(value) : false;
};

export const isEmailValid = (email: string): boolean => {
    if (email === "") {
        return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const isPasswordValid = (
    password: string,
    showErrors = true
): { hasError: boolean; errors: React.ReactNode } => {
    const passwordRegex =
        // eslint-disable-next-line max-len
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`~!@#$%^&*()\-_=+[\]{},.<>;:'"\\/|? ])[\w`~!@#$%^&*()\-_=+[\]{},.<>;:'"\\/|? ]{8,}$/;

    const errors = {
        length: password.length < 8,
        lowercase: !/[a-z]/.test(password),
        uppercase: !/[A-Z]/.test(password),
        number: !/\d/.test(password),
        special: !/[`~!@#$%^&*()\-_=+[\]{},.<>;:'"\\/|? ]/.test(password),
    };
    const highlightError = showErrors ? "color-critical-lt" : "";
    const highlightValid = "color-okay-lt";

    const errorText = (
        <span className="color-mid-m-1">
            At least
            <span className={errors.length ? highlightError : highlightValid}>
                {" "}
                8 characters
            </span>
            ,
            <span
                className={errors.lowercase ? highlightError : highlightValid}
            >
                {" "}
                one lowercase
            </span>
            ,
            <span
                className={errors.uppercase ? highlightError : highlightValid}
            >
                {" "}
                one uppercase
            </span>
            ,
            <span className={errors.number ? highlightError : highlightValid}>
                {" "}
                one digit
            </span>
            ,
            <span className={errors.special ? highlightError : highlightValid}>
                {" "}
                one special character
            </span>
        </span>
    );

    return {
        hasError: !passwordRegex.test(password),
        errors: errorText,
    };
};

export const passwordHint = (
    <span className="color-mid-m-1">
        At least 8 characters, one lowercase, one uppercase, one digit, one
        special character
    </span>
);

export const showErrorSuccessIcon = (
    input: string,
    isActive: boolean,
    hasError: boolean
): React.ReactNode => {
    if (isActive || !input) {
        return null;
    }
    return hasError ? (
        <FormErrorIcon className="absolute pl-1 -ml-9 mt-3" />
    ) : (
        <SuccessIcon className="absolute pl-1 -ml-9 mt-3" />
    );
};

export const isDeviceInformation = (
    device: DeviceRow
): device is DeviceInformation =>
    "secure" in device &&
    "screenLock" in device &&
    "biometrics" in device &&
    "version" in device &&
    "guidesCompleted" in device &&
    "device" in device &&
    "os" in device &&
    "securityPatchVersion" in device &&
    "isLatestVersion" in device &&
    "latestAppVersion" in device &&
    "osVersionState" in device &&
    "isNotificationsEnabled" in device &&
    "latestInsecureScanDate" in device &&
    "platform" in device &&
    "latestOSVersion" in device &&
    "isWorkProfile" in device &&
    "latestScanDate" in device &&
    "isPlayProtectEnabled" in device &&
    "threats" in device;
