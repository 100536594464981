import { ButtonHTMLAttributes, FC, PropsWithChildren, ReactChild } from "react";

import "components/button/Button.scss";

export interface ButtonProps
    extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
    /**
     * The different ways that buttons can look
     */
    appearance?:
        | "primary"
        | "secondary"
        | "tertiary"
        | "alert"
        | "icon"
        | "link"
        | "jumbo";

    /**
     * The text on the button or the aria label when the button is an icon
     */
    label?: string;

    /**
     * An icon to display
     */
    icon?: ReactChild;

    /**
     * Where to draw the icon relative to the label text
     */
    iconPosition?: "primary" | "before" | "after";
}

/**
 * Its a button. You click it, it does stuff.
 */
export const Button: FC<ButtonProps> = ({
    appearance = "primary",
    label,
    className = "",
    icon,
    iconPosition,
    children,
    name,
    ...props
}: ButtonProps) => {
    let resolvedName: string;

    if (name === undefined) {
        resolvedName = label?.toLocaleLowerCase() as string;
    } else {
        resolvedName = name.toLocaleLowerCase();
    }

    const classes = ["ivy-button", `ivy-button--${appearance}`];
    if (className !== "") {
        classes.push(className);
    }

    const Content = () => {
        if (icon !== undefined) {
            switch (iconPosition) {
                case "before":
                    return (
                        <>
                            <div className="flex-row flex-vc">
                                {icon}
                                <div>{label}</div>
                            </div>
                        </>
                    );

                case "after":
                    return (
                        <>
                            <div className="flex-row flex-vc">
                                <div>{label}</div>
                                {icon}
                            </div>
                        </>
                    );
                case "primary":
                default:
                    return <>{icon}</>;
            }
        }

        return <>{label}</>;
    };

    return (
        <button
            name={resolvedName}
            aria-label={icon && iconPosition === "primary" ? label : undefined}
            className={classes.join(" ")}
            {...props}
        >
            <Content />
            {children}
        </button>
    );
};

export interface PageButtonProps
    extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
    currentPage: number;
    page: number;
    paginate: (pageIndex: number) => void;
}

export const PageButton: FC<PageButtonProps> = ({
    currentPage,
    page,
    paginate,
    ...props
}: PageButtonProps) => (
    <button
        className={`${
            currentPage === page
                ? "text-white color-bg-action-lt rounded-full"
                : "color-bg-light-m-1 hover:underline"
        } p-1 px-2 mx-1 border-none cursor-pointer`}
        key={page}
        onClick={() => paginate(page)}
        disabled={currentPage === page}
        {...props}
    >
        {page}
    </button>
);
