import { FC } from "react";
import usePageTitle from "main/utils/usePageTitle";
import AlertsConsole from "./AlertsConsole";

const AlertsConsolePage: FC = () => {
    usePageTitle("Alerts Console");
    return <AlertsConsole />;
};

export default AlertsConsolePage;
