import { MouseEvent, FC, useEffect, useState } from "react";
import { useConfirmMembers, useOrgMembers } from "main/add-members/hooks";
import usePageTitle from "main/utils/usePageTitle";
import Analytics, { Events } from "main/utils/Analytics";
import BackIcon from "icons/ts/Back";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "main/app/types";
import { Button } from "components/button/Button";
import { Group, GroupMembers, Member, Members } from "main/add-members/types";
import { DisplayMember, MemberList } from "components/MemberList/Memberlist";
import ConfigurationLayout from "main/ConfigurationLayout";
import { displayWarningBanner } from "main/utils/displayBanner";
import { withEngSuffix } from "main/utils/utils";

interface MemberConfirmationPageProps {
    ssoGroups: Group[];
    ssoMemberCount: number;
    members: Member[];
    orgMembers: Member[];
    sso: boolean;
}

export const MemberConfirmationPage: FC<MemberConfirmationPageProps> = (
    props: MemberConfirmationPageProps
) => {
    usePageTitle("Review Your Members");
    const navigate = useNavigate();

    const { loading, createMembers, createSsoMembers } = useConfirmMembers();
    const { ssoGroups, ssoMemberCount, members, orgMembers, sso } = props;
    const [displayMembers, setDisplayMembers] = useState<DisplayMember[]>([]);
    const [allowedDupes, setAllowedDupes] = useState<Member[]>([]);

    let filteredMembers: Member[] = [];
    filteredMembers = members.filter(
        (member) =>
            !orgMembers.find(
                (orgMember) =>
                    orgMember.email.toLocaleLowerCase() ===
                    member.email.toLocaleLowerCase()
            )
    );
    filteredMembers = filteredMembers.filter(
        (member, index) =>
            !filteredMembers.find(
                (m, i) =>
                    m.email.toLocaleLowerCase() ===
                        member.email.toLocaleLowerCase() && i < index
            )
    );

    useEffect(() => {
        const emails = new Set();
        const membersToDsiplay: DisplayMember[] = members.map((member) => {
            const orgDuplicate = orgMembers.find(
                (orgMember) =>
                    orgMember.email.toLocaleLowerCase() ===
                    member.email.toLocaleLowerCase()
            );
            const entryDuplicate = emails.has(member.email);
            emails.add(member.email);
            return {
                id: member.id,
                name: member.name,
                email: member.email,
                duplicate: !!orgDuplicate || !!entryDuplicate,
                allowedDupe: false,
            };
        });
        setDisplayMembers(membersToDsiplay);
    }, [members, orgMembers]);

    useEffect(() => {
        const duplicates = displayMembers
            .filter((member) => member.allowedDupe)
            .map((member) => ({
                id: member.id,
                name: member.name,
                email: member.email,
            }));
        setAllowedDupes(duplicates);
    }, [displayMembers]);

    const confirmClicked = (e: MouseEvent) => {
        e.preventDefault();
        Analytics.event(Events.send_invitations);
        if (sso) {
            createSsoMembers(ssoGroups, allowedDupes);
        } else {
            createMembers(filteredMembers, allowedDupes);
        }
    };

    const handlePreviousStep = () => {
        if (sso) {
            Analytics.event(Events.back_to_sso_groups);
        } else {
            Analytics.event(Events.back_to_member_addition);
        }
        navigate(-1);
    };

    const breadcrumbs = () => {
        if (sso) {
            return (
                <>
                    <div className="mas color-mid-p-1">1. SSO Settings</div>
                    <div className="mas color-mid-p-1">2. Select Groups</div>
                    <div className="mas">3. Send Invitations</div>
                </>
            );
        }

        return (
            <>
                <div className="mas color-mid-p-1">1. Enter Members</div>
                <div className="mas">2. Send Invitations</div>
            </>
        );
    };

    const nonDuplicateMembersCount =
        filteredMembers.length + allowedDupes.length;
    const totalInvites = sso
        ? ssoMemberCount - (members.length - nonDuplicateMembersCount)
        : nonDuplicateMembersCount;

    return (
        <ConfigurationLayout>
            <div className="special-case-title color-action-lt proper-case mbs">
                Team Members Added
            </div>

            <div className="title-three flex-row flex-hc">{breadcrumbs()}</div>

            <p className="paragraph-one text-center">
                An email will be sent to each individual with a unique code to
                enroll their device in your organization.
            </p>

            {filteredMembers.length < members.length && (
                <>
                    <p className="paragraph-two text-center">
                        Devices are already registered for the crossed members.
                    </p>
                    <p className="paragraph-two text-center">
                        Click on the crossed members to add them back to the
                        list and register additional devices.
                    </p>
                </>
            )}

            <p
                className="paragraph-three text-center mvs"
                data-testid="member-count-message"
            >
                {totalInvites === 0 ? (
                    <>
                        No new members will be added. Undo deletion to send new
                        access codes.
                    </>
                ) : (
                    <>
                        {totalInvites.toLocaleString()}{" "}
                        {withEngSuffix("code", totalInvites)} will be generated
                        and billing will be updated to reflect the new device
                        count.
                    </>
                )}
            </p>

            <div className="flex-1 flex-vc">
                <div className="flex-row flex-vc flex-hb">
                    <div className="flex-hs heading-four color-dark-p-1">
                        DETAILS
                    </div>
                    <Button
                        appearance="link"
                        label="Return to Previous Step"
                        onClick={handlePreviousStep}
                        className="color-action-lt rubik flex-row flex-vc"
                        icon={<BackIcon />}
                        iconPosition="before"
                    />
                </div>

                <MemberList
                    ssoMemberPreviewCount={
                        sso ? ssoMemberCount - members.length : 0
                    }
                    displayMembers={displayMembers}
                    setDisplayMembers={setDisplayMembers}
                />
            </div>

            <Button
                disabled={
                    loading ||
                    (filteredMembers.length === 0 && allowedDupes.length === 0)
                }
                onClick={confirmClicked}
                className="max-width"
                label={loading ? "Sending..." : "Send Invitations"}
            />
        </ConfigurationLayout>
    );
};

const ConnectedMemberConfirmationPage: FC = () => {
    const session = useSelector((state: ApplicationState) => state.session);
    const { orgMembers, fetchOrgMembers, orgMembersLoading } = useOrgMembers();
    const sso = !!session.organization.ssoProvider;

    const location = useLocation();
    const [members, setMembers] = useState<Member[]>([]);
    const [ssoGroupsMembers, setSsoGroupsMembers] = useState<GroupMembers>({
        memberCount: 0,
        members: [],
    });
    const { isUsingSso = true } = location.state;

    useEffect(() => {
        fetchOrgMembers();
    }, []);

    useEffect(() => {
        (async () => {
            if (sso && isUsingSso) {
                const groupMembers = location.state
                    .groupsMembers as GroupMembers;
                setSsoGroupsMembers(groupMembers);
                setMembers(groupMembers.members);
            } else {
                const memberHistory = location.state as
                    | Members
                    | { members: []; error: ""; isUsingSso: false };
                const { members: newMembers, error } = memberHistory;
                if (error.length > 0) {
                    displayWarningBanner(error);
                }
                setMembers(newMembers);
            }
        })();
    }, [location.state]);

    return orgMembersLoading ? (
        <></>
    ) : (
        <MemberConfirmationPage
            ssoGroups={location.state.groups as Group[] | []}
            ssoMemberCount={ssoGroupsMembers.memberCount || 0}
            members={members}
            orgMembers={orgMembers}
            sso={sso && isUsingSso}
        />
    );
};

export default ConnectedMemberConfirmationPage;
