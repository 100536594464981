import NewIVerifyLogo from "icons/ts/NewLogo";
import { FC } from "react";

const Dashboard: FC = () => (
    <div className="flex-col flex-1 h-vh-95">
        <div className="flex-col flex-1">
            <div className="bg-white h-[calc(100vh_-_64px)] flex flex-row phm justify-center items-center">
                <div className="block w-[520px] text-center">
                    <NewIVerifyLogo />
                    <div className="text-black text-4xl font-semibold basis-2/12 mt-6">
                        Our New Portal is Live!
                    </div>
                    <div className="text-black text-xl basis-2/12 mt-6">
                        Experience our upgraded platform with enhanced features
                        and a smoother experience at{" "}
                        <span
                            className="underline text-[#6f2fee]"
                            role="link"
                            tabIndex={0}
                            onClick={() =>
                                window.open("https://edr.iverify.io", "_blank")
                            }
                            onKeyDown={(e) => {
                                if (e.key === "Enter" || e.key === " ") {
                                    window.open(
                                        "https://edr.iverify.io",
                                        "_blank"
                                    );
                                }
                            }}
                        >
                            edr.iverify.io
                        </span>
                    </div>
                    <div
                        className="bg-[#6f2fee] w-[110px] h-[38px] px-2 py-1 rounded cursor-pointer mt-6 mx-auto"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            window.location.href = "https://edr.iverify.io";
                        }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                                window.location.href = "https://edr.iverify.io";
                            }
                        }}
                    >
                        <div className="text-center text-white text-sm font-medium font-['Rubik'] leading-normal mt-2">
                            Go To Portal
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Dashboard;
