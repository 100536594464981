import { FC } from "react";

const AddIcon: FC = () => (
    <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M0.666504 14C0.666504 6.63616 6.63604 0.666626 13.9998 0.666626C17.5361 0.666626 20.9274 2.07138 23.4279 4.57187C25.9284 7.07235 27.3332 10.4637 27.3332 14C27.3332 21.3638 21.3636 27.3333 13.9998 27.3333C6.63604 27.3333 0.666504 21.3638 0.666504 14ZM18.6665 15.3333C19.0347 15.3333 19.3332 15.0348 19.3332 14.6666V13.3333C19.3332 12.9651 19.0347 12.6666 18.6665 12.6666H15.3332V9.33329C15.3332 8.9651 15.0347 8.66663 14.6665 8.66663H13.3332C12.965 8.66663 12.6665 8.9651 12.6665 9.33329V12.6666H9.33317C8.96498 12.6666 8.6665 12.9651 8.6665 13.3333V14.6666C8.6665 15.0348 8.96498 15.3333 9.33317 15.3333H12.6665V18.6666C12.6665 19.0348 12.965 19.3333 13.3332 19.3333H14.6665C15.0347 19.3333 15.3332 19.0348 15.3332 18.6666V15.3333H18.6665Z"
            fill="#007DB3"
        />
    </svg>
);

export default AddIcon;
