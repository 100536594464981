import { FC, FormEvent } from "react";
import { useLookup } from "main/org-lookup/hooks";
import TitleLayout from "main/layouts/TitleLayout";
import usePageTitle from "main/utils/usePageTitle";
import Analytics, { Events } from "main/utils/Analytics";
import { Button } from "components/button/Button";
import { AppRoutes } from "main/app/App";
import { LabeledInput } from "components/input/Input";
import { useForm } from "components/form/useForm";
import { Link } from "components/Link";

const OrgLookupPage: FC = () => {
    usePageTitle("Enter your Organization Name");

    const { loading, lookupCompany } = useLookup();

    const { inputs, onChange, filled } = useForm({
        company: "",
    });

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        Analytics.event(Events.lookup_company);
        lookupCompany(inputs.company as string);
    };

    return (
        <TitleLayout>
            <div className="flex-row text-left width-small flex-hc">
                <div className="flex">
                    <form onSubmit={handleSubmit} onChange={onChange}>
                        <LabeledInput
                            label="Organization Name"
                            data-testid="org-name"
                            name="company"
                            placeholder="my-organization-name"
                        />
                        <Button
                            className="max-width"
                            disabled={!filled || loading}
                            data-testid="org-name-submit"
                            label={loading ? "Loading..." : "Lookup"}
                        />
                        <div className="mtxl h6 text-center">
                            Don&apos;t have an account?&nbsp;
                            <Link
                                to={AppRoutes.salesEmailPath}
                                event={Events.sign_up_link}
                            >
                                Email us to get started
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </TitleLayout>
    );
};

export default OrgLookupPage;
