import { useState } from "react";
import { Button } from "components/button/Button";
import { displayInfoBanner } from "main/utils/displayBanner";
import { withEngSuffix } from "main/utils/utils";
import {
    AlertActions,
    AlertRow,
    AlertStatus,
    AlertTableFilters,
} from "./types";
import { useUpdateAlertStatus } from "./hooks";

export type ActionMenuHook = {
    actionsMenu: (
        rowSelections: AlertRow[],
        filters: AlertTableFilters
    ) => JSX.Element;
};

export const useActionMenu = (): ActionMenuHook => {
    const [selectedAction, setSelectedAction] = useState<AlertActions | null>(
        AlertActions.SELECT
    );
    const { loading, updateAlertStatus } = useUpdateAlertStatus();

    const handleAction =
        (rowSelections: AlertRow[], filters: AlertTableFilters) => () => {
            // eslint-disable-next-line no-nested-ternary
            const status = selectedAction
                ? selectedAction === AlertActions.CLOSE_ALERTS
                    ? AlertStatus.CLOSED
                    : AlertStatus.OPEN
                : null;

            if (status) {
                const areAllRowSelectionsSameStatus = rowSelections.every(
                    (row) => row.alertStatus === rowSelections[0].alertStatus
                );

                if (
                    areAllRowSelectionsSameStatus &&
                    status ===
                        AlertStatus[
                            rowSelections[0]
                                .alertStatus as keyof typeof AlertStatus
                        ]
                ) {
                    displayInfoBanner(
                        `${withEngSuffix(
                            "Alert",
                            rowSelections.length
                        )} already ${status.toLowerCase()}`
                    );
                } else {
                    updateAlertStatus(rowSelections, status, filters);
                }

                setSelectedAction(AlertActions.SELECT);
            }
        };

    const actionDropdown = (allowActions: boolean) => {
        const currentValue = `${
            allowActions ? selectedAction : "enable-action"
        }`;

        return (
            <select
                data-testid="alerts-action-dropdown"
                value={currentValue}
                onChange={(e) =>
                    setSelectedAction(e.target.value as AlertActions)
                }
                disabled={!allowActions}
                className={`dashboard-dropdown ${
                    allowActions
                        ? "dashboard-dropdown-selected"
                        : "dashboard-dropdown-not-selected"
                }`}
            >
                <option value="enable-action" hidden>
                    Select alert to enable options
                </option>
                <option value={AlertActions.SELECT}>Select option</option>
                <option value={AlertActions.CLOSE_ALERTS}>
                    Set status to Closed
                </option>
                <option value={AlertActions.OPEN_ALERTS}>
                    Set status to Open
                </option>
            </select>
        );
    };

    const actionsButton = (
        allowActions: boolean,
        rowSelections: AlertRow[],
        filters: AlertTableFilters
    ) => {
        const visibility =
            selectedAction !== AlertActions.SELECT && allowActions
                ? "visible"
                : "hidden";
        return (
            <Button
                className="h-8 py-0 px-2 self-center mr-1"
                appearance="tertiary"
                data-testid="action-button"
                style={{ visibility }}
                onClick={handleAction(rowSelections, filters)}
                disabled={loading}
                label={loading ? "Updating..." : "Update"}
            />
        );
    };

    const actionsMenu = (
        rowSelections: AlertRow[],
        filters: AlertTableFilters
    ) => {
        const allowActions = rowSelections.length > 0;
        return (
            <>
                <div className="dashboard-dropdown-menu self-center">
                    <div className="dashboard-dropdown-label">ACTION</div>
                    {actionDropdown(allowActions)}
                </div>
                {actionsButton(allowActions, rowSelections, filters)}
            </>
        );
    };

    return { actionsMenu };
};
