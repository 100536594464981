import Footer from "main/Footer";
import { FC, useEffect, useRef, useState } from "react";

import TableFooter from "main/dashboard/components/TableFooter";
import { useLocation, useNavigate } from "react-router-dom";
import useDidMountEffect from "main/utils/useDidMountEffect";
import { DEFAULT_PAGE } from "main/app/types";
import { Button } from "components/button/Button";
import BigCheckmarkIcon from "icons/ts/BigCheckmarkIcon";
import { useAlerts } from "./hooks";
import AlertsTable from "./AlertsTable";
import { AlertFilters, AlertRow, StatusFilter } from "./types";
import { useFilter } from "./useFilter";
import { useActionMenu } from "./useActionMenu";

const AlertsConsole: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState<AlertRow[]>([]);
    const [rowSelections, setRowSelections] = useState<AlertRow[]>([]);
    const tableRef = useRef<HTMLDivElement>(null);

    const {
        loading: alertsConsoleLoading,
        currentPage,
        pageSize,
        paginatedAlerts,
        totalPages,
        fetchDashboardAlerts,
        setCurrentPage,
        setPageSize,
        clearPaginatedAlerts,
    } = useAlerts();

    const { filterMenu, filters } = useFilter();
    const { actionsMenu } = useActionMenu();

    // Add page, pageSize and filters to url query params on initial render
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        if (filters.status === StatusFilter.NONE) {
            query.delete(AlertFilters.STATUS);
        } else {
            query.set(AlertFilters.STATUS, filters.status as string);
        }

        navigate({
            search: query.toString(),
        });
    }, []);

    useEffect(() => {
        fetchDashboardAlerts(undefined, undefined, filters);
    }, []);

    useDidMountEffect(() => {
        clearPaginatedAlerts();
        fetchDashboardAlerts(DEFAULT_PAGE, pageSize, filters);
        setCurrentPage(DEFAULT_PAGE);
    }, [filters]);

    useEffect(() => {
        const pageRows = paginatedAlerts[currentPage] || [];
        setFilteredData(pageRows);
    }, [paginatedAlerts]);

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.scrollTop = 0;
        }
    }, [paginatedAlerts]);

    return (
        <div className="flex-col flex-1 h-vh-95">
            <div className="flex-col flex-1">
                <div
                    data-testid="alerts-console"
                    className="dashboard flex flex-row phm"
                >
                    <div className="dashboard-title basis-2/12">Alerts</div>
                    <div className="dashboard-options basis-5/12 self-center">
                        {filterMenu}
                    </div>
                    <div className="dashboard-options basis-5/12 self-center">
                        <div className="flex flex-row justify-end">
                            {actionsMenu(rowSelections, filters)}
                        </div>
                    </div>
                </div>
                {filteredData.length === 0 && !alertsConsoleLoading ? (
                    <div className="flex-row justify-center items-center mt-10">
                        <div className="bg-[#F6FCFF] rounded-lg border-2 border-solid border-[#E5F7FE] w-3/4 p-6 my-5 text-center">
                            <div className="color-action-lt">
                                <BigCheckmarkIcon />
                                <p className="title-three mtm">
                                    No alerts found for your organization!
                                </p>
                                <p className="paragraph-one mt-2 mb-5">
                                    Any detections made by our threat hunting
                                    tools will be shown here
                                </p>
                                <Button
                                    label="View device scans"
                                    onClick={() => navigate("/dashboard")}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div
                        className="dashboard-table mt-4 mb-1 rounded-md overflow-y-scroll h-vh-80"
                        ref={tableRef}
                    >
                        <>
                            <div className="min-h-[92%]">
                                <AlertsTable
                                    data={filteredData}
                                    loading={alertsConsoleLoading}
                                    setSelections={setRowSelections}
                                />
                            </div>
                            <TableFooter
                                totalPages={totalPages}
                                currentPage={currentPage}
                                pageSize={pageSize}
                                paginate={setCurrentPage}
                                setPageSize={setPageSize}
                            />
                        </>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default AlertsConsole;
