import { FC } from "react";
import { Tooltip as ReactTooltip, ITooltip } from "react-tooltip";
import "./Tooltip.scss";

interface TooltipProps extends ITooltip {
    id?: string;
    className?: string;
}

const Tooltip: FC<TooltipProps> = (props: TooltipProps) => {
    const { id, ...rest } = props;
    return <ReactTooltip id={id} delayShow={300} opacity={1} {...rest} />;
};

export const toggleTooltip = (id: string, showTooltip: boolean): void => {
    const tooltip = document.getElementById(id);
    if (tooltip) {
        if (showTooltip) {
            tooltip.style.display = "block";
        } else {
            tooltip.style.display = "none";
        }
    }
};

export default Tooltip;
