import { FC, FormEvent, useEffect, useState } from "react";
import { useResetPassword } from "main/password-reset/hooks";
import { Button } from "components/button/Button";
import useEmailInput from "components/EmailInput/EmailInput";
import { useForm } from "components/form/useForm";
import PasswordPage from "main/password-reset/PasswordPage";

export const PasswordResetForm: FC = () => {
    const [reset, setReset] = useState<boolean>(false);
    const { inputs, onChange, filled, valid, setValid } = useForm({
        email: "",
    });

    const { loading, resetPassword } = useResetPassword();
    const { emailInput, error: emailError } = useEmailInput();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        resetPassword(inputs.email as string);
        setReset(true);
    };

    useEffect(() => {
        setValid(filled && emailError === "");
    }, [filled, emailError]);

    const resetForm = (
        <div className="width-xsmall">
            <div className="h5 mbl mtxs">
                Enter your email used for this account.
            </div>
            <form
                onSubmit={handleSubmit}
                onChange={onChange}
                className="color-bg-lighter-grey"
            >
                {emailInput}
                <Button
                    className="max-width mbs"
                    disabled={loading || !valid}
                    label={loading ? "Sending..." : "Send"}
                />
            </form>
        </div>
    );

    const resetConfirmation = (
        <div className="width-xlarge">
            <div className="h5 mbl mtxs">
                We&apos;ve sent password reset instructions to your email
                address if it is associated with an account.
            </div>
            <div className="h6 mbxl color-mid italic">
                If no email is received within ten minutes, check that the
                submitted address is correct.
            </div>
        </div>
    );

    const pageBody = reset ? resetConfirmation : resetForm;
    return (
        <>
            <div className="form-title">Password Recovery</div>
            {pageBody}
        </>
    );
};

const PasswordResetPage: FC = () => (
    <PasswordPage>
        <PasswordResetForm />
    </PasswordPage>
);

export default PasswordResetPage;
