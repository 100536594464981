import { AnyAction } from "redux";
import initialState from "main/app/state";

import { SessionState } from "main/app/types";

export enum SessionActions {
    FetchSessionData = "FetchSessionData",
    PaymentUpdated = "PaymentUpdated",
    Delete = "Delete",
}

export default function sessionReducer(
    state = initialState.session,
    action: AnyAction
): SessionState {
    switch (action.type) {
        case SessionActions.FetchSessionData: {
            return {
                ...action.session,
            };
        }
        case SessionActions.PaymentUpdated: {
            return {
                user: state.user,
                organization: state.organization,
                portalPreferences: state.portalPreferences,
            };
        }
        case SessionActions.Delete: {
            return {
                ...initialState.session,
            };
        }
        default:
            return state;
    }
}
