import LoadingBar from "components/LoadingBar/LoadingBar";
import { ThreatHunterUser } from "main/add-members/types";
import { apiClient } from "main/utils/ApiClient";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import handleResponse from "main/utils/HandleResponse";
import { useTable } from "react-table";
import { useSelector } from "react-redux";
import { ApplicationState } from "main/app/types";
import { useLocation, useNavigate } from "react-router-dom";
import usePageTitle from "main/utils/usePageTitle";
import { AppRoutes } from "main/app/App";
import { LabeledInput } from "components/input/Input";
import { Button } from "components/button/Button";
import AddIcon from "icons/ts/AddIcon";
import { displaySuccessBanner } from "main/utils/displayBanner";

export type ThreatHunterHook = {
    threatHunterUsers: ThreatHunterUser[];
    loading: boolean;
    fetchThreatHunterUsers: () => void;
};

export type AddThreatHunterUserHook = {
    loading: boolean;
    addThreatHunterUser: (
        name: string,
        email: string,
        onAddSuccess: () => void
    ) => void;
};

export const useThreatHunter = (): ThreatHunterHook => {
    const [threatHunterUsers, setThreatHunterUsers] = useState<
        ThreatHunterUser[]
    >([]);
    const { loading, execute } = useLoadingCall(apiClient.getThreatHunterUsers);

    const fetchThreatHunterUsers = useCallback(() => {
        execute()
            .then((users: ThreatHunterUser[]) => {
                setThreatHunterUsers(users);
            })
            .catch((e) => {
                handleResponse(e, "Problem requesting Threat Hunter users");
            });
    }, []);

    return { fetchThreatHunterUsers, threatHunterUsers, loading };
};

export const useAddThreatHunterUser = (): AddThreatHunterUserHook => {
    const { loading, execute } = useLoadingCall(apiClient.addThreatHunterUser);
    const addThreatHunterUser = useCallback(
        (name: string, email: string, onAddSuccess: () => void) => {
            execute(name, email)
                .then(() => {
                    onAddSuccess();
                    displaySuccessBanner(
                        `${name} has been invited to Threat Hunter, and will receive an email with further instructions.`
                    );
                })
                .catch((e) => {
                    handleResponse(e, "Failed to add Threat Hunter User");
                });
        },
        []
    );

    return { loading, addThreatHunterUser };
};

export const DisconnectedThreatHunterTab: React.FC = () => {
    const [addUserForm, setAddUserForm] = useState({
        name: "",
        email: "",
    });

    const { threatHunterUsers, loading, fetchThreatHunterUsers } =
        useThreatHunter();

    const { loading: addThreatHunterUsersLoading, addThreatHunterUser } =
        useAddThreatHunterUser();

    useEffect(() => {
        fetchThreatHunterUsers();
    }, []);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        addThreatHunterUser(addUserForm.name, addUserForm.email, () => {
            setAddUserForm({
                name: "",
                email: "",
            });
            fetchThreatHunterUsers();
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name" as keyof ThreatHunterUser,
            },
            {
                Header: "Email",
                accessor: "email" as keyof ThreatHunterUser,
            },
            {
                Header: "Access Code",
                accessor: "accessCode" as keyof ThreatHunterUser,
            },
            {
                Header: "Status",
                accessor: "status" as keyof ThreatHunterUser,
            },
        ],
        []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data: threatHunterUsers as ThreatHunterUser[],
        });

    return (
        <>
            {loading && <LoadingBar />}
            <div className="plm">
                <div className="heading-two pt-5 pbs">
                    Provision Threat Hunter access
                </div>
                <form
                    data-testid="add-admin-form"
                    onSubmit={handleSubmit}
                    className="width-medium"
                >
                    <div className="flex-row items-center">
                        <LabeledInput
                            placeholder="Full Name"
                            label="Full Name"
                            name="name"
                            type="text"
                            className="mrs flex-2"
                            value={addUserForm.name}
                            onChange={(e) =>
                                setAddUserForm({
                                    ...addUserForm,
                                    name: e.target.value,
                                })
                            }
                        />
                        <LabeledInput
                            placeholder="name@example.com"
                            label="Email"
                            name="email"
                            type="email"
                            className="mrs flex-2"
                            value={addUserForm.email}
                            onChange={(e) =>
                                setAddUserForm({
                                    ...addUserForm,
                                    email: e.target.value,
                                })
                            }
                        />
                        <Button
                            disabled={
                                addUserForm.name === "" ||
                                addUserForm.email === "" ||
                                addThreatHunterUsersLoading
                            }
                            name="add-user"
                            className="ivy-button--icon pbxs hover:border-none focus:border-none"
                            aria-label="Add"
                        >
                            <AddIcon />
                        </Button>
                    </div>
                </form>
                <p className="heading-two pb-2">Provisioned Users</p>
            </div>
            {threatHunterUsers.length === 0 ? (
                <h3 className="plm italic">
                    No users found. Use the Provision Threat Hunter form to
                    invite users
                </h3>
            ) : (
                <div className="dashboard-table my-2 rounded-md overflow-y-scroll h-vh-50">
                    <table
                        className="border border-light-m-1"
                        {...getTableProps()}
                    >
                        <thead className="color-bg-light-m-1 sticky -top-[0.1px] z-10">
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="pl-4"
                                        >
                                            {
                                                column.render(
                                                    "Header"
                                                ) as React.ReactNode
                                            }
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map((row) => {
                                prepareRow(row);
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => (
                                            <td
                                                {...cell.getCellProps()}
                                                className="pl-4"
                                            >
                                                {
                                                    cell.render(
                                                        "Cell"
                                                    ) as React.ReactNode
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};

const ThreatHunterTab: React.FC = () => {
    usePageTitle("Threat Hunter Portal");

    const session = useSelector((state: ApplicationState) => state.session);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!session?.portalPreferences?.threatHunterAccess) {
            navigate(AppRoutes.dashboardPath);
        }
    }, [session, location]);

    return <DisconnectedThreatHunterTab />;
};

export default ThreatHunterTab;
