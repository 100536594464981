import { FC, useEffect } from "react";
import Dashboard from "main/dashboard/Dashboard";
import usePageTitle from "main/utils/usePageTitle";
import { useLocation } from "react-router-dom";
import {
    BannerInformation,
    BannerType,
    displaySuccessBanner,
} from "main/utils/displayBanner";

const DashboardPage: FC = () => {
    usePageTitle("Dashboard");

    const location = useLocation();

    useEffect(() => {
        (async () => {
            const banner: BannerInformation | undefined = location.state as
                | BannerInformation
                | undefined;
            if (banner !== undefined) {
                switch (banner?.level) {
                    case BannerType.Success:
                        displaySuccessBanner(banner.msg);
                        break;
                    default:
                    // no-op
                }
            }
        })();
    }, [location.state]);

    return <Dashboard />;
};

export default DashboardPage;
