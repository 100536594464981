export const elementFont = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Open+Sans",
        },
    ],
};

export type CheckoutResponse = {
    success?: string;
    error?: string;
};
