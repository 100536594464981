import { Link } from "react-router-dom";

import TitleLayout from "main/layouts/TitleLayout";
import LoginForm from "main/login/LoginForm";
import { AppRoutes } from "main/app/App";
import { FC } from "react";

const LoginPage: FC = () => (
    <TitleLayout>
        <div className="form-title">Account Sign In</div>
        <LoginForm />
        <div className="mtxl">
            <Link to={AppRoutes.resetPasswordPath}>Forgot Password?</Link>
        </div>
    </TitleLayout>
);

export default LoginPage;
