import { AxiosError } from "axios";
import { toast } from "react-toastify";

import {
    displayErrorBanner,
    displayInfoBanner,
    displaySuccessBanner,
    displayWarningBanner,
} from "main/utils/displayBanner";

export default (e: AxiosError | unknown, errMsg: string): void => {
    try {
        // @ts-ignore
        const { success, info, warning, error } = e?.response?.data ?? {};
        if (success) {
            displaySuccessBanner(success);
        }
        if (info) {
            displayInfoBanner(info);
        }
        if (warning) {
            displayWarningBanner(warning);
        }
        if (error) {
            displayErrorBanner(error);
        }
    } catch (_) {
        // eslint-disable-next-line no-console
        console.error(e);
        const msg = `Error: ${errMsg}`;
        toast.error(msg, { toastId: msg });
    }
};
