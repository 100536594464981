import React, { useEffect, useState } from "react";
import { KeyLookup } from "main/create-account/types";

export interface FormProps {
    inputs: KeyLookup<unknown>;
    onChange: (event: React.ChangeEvent<HTMLFormElement>) => void;
    valid: boolean;
    setValid: (valid: boolean) => void;
    filled: boolean;
    reset: () => void;
}

export const useForm = (initialState = {}): FormProps => {
    const [inputs, setInputs] = useState(initialState);
    const [valid, setValid] = useState(false);
    const [filled, setFilled] = useState(false);

    const onChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        setInputs({ ...inputs, [event.target.name]: event.target.value });
    };

    const reset = () => {
        setInputs(initialState);
    };

    useEffect(() => {
        const allFieldsFilledIn = Object.values(inputs).every(
            (value) => !!value
        );
        setFilled(allFieldsFilledIn);
    }, [inputs]);

    return {
        onChange,
        inputs,
        valid,
        setValid,
        filled,
        reset,
    };
};
