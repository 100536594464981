import { AxiosError } from "axios";
import { User } from "main/settings/types";

import {
    apiClient,
    BillingDetails,
    BillingKey,
    CardDetails,
    PlanStatus,
    UpdatePaymentStatus,
} from "main/utils/ApiClient";
import { useCallback, useState } from "react";
import {
    displayErrorBanner,
    displaySuccessBanner,
} from "main/utils/displayBanner";
import { useDispatch } from "react-redux";
import { SessionActions } from "main/login/sessionReducer";
import { AppRoutes } from "main/app/App";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import { useLogout } from "main/login/hooks";
import Analytics, { Events } from "main/utils/Analytics";
import { toHumanReadableDate } from "main/utils/utils";
import {
    ApiConfiguration,
    ApiTokenExpiration,
    WebhookConfig,
    EmailPreferences,
    EmailTemplate,
    NotificationPreference,
    EmailTemplateType,
    InternalNotificationTopic,
} from "main/app/types";

export type CustomerBillingDetailsHook = {
    invoice: boolean;
    loading: boolean;
    hasPaymentMethod: boolean;
    paymentStatement: string;
    fetchCustomerBillingDetails: () => void;
};

export const useCustomerBillingDetails = (): CustomerBillingDetailsHook => {
    const [paymentStatement, setPaymentStatement] = useState<string>("");
    const [card, setCard] = useState<CardDetails | undefined>(undefined);
    const [invoice, setInvoice] = useState<boolean>(false);

    const { loading, execute } = useLoadingCall(apiClient.getBillingDetails);

    const fetchCustomerBillingDetails = useCallback(() => {
        execute()
            .then((details: BillingDetails) => {
                const { subscription } = details;

                setInvoice(subscription.invoice);

                if (subscription.status === "canceled") {
                    setPaymentStatement("No payment scheduled");
                } else if (
                    !!subscription.renewDate &&
                    (!!subscription.amountDue || subscription.amountDue === 0) // We can have $0 amount due, as a hack to skip auto invoices. To resolve in https://github.com/iverify-team/iverify/issues/4179
                ) {
                    const dollars = subscription.amountDue / 100;
                    const amount = dollars.toLocaleString(undefined, {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    });

                    const endDate = toHumanReadableDate(
                        subscription.renewDate * 1000,
                        { time: false }
                    );
                    if (details.card) {
                        setCard(details.card);
                        const { network, lastFour } = details.card;
                        setPaymentStatement(
                            `Next payment (estimated) of ${amount} scheduled on ${endDate} to ${network} ending ****${lastFour}`
                        );
                    } else if (subscription.invoice) {
                        setPaymentStatement(
                            subscription.amountDue === 0
                                ? `Your organization is set up for custom billing.`
                                : `Next invoice (estimated) of ${amount} to be billed on ${endDate}`
                        );
                    }
                } else {
                    setPaymentStatement(
                        `Unable to retrieve payment details. Please try again later.`
                    );
                }
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Problem requesting billing information.");
            });
    }, []);

    return {
        loading,
        paymentStatement,
        invoice,
        hasPaymentMethod: !!card,
        fetchCustomerBillingDetails,
    };
};

export type CustomerPlanDetailsHook = {
    status: string;
    statusMessage: string;
    loading: boolean;
    fetchCustomerPlanDetails: () => void;
    cancelSubscription: () => void;
    reactivateSubscription: () => void;
    deleteOrganization: () => void;
};

export const useCustomerPlanDetails = (): CustomerPlanDetailsHook => {
    const [status, setStatus] = useState<string>("");
    const [statusMessage, setStatusMessage] = useState<string>("");
    const { loading: getPlanDetailsLoading, execute: getPlanDetails } =
        useLoadingCall(apiClient.getPlanDetails);
    const logout = useLogout();

    const fetchCustomerPlanDetails = useCallback(() => {
        getPlanDetails()
            .then((plan: PlanStatus) => {
                const {
                    relevantDate,
                    statusMessage: msg,
                    status: stripeStatus,
                } = plan;
                if (relevantDate) {
                    setStatusMessage(
                        `${msg} ${toHumanReadableDate(relevantDate * 1000, {
                            time: false,
                        })}`
                    );
                } else {
                    setStatusMessage(msg);
                }
                setStatus(stripeStatus);
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Problem requesting plan information.");
            });
    }, []);

    const { loading: cancelPlanLoading, execute: cancelPlan } = useLoadingCall(
        apiClient.cancelPlan
    );
    const cancelSubscription = useCallback(() => {
        cancelPlan()
            .then(() => {
                displaySuccessBanner(
                    "Subscription cancelled. Status may not immediately update."
                );
                setStatus("canceled");
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Failed to cancel subscriptions");
            });
    }, []);

    const {
        loading: deleteOrganizationLoading,
        execute: deleteOrganizationCall,
    } = useLoadingCall(apiClient.deleteOrganization);

    const {
        loading: reactivateOrganizationLoading,
        execute: reactivateOrganizationCall,
    } = useLoadingCall(apiClient.reactivatePlan);

    const reactivateSubscription = useCallback(() => {
        reactivateOrganizationCall()
            .then(() => {
                displaySuccessBanner(
                    "Subscription reactivated. Status may not immediately update."
                );
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Failed to reactivate subscriptions");
            });
    }, []);

    const deleteOrganization = useCallback(() => {
        deleteOrganizationCall()
            .then(() => {
                displaySuccessBanner("Organization deleted");
                logout();
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Failed to delete organization");
            });
    }, []);

    const loading =
        getPlanDetailsLoading ||
        cancelPlanLoading ||
        deleteOrganizationLoading ||
        reactivateOrganizationLoading;

    return {
        loading,
        status,
        statusMessage,
        fetchCustomerPlanDetails,
        cancelSubscription,
        reactivateSubscription,
        deleteOrganization,
    };
};

export type UpdatedPaymentMethodHook = {
    fetchStripeClientSecret: () => Promise<string>;
    updateStripePaymentInfo: (
        paymentMethod: string
    ) => Promise<UpdatePaymentStatus>;
};

export const useUpdatedPaymentMethod = (): UpdatedPaymentMethodHook => {
    const fetchStripeClientSecret = useCallback(
        () =>
            apiClient
                .getBillingUpdateKey()
                .then((key: BillingKey) => key.clientSecret)
                .catch((e: AxiosError) => {
                    handleResponse(
                        e,
                        "Problem requesting payment update information."
                    );
                    return "";
                }),
        []
    );

    const updateStripePaymentInfo = useCallback(
        (paymentMethod: string) =>
            apiClient.updateDefaultPaymentMethod(paymentMethod),
        []
    );

    return { fetchStripeClientSecret, updateStripePaymentInfo };
};

export type OrgAdminHook = {
    admins: User[];
    loading: boolean;
    fetchOrgAdmins: () => void;
};

export const useOrgAdmin = (): OrgAdminHook => {
    const [admins, setAdmins] = useState<User[]>([]);
    const { loading, execute } = useLoadingCall(apiClient.getOrgAdmins);

    const fetchOrgAdmins = useCallback(() => {
        execute()
            .then((users: User[]) => {
                setAdmins(users);
            })
            .catch((e: AxiosError) => {
                handleResponse(
                    e,
                    "Problem requesting organization administrators."
                );
            });
    }, []);

    return { fetchOrgAdmins, admins, loading };
};

export type SsoIntegrationHook = {
    loading: boolean;
    deleteSsoIntegration: () => void;
};

export const useSsoIntegration = (): SsoIntegrationHook => {
    const dispatch = useDispatch();
    const { loading, execute } = useLoadingCall(apiClient.deleteSsoIntegration);

    const deleteSsoIntegration = useCallback(() => {
        execute()
            .then(() => {
                Analytics.event(Events.disconnect_sso);
                dispatch({ type: SessionActions.Delete });
                window.location.href = `${window.location.origin}${AppRoutes.loginPath}`;
            })
            .catch((e: AxiosError) => {
                handleResponse(
                    e,
                    "Problem deleting sso integration. Contact iVerify support."
                );
            });
    }, []);

    return { deleteSsoIntegration, loading };
};

export type AddAdminHook = {
    loading: boolean;
    addAdmin: (
        name: string,
        email: string,
        onAddSuccess: (name: string, email: string) => void
    ) => void;
};

export const useAddAdmin = (): AddAdminHook => {
    const { loading, execute } = useLoadingCall(apiClient.addAdmin);
    const addAdmin = useCallback(
        (
            name: string,
            email: string,
            onAddSuccess: (name: string, email: string) => void
        ) => {
            execute(name, email)
                .then(() => {
                    onAddSuccess(name, email);
                    displaySuccessBanner(
                        `${name} has been invited as an administrator.`
                    );
                })
                .catch((e: AxiosError) => {
                    handleResponse(e, "Failed to add admin.");
                });
        },
        []
    );

    return { loading, addAdmin };
};

export type ResendAdminInviteHook = {
    loading: boolean;
    resendInvite: (
        name: string,
        email: string,
        onResendSuccess: (name: string, email: string) => void
    ) => void;
};

export const useResendInvite = (): ResendAdminInviteHook => {
    const { loading, execute } = useLoadingCall(apiClient.resendAdminInvite);
    const resendInvite = useCallback(
        (
            name: string,
            email: string,
            onResendSuccess: (name: string, email: string) => void
        ) => {
            execute(name, email)
                .then(() => {
                    onResendSuccess(name, email);
                    displaySuccessBanner(`Email has been sent to ${email}`);
                })
                .catch((e: AxiosError) => {
                    handleResponse(e, "Failed to send email");
                });
        },
        []
    );

    return { loading, resendInvite };
};

export type ApiSettingsHook = {
    loading: boolean;
    apiConfig: ApiConfiguration;
    getApiConfig: () => void;
    generateApiConfig: (tokenExpiration: ApiTokenExpiration) => void;
    revokeApiToken: () => void;
};

export type WebhookSettingsHook = {
    loading: boolean;
    webhookConfig: WebhookConfig;
    webhookError: string;
    getWebhookConfig: () => void;
    getWebhookSecret: () => void;
    saveWebhookConfig: (config: WebhookConfig) => void;
    deactivateWebhookConfig: () => void;
};

export const useApiConfig = (): ApiSettingsHook => {
    const [apiConfig, setApiConfig] = useState<ApiConfiguration>({});

    const { loading: fetchLoading, execute: fetch } = useLoadingCall(
        apiClient.getApiToken
    );

    const getApiConfig = useCallback(() => {
        fetch()
            .then((config: ApiConfiguration) => {
                setApiConfig(config);
            })
            .catch((e) => {
                handleResponse(e, "Failed to get API key.");
            });
    }, [fetch]);

    const { loading: generateLoading, execute: post } = useLoadingCall(
        apiClient.generateAPiToken
    );

    const generateApiConfig = useCallback(
        (tokenExpiration: ApiTokenExpiration) => {
            post(tokenExpiration)
                .then((config: ApiConfiguration) => {
                    setApiConfig(config);
                    displaySuccessBanner("Successfully generated API key.");
                })
                .catch((e) => {
                    handleResponse(e, "Failed to generate a new API key.");
                });
        },
        [post]
    );

    const { loading: revokeLoading, execute: revoke } = useLoadingCall(
        apiClient.revokeApiToken
    );

    const revokeApiToken = useCallback(() => {
        revoke()
            .then(() => {
                setApiConfig({});
                displaySuccessBanner("Successfully revoked API access.");
            })
            .catch((e) => {
                handleResponse(e, "Failed to revoke API access.");
            });
    }, [revoke]);

    return {
        loading: fetchLoading || generateLoading || revokeLoading,
        apiConfig,
        getApiConfig,
        generateApiConfig,
        revokeApiToken,
    };
};

export const useWebhookConfig = (): WebhookSettingsHook => {
    const [webhookConfig, setWebhookConfig] = useState<WebhookConfig>({
        url: null,
        secret: null,
    });
    const [webhookError, setWebhookError] = useState<string>("");

    const { loading: fetchLoading, execute: fetch } = useLoadingCall(
        apiClient.getWebhookConfig
    );

    const getWebhookConfig = useCallback(() => {
        fetch()
            .then((config: WebhookConfig) => {
                setWebhookConfig(config);
            })
            .catch((e) => {
                handleResponse(e, "Failed to get webhook settings.");
            });
    }, [fetch]);

    const { loading: secretLoading, execute: getSecret } = useLoadingCall(
        apiClient.getWebhookSecret
    );

    const getWebhookSecret = useCallback(() => {
        getSecret()
            .then((secret: string) => {
                setWebhookConfig({
                    ...webhookConfig,
                    secret,
                });
            })
            .catch((e) => {
                handleResponse(e, "Failed to get webhook secret.");
            });
    }, [getSecret]);

    const { loading: saveWebhookLoading, execute: post } = useLoadingCall(
        apiClient.saveWebhookConfig
    );

    const saveWebhookConfig = useCallback(
        (config: WebhookConfig) => {
            post(config)
                .then(() => {
                    displaySuccessBanner(
                        "Successfully saved webhook settings."
                    );
                    setWebhookError("");
                })
                .catch((e) => {
                    setWebhookError(e.response.data);
                    if (e.response.status === 500) {
                        displayErrorBanner("Failed to save webhook settings.");
                    }
                });
        },
        [post]
    );

    const { loading: deleteWebhookLoading, execute: deleteWebhook } =
        useLoadingCall(apiClient.deleteWebhookConfig);

    const deactivateWebhookConfig = useCallback(() => {
        deleteWebhook()
            .then(() => {
                displaySuccessBanner("Successfully deactivated webhooks.");
                setWebhookConfig({ url: null, secret: null });
            })
            .catch((e) => {
                handleResponse(e, "Failed to deactivate webhooks.");
            });
    }, [deleteWebhook]);

    return {
        loading:
            fetchLoading ||
            secretLoading ||
            saveWebhookLoading ||
            deleteWebhookLoading,
        webhookConfig,
        webhookError,
        getWebhookConfig,
        getWebhookSecret,
        saveWebhookConfig,
        deactivateWebhookConfig,
    };
};

export type NotificationSettingsHook = {
    loading: boolean;
    emailPreferences: EmailPreferences;
    activeEmailTemplate: EmailTemplate;
    notificationsPreferences: NotificationPreference;
    getEmailPreferences: () => void;
    updateEmailPreferences: (emailPreferences: EmailPreferences) => void;
    getEmailTemplate: (type: EmailTemplateType) => void;
    updateEmailTemplate: (template: EmailTemplate) => void;
    getNotificationsPreferences: (topic: InternalNotificationTopic) => void;
    updateNotificationsPreferences: (
        preferences: NotificationPreference
    ) => void;
};

export const useNotificationsHook = (): NotificationSettingsHook => {
    const [emailPreferences, setEmailPreferences] = useState<EmailPreferences>({
        orgId: null,
        weeklySummary: null,
        threatAlerts: null,
        enrollmentEmailsFrequency: null,
        outdatedOsEmailsFrequency: null,
    });

    const [activeEmailTemplate, setActiveEmailTemplate] =
        useState<EmailTemplate>({
            type: undefined,
            subject: "",
            body: "",
        });

    const [notificationsPreferences, setNotificationsPreferences] =
        useState<NotificationPreference>({
            notificationType: null,
            topic: null,
            deviceIntegrityPeriod: null,
            notificationFrequency: null,
        });

    const { loading: fetchEmailPrefsLoading, execute: fetchEmailPrefs } =
        useLoadingCall(apiClient.getEmailPreferences);

    const getEmailPreferences = useCallback(() => {
        fetchEmailPrefs()
            .then((preferences: EmailPreferences) => {
                setEmailPreferences(preferences);
            })
            .catch((e) => {
                handleResponse(e, "Failed to get email preferences.");
            });
    }, [fetchEmailPrefs]);

    const { loading: updateEmailPrefsLoading, execute: postEmailPrefs } =
        useLoadingCall(apiClient.updateEmailPreferences);

    const updateEmailPreferences = useCallback(
        (preferences: EmailPreferences) => {
            postEmailPrefs(preferences)
                .then(() => {
                    setEmailPreferences(preferences);
                    displaySuccessBanner(
                        "Successfully updated email notifications preferences."
                    );
                })
                .catch((e) => {
                    handleResponse(
                        e,
                        "Failed to update email notifications preferences."
                    );
                });
        },
        [postEmailPrefs]
    );

    const { loading: fetchEmailTemplateLoading, execute: fetchEmailTemplate } =
        useLoadingCall(apiClient.getEmailTemplate);

    const getEmailTemplate = useCallback(
        (type: EmailTemplateType) => {
            fetchEmailTemplate(type)
                .then((template: EmailTemplate) => {
                    setActiveEmailTemplate(template);
                })
                .catch((e) => {
                    handleResponse(e, "Failed to fetch saved email template.");
                });
        },
        [fetchEmailTemplate]
    );

    const { loading: updateEmailTemplateLoading, execute: postEmailTemplate } =
        useLoadingCall(apiClient.updateEmailTemplate);

    const updateEmailTemplate = useCallback(
        (template: EmailTemplate) => {
            postEmailTemplate(template)
                .then(() => {
                    setActiveEmailTemplate(template);
                    displaySuccessBanner(
                        "Successfully updated saved email template."
                    );
                })
                .catch((e) => {
                    handleResponse(e, "Failed to update saved email template.");
                });
        },
        [postEmailTemplate]
    );

    const {
        loading: fetchNotificationsPrefsLoading,
        execute: fetchNotificationsPrefs,
    } = useLoadingCall(apiClient.getNotificationsPreferences);

    const getNotificationsPreferences = useCallback(
        (topic: InternalNotificationTopic) => {
            fetchNotificationsPrefs(topic)
                .then((preferences: NotificationPreference) => {
                    setNotificationsPreferences(preferences);
                })
                .catch((e) => {
                    handleResponse(
                        e,
                        "Failed to get notification preferences."
                    );
                });
        },
        [fetchNotificationsPrefs]
    );

    const {
        loading: postNotificationsPrefsLoading,
        execute: postNotificationsPrefs,
    } = useLoadingCall(apiClient.updateNotificationsPreferences);

    const updateNotificationsPreferences = useCallback(
        (preferences: NotificationPreference) => {
            postNotificationsPrefs(preferences)
                .then(() => {
                    setNotificationsPreferences(preferences);
                    displaySuccessBanner(
                        "Successfully updated notifications preferences."
                    );
                })
                .catch((e) => {
                    handleResponse(
                        e,
                        "Failed to update notifications preferences."
                    );
                });
        },
        [postNotificationsPrefs]
    );

    return {
        loading:
            fetchEmailPrefsLoading ||
            updateEmailPrefsLoading ||
            fetchEmailTemplateLoading ||
            updateEmailTemplateLoading ||
            fetchNotificationsPrefsLoading ||
            postNotificationsPrefsLoading,
        emailPreferences,
        activeEmailTemplate,
        notificationsPreferences,
        getEmailPreferences,
        getEmailTemplate,
        getNotificationsPreferences,
        updateEmailPreferences,
        updateEmailTemplate,
        updateNotificationsPreferences,
    };
};

export default useCustomerBillingDetails;
