import { AxiosError } from "axios";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import initialState from "main/app/state";
import { SessionActions } from "main/login/sessionReducer";
import Analytics, { Events } from "main/utils/Analytics";
import { apiClient } from "main/utils/ApiClient";
import { AppRoutes } from "main/app/App";
import { SessionState } from "main/app/types";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import { displayErrorBanner } from "main/utils/displayBanner";

export type LoginHook = {
    loading: boolean;
    login: (email: string, password: string) => void;
};

export const useLogin = (): LoginHook => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading, execute: loginCall } = useLoadingCall(apiClient.login);

    const login = useCallback(
        (email: string, password: string) => {
            loginCall(email, password)
                .then((session: SessionState) => {
                    dispatch({
                        type: SessionActions.FetchSessionData,
                        session,
                    });
                    navigate(AppRoutes.dashboardPath);
                })
                .catch((e: AxiosError) => {
                    if (e?.response?.status === 403) {
                        displayErrorBanner(
                            "Access limited due to login policy. Contact your administrator for details."
                        );
                    } else {
                        handleResponse(e, "Failed to login user.");
                    }
                });
        },
        [loginCall]
    );

    return { loading, login };
};

export const useLogout = (): (() => void) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return useCallback(() => {
        Analytics.event(Events.logout);

        apiClient.logout().finally(() => {
            dispatch({
                type: SessionActions.FetchSessionData,
                session: initialState.session,
            });
            navigate(AppRoutes.lookupPath);
        });
    }, []);
};
