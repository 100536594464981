import { applyMiddleware, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";

import rootReducer from "main/app/rootReducer";
import initialState from "main/app/state";
import { apiClient } from "main/utils/ApiClient";
import { SessionState } from "main/app/types";

const composeEnhancers =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

const store: Promise<Store> = apiClient
    .fetchSession()
    .then((session: SessionState) =>
        createStore(rootReducer, { ...initialState, session }, enhancer)
    )
    .catch((_) => createStore(rootReducer, initialState, enhancer));

export default store;
