import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { Integration } from "@sentry/types";

const loadSentry = function fn(environment: string, release: string): void {
    Sentry.init({
        dsn: "https://5899f27c600144828eaf2e2b8df8a2b6@o1386464.ingest.sentry.io/6706813",
        integrations: [new BrowserTracing() as Integration],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        release,
        environment,
    });
};

export default loadSentry;
