import { AppRoutes } from "main/app/App";
import { Events } from "main/utils/Analytics";
import TwitterIcon from "icons/ts/TwitterIcon";
import { Link } from "components/Link";
import { FC } from "react";

const Footer: FC = () => (
    <div className="footer py-4">
        <div className="footer-social">
            <Link
                to={{ pathname: AppRoutes.twitterPath }}
                className="footer-social"
                event={Events.twitter}
                parameters={{ from: "footer" }}
            >
                <TwitterIcon />
                iVerify on Twitter
            </Link>
        </div>
        <div className="footer-legal">
            <Link
                to={{ pathname: AppRoutes.termsPath }}
                className="footer-terms"
                event={Events.terms}
                parameters={{ from: "footer" }}
            >
                Terms and Conditions
            </Link>
            <Link
                to={{ pathname: AppRoutes.privacyPath }}
                className="footer-terms"
                event={Events.privacy}
                parameters={{ from: "footer" }}
            >
                Privacy Policy
            </Link>
            <Link
                to={{ pathname: AppRoutes.supportPath }}
                className="footer-terms"
                event={Events.supportFAQ}
                parameters={{ from: "footer" }}
            >
                FAQ
            </Link>
            <Link
                to={{ pathname: AppRoutes.supportEmailPath }}
                className="footer-terms"
                event={Events.supportEmail}
                parameters={{ from: "footer" }}
            >
                support@iverify.io
            </Link>
        </div>
    </div>
);

export default Footer;
