import { useRef, useEffect, DependencyList, EffectCallback } from "react";

/**
 * Identical to useEffect, except that it never runs on mount. This is
 * the equivalent of the componentDidUpdate lifecycle function.
 *
 * @param {function:function} effect - A useEffect effect.
 * @param {array} [dependencies] - useEffect dependency list.
 */
const useDidMountEffect = (
    effect: EffectCallback,
    dependencies?: DependencyList
): void => {
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            effect();
        }
    }, dependencies);
};

export default useDidMountEffect;
