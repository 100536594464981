import SortingArrowIcon from "icons/ts/SortingArrowIcon";

const sortingArrow = (
    isSorted: boolean,
    isSortedDesc: boolean | undefined
): JSX.Element | null => {
    const visibility = isSorted ? "visible" : "hidden";
    return (
        <div style={{ visibility }}>
            <SortingArrowIcon rotate={!isSortedDesc} />
        </div>
    );
};

export default sortingArrow;
