import { FC } from "react";

const LoadingBar: FC = () => (
    <div role="progressbar" className="progress-bar">
        <div className="progress-bar-spinner-1" />
        <div className="progress-bar-spinner-2" />
    </div>
);

export default LoadingBar;
