export type Event = string;

export enum Events {
    upload_members_file_select = "upload_members_file_select",
    upload_members_file_drop = "upload_members_file_drop",
    upload_csv_button = "upload_csv_button",
    lookup_company = "lookup_company",
    send_invitations = "send_invitations",
    resend_invitation = "resend_invitations",
    back_to_sso_groups = "back_to_sso_groups",
    back_to_member_addition = "back_to_member_addition",
    sign_in = "sign_in",
    show_dropdown = "show_dropdown",
    header_logo = "header_logo",
    billing = "billing",
    download_csv_template = "download_csv_template",
    create_account = "create_account",
    logout = "logout",
    add_members = "add_members",
    sort_dashboard_column = "sort_dashboard_column",
    submit_groups = "submit_groups",
    upload_members_sso = "upload_members_sso",
    upload_members_non_sso = "upload_members_non_sso",
    upload_members_manual = "upload_members_manual",
    terms = "terms",
    privacy = "privacy",
    supportFAQ = "support_faq",
    supportEmail = "support_email",
    twitter = "twitter",
    sign_up_link = "sign_up_link",
    create_account_error = "create_account_error",
    submit_payment_information = "submit_payment_information",
    add_additional_admin = "add_additional_admin",
    enable_scim = "enable_scim",
    disable_scim = "disable_scim",
    disconnect_sso = "disconnect_sso",
    enable_mdm = "enable_mdm",
    disable_mdm = "disable_mdm",
    generate_api_token = "generate_api_token",
    revoke_api_token = "revoke_api_token",
}

export interface Parameters {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface AnalyticsEngine {
    load(): void;

    event(event: Event, parameters?: Parameters): void;

    pageview(title: string, path: string): void;
}
