import { FC, FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { usePasswordUpdate } from "main/password-reset/hooks";
import {
    isPasswordValid,
    passwordHint,
    showErrorSuccessIcon,
} from "main/utils/validate";
import { Button } from "components/button/Button";
import { LabeledInput } from "components/input/Input";
import { useForm } from "components/form/useForm";
import PasswordPage from "main/password-reset/PasswordPage";

interface RouterProps {
    token?: string | undefined;
}

export const PasswordUpdateForm: FC<RouterProps> = () => {
    const { token } = useParams();

    const { inputs, onChange, filled, valid, setValid } = useForm({
        password: "",
        passwordMatch: "",
        requestToken: token ?? "",
    });

    const [isPasswordActive, setIsPasswordActive] = useState<boolean>(false);
    const [isPasswordMatchActive, setIsPasswordMatchActive] =
        useState<boolean>(false);
    const [passwordErrors, setPasswordErrors] = useState<React.ReactNode>(null);
    const [hasPasswordErrors, setHasPasswordErrors] = useState<boolean>(true);
    const [hasPasswordMatchError, setHasPasswordMatchError] =
        useState<boolean>(false);
    const { loading, reset, updatePassword } = usePasswordUpdate();

    useEffect(() => {
        const noErrors = !hasPasswordErrors && !hasPasswordMatchError;
        setValid(filled && noErrors);
    }, [filled, passwordErrors, hasPasswordMatchError]);

    const showPasswordHint = () => {
        setIsPasswordActive(true);
        if (!hasPasswordErrors && !inputs.password) {
            setPasswordErrors(passwordHint);
        }
    };

    const validatePassword = (e: FormEvent) => {
        const password = (e.target as HTMLInputElement).value;
        const { hasError, errors } = isPasswordValid(password, false);
        setPasswordErrors(errors);
        setHasPasswordErrors(hasError);
    };

    const checkPasswordErrors = (e: FormEvent) => {
        if (inputs.password) {
            setIsPasswordActive(false);
            const password = (e.target as HTMLInputElement).value;
            const { hasError, errors } = isPasswordValid(password);

            if (password === "" || !hasPasswordErrors) {
                setPasswordErrors(null);
            }
            setHasPasswordErrors(hasError);
            setPasswordErrors(errors);
        }
    };

    const validatePasswordMatch = (e: FormEvent) => {
        setIsPasswordMatchActive(false);
        const passwordMatch = (e.target as HTMLInputElement).value;
        if (passwordMatch === "" || passwordMatch === inputs.password) {
            setHasPasswordMatchError(false);
        } else {
            setHasPasswordMatchError(true);
        }
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        updatePassword(
            inputs.requestToken as string,
            inputs.password as string
        );
    };

    const updateForm = () => (
        <>
            <div className="h5 mbl mtxs">Enter your new password</div>
            <form
                onSubmit={handleSubmit}
                onChange={onChange}
                className="color-bg-lighter-grey"
            >
                <LabeledInput
                    name="password"
                    label="Password"
                    placeholder="password 8+ characters"
                    type="password"
                    autoComplete="password"
                    onFocus={showPasswordHint}
                    onInput={validatePassword}
                    onBlur={checkPasswordErrors}
                    error={passwordErrors}
                    icon={showErrorSuccessIcon(
                        inputs.password as string,
                        isPasswordActive,
                        hasPasswordErrors
                    )}
                    maxLength={256}
                />
                <LabeledInput
                    name="passwordMatch"
                    label="Confirm Password"
                    placeholder="password 8+ characters"
                    type="password"
                    autoComplete="password"
                    onFocus={() => setIsPasswordMatchActive(true)}
                    onBlur={validatePasswordMatch}
                    error={
                        hasPasswordMatchError ? "Passwords must match" : null
                    }
                    icon={showErrorSuccessIcon(
                        inputs.passwordMatch as string,
                        isPasswordMatchActive,
                        inputs.passwordMatch !== inputs.password
                    )}
                    maxLength={256}
                />
                <Button
                    disabled={loading || !valid}
                    data-testid="update-password-submit"
                    label={loading ? "Updating..." : "Update Password"}
                />
            </form>
        </>
    );

    const updateConfirmation = () => (
        <>
            <div className="h5 mbl mtxs">Your password has been updated.</div>
            <div className="h6 color-mid italic">
                Click the link below and sign in with your new password.
            </div>
        </>
    );

    const pageBody = reset ? updateConfirmation() : updateForm();
    return (
        <>
            <div className="width-small">
                <div className="form-title">Password Update</div>
                {pageBody}
            </div>
        </>
    );
};

const PasswordUpdatePage: FC = () => (
    <PasswordPage>
        <PasswordUpdateForm />
    </PasswordPage>
);
export default PasswordUpdatePage;
