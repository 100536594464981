import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { AxiosError } from "axios";
import { AppRoutes } from "main/app/App";
import { displayErrorBanner } from "main/utils/displayBanner";
import { useDispatch } from "react-redux";
import { SessionActions } from "main/login/sessionReducer";

export type UseLoadingCallHook<T> = {
    loading: boolean;
    execute: (...args: any[]) => Promise<T>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useLoadingCall = <T,>(
    call: (...args: any[]) => Promise<T>
): UseLoadingCallHook<T> => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [requestInFlight, setRequestInFlight] = useState<Promise<T> | null>(
        null
    );

    const execute = useCallback(async (...args: any[]) => {
        if (loading) {
            return requestInFlight as Promise<T>;
        }

        setLoading(true);
        const request = call(...args)
            .catch((e: AxiosError) => {
                if (e?.response?.status === 401) {
                    dispatch({ type: SessionActions.Delete });
                    navigate(AppRoutes.lookupPath);
                    displayErrorBanner("You are no longer logged in");
                }
                return Promise.reject(e);
            })
            .finally(() => {
                setLoading(false);
            });

        setRequestInFlight(request);
        return request;
    }, []);

    return { loading, execute };
};
