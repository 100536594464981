import { AnyAction } from "redux";
import initialState from "main/app/state";

import { DashboardState, PaginatedDevices } from "main/app/types";

export enum DashboardActions {
    FetchDevices = "FetchDevices",
    ClearPages = "ClearPages",
    UnenrollDevices = "UnenrollDevices",
}

export default function DashboardReducer(
    state = initialState.dashboard,
    action: AnyAction
): DashboardState {
    switch (action.type) {
        case DashboardActions.FetchDevices: {
            return {
                ...state,
                paginatedDevices: {
                    ...state.paginatedDevices,
                    [action.page]: action.devices,
                },
                totalPages: action.totalPages,
            };
        }
        case DashboardActions.ClearPages: {
            return {
                ...state,
                paginatedDevices: {},
                totalPages: 0,
            };
        }
        case DashboardActions.UnenrollDevices: {
            const { deviceCodes } = action;
            const paginatedDevices = { ...state.paginatedDevices };
            Object.keys(paginatedDevices).forEach((pageNum) => {
                const page = pageNum as unknown as keyof PaginatedDevices;
                paginatedDevices[page] = paginatedDevices[page].filter(
                    (device) => !deviceCodes.includes(device.code)
                );
            });
            return {
                ...state,
                paginatedDevices,
            };
        }
        default:
            return state;
    }
}
