import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";

const useHistoryState = <T,>(
    key: string,
    initialValue: T
): [T, (t: T) => void] => {
    const navigate = useNavigate();
    const location = useLocation();

    const [rawState, rawSetState] = useState<T>(() => {
        const value = location.state?.[key];
        return value ?? initialValue;
    });

    const setState = (value: T) => {
        navigate(location.pathname, {
            state: {
                ...location.state,
                [key]: value,
            },
        });
        rawSetState(value);
    };

    return [rawState, setState];
};

export default useHistoryState;
