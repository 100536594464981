import TitleLayout from "main/layouts/TitleLayout";
import LoadedCheckoutForm from "components/CheckoutForm/CheckoutForm";
import { useDispatch } from "react-redux";
import { Stripe } from "@stripe/stripe-js";
import { loadedStripePromise, AppRoutes } from "main/app/App";
import IVerifyOrgs from "icons/ts/iVerifyOrgs";
import { apiClient } from "main/utils/ApiClient";
import { AxiosError } from "axios";
import handleResponse from "main/utils/HandleResponse";
import { Link } from "components/Link";
import { FC, useEffect } from "react";
import usePageTitle from "main/utils/usePageTitle";
import { useLocation } from "react-router-dom";
import { SessionActions } from "main/login/sessionReducer";

export interface WelcomePageProps {
    name: string;
    stripePromise: Promise<Stripe | null>;
}

export const DisconnectedWelcomePage: FC<WelcomePageProps> = (
    props: WelcomePageProps
) => {
    const { name, stripePromise } = props;
    const onSaveBilling = () => {
        apiClient.emailAllMembers().catch((e: AxiosError) => {
            handleResponse(e, "Failed to email members please try again");
        });
    };

    const trialEndDate = new Date();
    trialEndDate.setDate(trialEndDate.getDate() + 7);

    const month = trialEndDate.toLocaleString("default", { month: "long" });
    const day = trialEndDate.getDate();
    const year = trialEndDate.getFullYear();
    const suffix = (d: number) => {
        if (d > 3 && d < 21) {
            return "th";
        }
        switch (d % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    };
    const nth = suffix(day);
    const trialEndDateFormatted = `${month} ${day}${nth}, ${year}`;

    return (
        <TitleLayout>
            <div className="flex-row flex-hc width-xlarge text-left">
                <div className="mrxl">
                    <div className="special-case-title color-action-lt mbm">
                        Welcome, {name}!
                    </div>
                    <IVerifyOrgs />
                    <div className="title-three mvs">
                        Start Your No Obligation, 7 day Trial
                    </div>
                    <div className="paragraph-one mvs">
                        No account charge if cancelled prior to the trial end
                        date.
                    </div>
                    <div className="paragraph-three mvs">
                        The admin account will be added as the first
                        organization member, additions or removals can be made
                        from the dashboard.
                        <p>
                            The trial will end on {trialEndDateFormatted}, and a
                            paid subscription will begin unless canceled prior.
                        </p>
                    </div>
                </div>
                <div className="welcome-left-border" />
                <div className="mlxl flex-ac">
                    <div className="heading-one color-mid-m-1 mbm">
                        Card on Account
                    </div>
                    <LoadedCheckoutForm
                        stripePromise={stripePromise}
                        name={name}
                        onSaveBilling={onSaveBilling}
                        buttonLabel="Begin Trial"
                        noPaymentMethod
                    />
                    <div className="mtxl">
                        For enterprise purchasing support, including paying via
                        purchase order, please contact&nbsp;
                        <Link to={{ pathname: AppRoutes.salesEmailPath }}>
                            sales@iverify.io
                        </Link>
                        .
                    </div>
                </div>
            </div>
        </TitleLayout>
    );
};

const WelcomePage: FC = () => {
    usePageTitle("Welcome! Start your 7-day trial");

    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (location.state) {
            const { session } = location.state;
            if (session) {
                dispatch({
                    type: SessionActions.FetchSessionData,
                    session,
                });
            }
        }
    }, [location.state]);

    const name = location.state?.session?.user?.name;

    return (
        <DisconnectedWelcomePage
            name={name}
            stripePromise={loadedStripePromise}
        />
    );
};

export default WelcomePage;
