import { LabeledInput } from "components/input/Input";
import { ChangeEvent, useState } from "react";
import { isAscii, isEmailValid } from "main/utils/validate";

export type EmailInputHook = {
    emailInput: JSX.Element;
    error: string | null;
};

const useEmailInput = (): EmailInputHook => {
    const [error, setError] = useState<string | null>(null);

    const clearError = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        const email = e.currentTarget.value;
        if (isEmailValid(email)) {
            setError("");
        } else {
            setError(null);
        }
    };

    const validateEmail = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const email = e.currentTarget.value;
        if (email === "") {
            setError(null);
        } else if (!isAscii(email)) {
            setError("Unicode characters not supported");
        } else if (!isEmailValid(email)) {
            setError("Enter a valid Email");
        }
    };

    const emailInput = (
        <LabeledInput
            autoComplete="email"
            type="text"
            name="email"
            label="Email"
            placeholder="name@example.com"
            onBlur={validateEmail}
            onChange={clearError}
            error={error || undefined}
        />
    );

    return { emailInput, error };
};

export default useEmailInput;
