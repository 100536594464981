const ConfigurationLayout = ({
    children,
}: React.PropsWithChildren<unknown>): JSX.Element => (
    <div className="flex-col">
        <div className="mts mhm mhs-sm">
            <div className="flex-col flex-vc">
                <div className="text-center mtm">{children}</div>
            </div>
        </div>
    </div>
);

export default ConfigurationLayout;
