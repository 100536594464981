import ChevronIcon from "icons/ts/Chevron";
import useDidMountEffect from "main/utils/useDidMountEffect";
import React, { useEffect, useRef, useState } from "react";

type Option = {
    label: string;
    value: string;
    selected?: boolean;
};

interface MultiSelectInputProps {
    title: string;
    options: Option[];
    onSelect: (selected: Record<string, boolean>) => void;
    showSelectCount?: boolean;
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
    title,
    options,
    onSelect,
    showSelectCount = false,
}) => {
    const initialSelected = Object.fromEntries(
        options.map(({ value, selected }) => [value, selected ?? false])
    );
    const [selectedOptions, setSelectedOptions] =
        useState<Record<string, boolean>>(initialSelected);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useDidMountEffect(() => {
        onSelect(selectedOptions);
    }, [selectedOptions]);

    const toggleOption = (value: string) => {
        setSelectedOptions({
            ...selectedOptions,
            [value]: !selectedOptions[value],
        });
    };

    useEffect(() => {
        const handleMouseDown = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                // @ts-ignore
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);

    return (
        <div className="relative inline-block" ref={dropdownRef}>
            <button
                data-testid="multi-select-button"
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center dashboard-dropdown ivy-button--icon w-36"
            >
                <span className="px-2">
                    {title}{" "}
                    {showSelectCount &&
                        `(${
                            Object.values(selectedOptions).filter(Boolean)
                                .length
                        })`}
                </span>
                <span className="flex items-center ml-auto">
                    <ChevronIcon className="m-0" color="#020608" />
                </span>
            </button>
            {isOpen && (
                <div className="absolute z-20 mt-1 ml-1 px-2 py-1 w-32 bg-white border border-gray-200 rounded-sm">
                    {options.map((option) => (
                        <label className="form-row color-black block">
                            <input
                                type="checkbox"
                                name={option.value}
                                checked={selectedOptions[option.value]}
                                onChange={() => toggleOption(option.value)}
                            />
                            {option.label}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelectInput;
