import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Group,
    GroupSettings,
    GroupMembers,
    Member,
} from "main/add-members/types";
import { apiClient, NewMembersResponse } from "main/utils/ApiClient";
import { BannerInformation, BannerType } from "main/utils/displayBanner";
import { AppRoutes } from "main/app/App";
import { useAddMembersHint } from "main/app/AppCookies";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import useDashboard from "main/dashboard/hooks";
import { useFilter } from "main/dashboard/useFilter";
import { withEngSuffix } from "main/utils/utils";

export type ParseCsvFileHook = {
    loading: boolean;
    setCsvFile: (csvFile: File) => void;
};

export const useParseCsvFile = (): ParseCsvFileHook => {
    const { loading, execute: sendMembersCsv } = useLoadingCall(
        apiClient.sendMembersCsv
    );

    const navigate = useNavigate();
    const setCsvFile = useCallback((csvFile: File) => {
        sendMembersCsv(csvFile)
            .then((members) => {
                navigate(AppRoutes.memberConfirmPath, {
                    state: { ...members, isUsingSso: false },
                });
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Failed to read members from file");
            });
    }, []);

    return { loading, setCsvFile };
};

export type ConfirmMembersHook = {
    loading: boolean;
    createMembers: (members: Member[], allowedDupes: Member[]) => void;
    createSsoMembers: (ssoGroups: Group[], allowedDupes: Member[]) => void;
};

export type UseOrgMembersHook = {
    orgMembers: Member[];
    fetchOrgMembers: () => void;
    orgMembersLoading: boolean;
};

export const useOrgMembers = (): UseOrgMembersHook => {
    const [orgMembers, setOrgMembers] = useState<Member[]>([]);

    const { loading: orgMembersLoading, execute: getOrgMembers } =
        useLoadingCall(apiClient.getOrgMembers);

    const fetchOrgMembers = () =>
        getOrgMembers()
            .then((data) => {
                setOrgMembers(data.members);
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Failed to fetch members");
            });

    return { orgMembers, fetchOrgMembers, orgMembersLoading };
};

export const useConfirmMembers = (): ConfirmMembersHook => {
    const { loading: createMembersLoading, execute: createMembersCall } =
        useLoadingCall(apiClient.createMembers);
    const { loading: createSsoMembersLoading, execute: createSsoMembersCall } =
        useLoadingCall(apiClient.createSsoMembers);
    const loading = createMembersLoading || createSsoMembersLoading;

    const navigate = useNavigate();
    const { setHideAddMembersHint } = useAddMembersHint();
    const { currentPage, pageSize, fetchDashboardRows } = useDashboard();
    const { filters } = useFilter();

    const createMembers = useCallback(
        (newMembers: Member[], allowedDupes: Member[]) => {
            let allMembers = newMembers;
            if (allowedDupes.length > 0) {
                allMembers = [...newMembers, ...allowedDupes];
            }

            createMembersCall(allMembers, true)
                .then((newMembersResponse: NewMembersResponse) => {
                    setHideAddMembersHint();

                    const msg: BannerInformation = {
                        level: BannerType.Success,
                        msg: `Invitations are being sent to ${newMembersResponse.numMembersCreated.toLocaleString()} organization ${withEngSuffix(
                            "member",
                            newMembersResponse.numMembersCreated
                        )}`,
                    };

                    fetchDashboardRows(currentPage, pageSize, filters);
                    navigate(AppRoutes.dashboardPath, { state: msg });
                })
                .catch((e: AxiosError) => {
                    handleResponse(e, "Failed to create members");
                });
        },
        []
    );

    const createSsoMembers = useCallback(
        (ssoGroups: Group[], allowedDupes: Member[]) => {
            createSsoMembersCall(ssoGroups, allowedDupes, true)
                .then((newMembersResponse: NewMembersResponse) => {
                    setHideAddMembersHint();

                    const msg: BannerInformation = {
                        level: BannerType.Success,
                        msg: `Invitations are being sent to ${newMembersResponse.numMembersCreated.toLocaleString()} organization ${withEngSuffix(
                            "member",
                            newMembersResponse.numMembersCreated
                        )}`,
                    };

                    fetchDashboardRows(currentPage, pageSize, filters);
                    navigate(AppRoutes.dashboardPath, { state: msg });
                })
                .catch((e: AxiosError) => {
                    handleResponse(e, "Failed to create members");
                });
        },
        []
    );
    return { loading, createMembers, createSsoMembers };
};

export type GroupsHook = {
    groups: Group[];
    fetchSsoGroups: (searchQuery?: string) => void;
    hasNextPage: boolean;
    fetchMembersInGroup: (groups: GroupSettings) => void;
    loading: boolean;
};

export type SSOProviderHook = {
    fetchSsoProvider: () => Promise<string>;
    loading: boolean;
};

export const useGroups = (): GroupsHook => {
    const [fetchedGroups, setFetchedGroups] = useState<Group[]>([]);
    const [nextPage, setNextPage] = useState<string | null>(null);

    const { loading: ssoGroupsLoading, execute: getSSOGroups } = useLoadingCall(
        apiClient.getSSOGroups
    );

    const navigate = useNavigate();

    const fetchSsoGroups = (searchQuery = "") =>
        getSSOGroups(nextPage, searchQuery)
            .then((data) => {
                if (searchQuery) {
                    setFetchedGroups(data.groups);
                } else {
                    setFetchedGroups([...fetchedGroups, ...data.groups]);
                }
                setNextPage(data.nextPage ?? null);
            })
            .catch((e) => {
                handleResponse(e, "Failed to get sso groups");
                setFetchedGroups([] as Group[]);
                setNextPage(null);
            });

    const { loading: membersInGroupLoading, execute: fetchMembersInGroupCall } =
        useLoadingCall(apiClient.fetchMembersInGroup);

    const fetchMembersInGroup = (groups: GroupSettings) => {
        const selectedGroupsRequest: Group[] = Object.values(groups)
            .filter((group) => group.selected)
            .map(({ id, name }) => ({ id, name }));

        fetchMembersInGroupCall(selectedGroupsRequest)
            .then((groupsMembers: GroupMembers) => {
                navigate(AppRoutes.memberConfirmPath, {
                    state: { groups: selectedGroupsRequest, groupsMembers },
                });
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Failed to get sso users for groups");
            });
    };

    const loading = ssoGroupsLoading || membersInGroupLoading;

    return {
        loading,
        groups: fetchedGroups,
        fetchSsoGroups,
        hasNextPage: nextPage !== null,
        fetchMembersInGroup,
    };
};

export const useSSOProvider = (): SSOProviderHook => {
    const { loading, execute: getSSOProvider } = useLoadingCall(
        apiClient.getSSOProvider
    );

    const fetchSsoProvider = useCallback(
        () =>
            getSSOProvider().catch((e) => {
                handleResponse(e, "Failed to get sso provider");
                return "";
            }),
        []
    );

    return { loading, fetchSsoProvider };
};
