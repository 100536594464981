import React from "react";
import { useLogout } from "main/login/hooks";

const LogoutPage: React.FC = () => {
    const logout = useLogout();
    logout();

    return null;
};

export default LogoutPage;
