import { FC } from "react";

interface TableLoadingIndicatorProps {
    loading: boolean;
}

const TableLoadingIndicator: FC<TableLoadingIndicatorProps> = ({ loading }) => (
    <tr role="progressbar">
        <td
            colSpan={1000}
            className={`border-y-0 progress-bar ${
                !loading ? "color-bg-light-m-1" : ""
            }`}
        >
            {loading && (
                <>
                    <div className="progress-bar-spinner-1" />
                    <div className="progress-bar-spinner-2" />
                </>
            )}
        </td>
    </tr>
);

export default TableLoadingIndicator;
