import { Cell, Row } from "react-table";
import { AlertRow } from "./types";

const cellClass = (cell: Cell<AlertRow>): string | undefined => {
    const {
        column: { id },
    } = cell;
    return id;
};

const getTableBody = (
    prepareRow: (row: Row<AlertRow>) => void,
    page: Array<Row<AlertRow>>
): JSX.Element => (
    <tbody>
        {page.map((row) => {
            prepareRow(row);
            const { key: rowKey } = row.getRowProps();

            return (
                <tr key={rowKey}>
                    {row.cells.map((cell: Cell<AlertRow>) => {
                        const { key: cellKey } = cell.getCellProps();
                        return (
                            <td className={cellClass(cell)} key={cellKey}>
                                <>{cell.render("Cell")}</>
                            </td>
                        );
                    })}
                </tr>
            );
        })}
    </tbody>
);

export default getTableBody;
