import { FC } from "react";

const ManualAddIcon: FC = () => (
    <svg
        width="52"
        height="56"
        viewBox="0 0 52 38"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M18.9999 19C24.1546 19 28.3333 14.8213 28.3333 9.66668C28.3333 4.51202 24.1546 0.333344 18.9999 0.333344C13.8453 0.333344 9.66661 4.51202 9.66661 9.66668C9.66661 14.8213 13.8453 19 18.9999 19ZM37.4099 34.2833C37.7739 35.0056 37.7379 35.8647 37.3148 36.5539C36.8918 37.2432 36.142 37.6642 35.3333 37.6667H2.66661C1.85789 37.6642 1.10811 37.2432 0.685053 36.5539C0.262001 35.8647 0.226011 35.0056 0.58994 34.2833L3.94994 27.54C5.13713 25.162 7.5687 23.6615 10.2266 23.6667H27.7733C30.4312 23.6615 32.8627 25.162 34.0499 27.54L37.4099 34.2833ZM44.6666 14.3333H50.4999C51.1443 14.3333 51.6666 14.8557 51.6666 15.5V17.8333C51.6666 18.4777 51.1443 19 50.4999 19H44.6666V24.8333C44.6666 25.4777 44.1443 26 43.4999 26H41.1666C40.5223 26 39.9999 25.4777 39.9999 24.8333V19H34.1666C33.5223 19 32.9999 18.4777 32.9999 17.8333V15.5C32.9999 14.8557 33.5223 14.3333 34.1666 14.3333H39.9999V8.50001C39.9999 7.85568 40.5223 7.33334 41.1666 7.33334H43.4999C44.1443 7.33334 44.6666 7.85568 44.6666 8.50001V14.3333Z"
        />
    </svg>
);

export default ManualAddIcon;
