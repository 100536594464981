import { IconProps } from "main/app/types";
import React from "react";
import { Tooltip } from "react-tooltip";

const InfoIcon: React.FC<IconProps> = ({
    color = "#00648F",
    body = null,
    ...props
}: IconProps) => {
    const icon = (
        <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
            <path
                fill={color}
                fillRule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Zm1.6 0a6.4 6.4 0 1 0 12.8 0A6.4 6.4 0 0 0 1.6 8Zm7.2.4a.4.4 0 0 0-.4-.4h-.8a.4.4 0 0 0-.4.4v2.4c0 .22.18.4.4.4h.8a.4.4 0 0 0 .4-.4V8.4Zm-.4-3.6c.22 0 .4.18.4.4V6a.4.4 0 0 1-.4.4h-.8a.4.4 0 0 1-.4-.4v-.8c0-.22.18-.4.4-.4h.8Z"
                clipRule="evenodd"
            />
        </svg>
    );

    return body ? (
        <div data-tooltip-id="info-tooltip">
            {icon}
            <Tooltip id="info-tooltip" delayShow={100} opacity={1}>
                {body}
            </Tooltip>
        </div>
    ) : (
        icon
    );
};

export default InfoIcon;
