import { AxiosError } from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    redirectToAddMembers,
    setRedirectToAddMembers,
} from "main/app/AppCookies";
import ErrorPage from "main/error/ErrorPage";
import { SessionActions } from "main/login/sessionReducer";
import { apiClient } from "main/utils/ApiClient";
import { AppRoutes } from "main/app/App";
import { SessionState } from "main/app/types";

const SessionHydrator: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    apiClient
        .fetchSession()
        .then((session: SessionState) => {
            dispatch({ type: SessionActions.FetchSessionData, session });
            const redirect = redirectToAddMembers();
            const path = redirect
                ? AppRoutes.memberGroupsPath
                : AppRoutes.dashboardPath;

            setRedirectToAddMembers(false); // Don't loop forever

            navigate(path);
        })
        .catch((e: AxiosError) => {
            let errorPage: JSX.Element | null = null;
            if (e?.response?.status === 401) {
                dispatch({ type: SessionActions.Delete });
                navigate(AppRoutes.lookupPath, { replace: true });
            } else {
                errorPage = (
                    <ErrorPage
                        message="Could not log in"
                        description={e.message}
                    />
                );
            }
            return errorPage;
        });

    return null;
};

export default SessionHydrator;
