import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import LoginPage from "main/login/LoginPage";
import OrgLookupPage from "main/org-lookup/OrgLookupPage";
import PasswordResetPage from "main/password-reset/PasswordResetPage";
import PasswordUpdatePage from "main/password-reset/PasswordUpdatePage";
import { AppRoutes } from "main/app/App";

const OpenRoutes: FC = () => (
    <>
        <Routes>
            <Route path={AppRoutes.lookupPath} element={<OrgLookupPage />} />
            <Route path={AppRoutes.loginPath} element={<LoginPage />} />
            <Route
                path={AppRoutes.resetPasswordPath}
                element={<PasswordResetPage />}
            />
            <Route
                path={AppRoutes.updatePasswordPath}
                element={<PasswordUpdatePage />}
            />
            <Route
                path="/"
                element={<Navigate replace to={AppRoutes.lookupPath} />}
            />
            <Route
                path="*"
                element={<Navigate replace to={AppRoutes.lookupPath} />}
            />
        </Routes>
    </>
);

export default OpenRoutes;
