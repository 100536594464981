import { FC } from "react";

export interface ToggleInputProps {
    name: string;
    checked?: boolean;
    onChange: () => void;
}

const ToggleInput: FC<ToggleInputProps> = ({
    name,
    checked = false,
    onChange,
}) => (
    <label className="relative inline-flex cursor-pointer select-none items-center">
        <input
            type="checkbox"
            className="sr-only"
            name={name}
            checked={checked}
            onChange={onChange}
        />
        <span
            className={`slider mr-3 flex h-5 w-11 items-center rounded-full p-1 duration-200 ${
                checked ? "color-bg-action-lt" : "color-bg-mid"
            }`}
        >
            <span
                className={`dot h-5 w-5 rounded-full bg-white duration-200 ${
                    checked ? " ml-6" : ""
                }`}
            />
        </span>
    </label>
);

export default ToggleInput;
