import { IconProps } from "main/app/types";
import React from "react";

const EyeClosedIcon: React.FC<IconProps> = ({
    color = "#007DB2",
    ...props
}: IconProps) => (
    <svg width="22" height="20" viewBox="0 0 22 20" {...props}>
        <path
            fill={color}
            fillRule="evenodd"
            d="M2.616.166a.525.525 0 0 0-.77 0l-.582.59a.536.536 0 0 0 0 .777l2.859 2.91a10.714 10.714 0 0 0-4.057 5.2A1.121 1.121 0 0 0 0 9.998c0 .118.022.235.066.344 1.787 4.51 6.12 7.457 10.928 7.432 1.779-.01 3.533-.424 5.134-1.21l3.243 3.266a.525.525 0 0 0 .77 0l.582-.59a.536.536 0 0 0 0-.777L2.616.166Zm8.376 15.386c-3.72-.008-7.107-2.166-8.718-5.554a8.98 8.98 0 0 1 3.441-3.955l2.375 2.4a3.359 3.359 0 0 0 .644 3.793 3.273 3.273 0 0 0 3.753.65l1.935 1.956a9.805 9.805 0 0 1-3.43.71ZM7.827 2.667c5.797-1.674 11.909 1.347 14.16 7 .017.11.017.222 0 .333a.909.909 0 0 1-.066.366 10.132 10.132 0 0 1-2.562 3.955l-1.561-1.577a8.067 8.067 0 0 0 1.913-2.744C18.1 6.61 14.713 4.452 10.993 4.444c-.438 0-.875.034-1.308.1L7.827 2.666Z"
            clipRule="evenodd"
        />
    </svg>
);

export default EyeClosedIcon;
