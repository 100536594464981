import { AppRoutes } from "main/app/App";
import ConfigurationLayout from "main/ConfigurationLayout";
import Analytics, { Events } from "main/utils/Analytics";
import usePageTitle from "main/utils/usePageTitle";
import { useNavigate } from "react-router-dom";
import AzureLogo from "icons/svgs/azure-logo.svg";
import OktaLogo from "icons/svgs/okta-logo.svg";
import { SsoButton } from "components/SsoButton/SsoButton";
import { FC, MouseEvent } from "react";

const SSOSelectionPage: FC = (): JSX.Element => {
    usePageTitle("Select your SSO Provider");

    const navigate = useNavigate();

    const handleSsoSelected = (e: MouseEvent) => {
        const provider = (e.currentTarget as HTMLButtonElement).name;
        Analytics.event(Events.upload_members_sso, { sso: provider });
        navigate(AppRoutes.ssoSetupPath, { state: provider });
    };

    return (
        <ConfigurationLayout>
            <div className="special-case-title flex-hc flex-ac pbs color-action-lt proper-case">
                Add Team Members
            </div>
            <div className="paragraph-one mts mhs mbm color-dark-p-2">
                Select your existing SSO provider to connect.
            </div>
            <div className="flex-row flex-hc flex-ac">
                <SsoButton
                    label="okta"
                    icon={<OktaLogo />}
                    onClick={handleSsoSelected}
                />
                <SsoButton
                    label="azure"
                    icon={<AzureLogo />}
                    onClick={handleSsoSelected}
                />
            </div>
        </ConfigurationLayout>
    );
};

export default SSOSelectionPage;
