import usePageTitle from "main/utils/usePageTitle";
import { useSelector } from "react-redux";
import { ApplicationState } from "main/app/types";
import { Button } from "components/button/Button";
import { useConfirmationModal } from "components/modal/Modal";
import LoadingBar from "components/LoadingBar/LoadingBar";
import { useSsoIntegration } from "main/settings/hooks";
import { AppRoutes } from "main/app/App";
import { useNavigate } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import { OrgLookupResponse, apiClient } from "main/utils/ApiClient";

interface SsoSettingsTabProps {
    sso: boolean;
    provider: string;
    loading: boolean;
    deleteSsoIntegration: () => void;
}

export const DisconnectedSsoTab = (props: SsoSettingsTabProps): JSX.Element => {
    usePageTitle("SSO Settings");

    const { sso, provider, loading, deleteSsoIntegration } = props;
    const navigate = useNavigate();

    const modalProps = {
        title: "Disconnect SSO",
        message:
            "Are you sure you want to disconnect your Single Sign On Provider?",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Disconnect",
    };

    const { showModal, modal } = useConfirmationModal({ ...modalProps });

    const hasSso = (
        <>
            {modal(deleteSsoIntegration)}
            {provider ? (
                <div className="paragraph-two pbm proper-case">
                    {provider} configured
                </div>
            ) : (
                <div className="paragraph-two pbm">
                    Please login with SSO to complete setup
                </div>
            )}
            <Button
                className="fit-content ivy-button--tertiary-alert"
                label="Disconnect"
                onClick={showModal}
            />
        </>
    );

    const ssoNotConfiguredBody = (
        <>
            <div className="paragraph-three pbm color-dark-2">
                Not yet configured
            </div>
            <Button
                className="fit-content"
                label="Set Up SSO"
                onClick={() => {
                    navigate(AppRoutes.ssoSelectionPath, { state: provider });
                }}
            />
        </>
    );

    const ssoTabBody = sso ? hasSso : ssoNotConfiguredBody;

    return (
        <>
            {loading && <LoadingBar />}
            <div className="plm">
                <div className="flex-col flex-vs ptm">
                    <div className="heading-one pbs">
                        Single Sign On Provider
                    </div>
                    {ssoTabBody}
                </div>
            </div>
        </>
    );
};

const SsoTab: FC = () => {
    const session = useSelector((state: ApplicationState) => state.session);
    const { loading: lookupLoading, execute: lookupOrgBySlug } = useLoadingCall(
        apiClient.lookupOrgBySlug
    );
    const { loading, deleteSsoIntegration } = useSsoIntegration();
    const { ssoProvider } = session.organization;
    const [hasSso, setHasSso] = useState<boolean>(false);

    useEffect(() => {
        if (!ssoProvider) {
            lookupOrgBySlug(session.organization.slug).then(
                (response: OrgLookupResponse) => {
                    setHasSso(response.hasSSO);
                }
            );
        } else {
            setHasSso(true);
        }
    }, []);

    return (
        <DisconnectedSsoTab
            sso={hasSso}
            provider={ssoProvider}
            loading={loading || lookupLoading}
            deleteSsoIntegration={deleteSsoIntegration}
        />
    );
};

export default SsoTab;
