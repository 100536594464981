/* eslint-disable react/jsx-props-no-spreading */
import {
    Row,
    TableOptions,
    useBlockLayout,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";
import { FC, useEffect, useMemo } from "react";
import Tooltip from "components/Tooltip/Tooltip";
import alertsTableColumns from "./columns";
import { AlertRow } from "./types";
import getTableHeader from "./TableHeader";
import getTableBody from "./TableBody";

interface TableProps {
    data: AlertRow[];
    loading: boolean;
    setSelections: (rows: AlertRow[]) => void;
}

const AlertsTable: FC<TableProps> = (props: TableProps) => {
    const { data, setSelections, loading } = props;
    const columns = useMemo(() => alertsTableColumns, []);

    const defaultColumn = useMemo(
        () => ({
            defaultCanSort: true,
            disableSortBy: false,
            canSort: true,
        }),
        []
    );

    const tableOptions: TableOptions<AlertRow> = {
        columns,
        data,
        defaultColumn,
        disableSortRemove: false,
    };

    const { headerGroups, rows, prepareRow, selectedFlatRows } = useTable(
        tableOptions,
        useSortBy,
        useRowSelect,
        useBlockLayout
    );

    useEffect(() => {
        setSelections(
            selectedFlatRows.map((row: Row<AlertRow>) => row.original)
        );
    }, [setSelections, selectedFlatRows]);

    return (
        <>
            <table className="border border-light-m-1">
                {getTableHeader(headerGroups, loading)}
                {rows?.length > 0 && getTableBody(prepareRow, rows)}
            </table>
            <Tooltip id="table-cell-tooltip" clickable />
        </>
    );
};

export default AlertsTable;
