import { AnyAction, combineReducers } from "redux";
import sessionReducer from "main/login/sessionReducer";
import initialState from "main/app/state";

import { ApplicationState } from "main/app/types";
import DashboardReducer from "main/dashboard/dashboardReducer";
import AlertsReducer from "main/alerts/AlertsReducer";

const combinedReducer = combineReducers({
    session: sessionReducer,
    dashboard: DashboardReducer,
    alerts: AlertsReducer,
});

export default function rootReducer(
    state = initialState,
    action: AnyAction
): ApplicationState {
    return combinedReducer(state, action);
}
