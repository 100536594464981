import { Button } from "components/button/Button";
import { LabeledInput } from "components/input/Input";

import { useLogin } from "main/login/hooks";
import Analytics, { Events } from "main/utils/Analytics";
import { FC, FormEvent, useEffect } from "react";
import useEmailInput from "components/EmailInput/EmailInput";
import { useForm } from "components/form/useForm";

const LoginForm: FC = () => {
    const { inputs, onChange, filled, valid, setValid } = useForm({
        email: "",
        password: "",
    });
    const { emailInput, error: emailError } = useEmailInput();

    const { loading, login } = useLogin();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        Analytics.event(Events.sign_in);
        login(inputs.email as string, inputs.password as string);
    };

    useEffect(() => {
        setValid(filled && emailError === "");
    }, [filled, emailError]);

    return (
        <>
            <form
                onSubmit={handleSubmit}
                onChange={onChange}
                className="color-bg-lighter-grey width-xsmall"
            >
                {emailInput}
                <LabeledInput
                    autoComplete="password"
                    type="password"
                    name="password"
                    label="Password"
                    placeholder="password"
                />
                <Button
                    className="max-width"
                    disabled={loading || !valid}
                    label={loading ? "iVerifying..." : "Sign In"}
                />
            </form>
        </>
    );
};

export default LoginForm;
