export interface User {
    name: string;
    email: string;
    inviteAccepted: boolean;
    inviteSentDate: string;
}

export enum StripeSubscriptionStatus {
    incomplete = "incomplete",
    incomplete_expired = "incomplete_expired",
    trialing = "trialing",
    active = "active",
    past_due = "past_due",
    canceled = "canceled",
    unpaid = "unpaid",
}

export const paidStatuses = [
    StripeSubscriptionStatus.active,
    StripeSubscriptionStatus.trialing,
    StripeSubscriptionStatus.past_due,
];

export default User;
