import { FC } from "react";

const FileIcon: FC = () => (
    <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M26.2267 4.66669H42C44.5773 4.66669 46.6667 6.75602 46.6667 9.33335V46.6667C46.6667 49.244 44.5773 51.3334 42 51.3334H14C11.4227 51.3334 9.33334 49.244 9.33334 46.6667V21.56C9.33497 19.7041 10.0735 17.9248 11.3867 16.6134L21.28 6.72002C22.5915 5.40689 24.3708 4.66832 26.2267 4.66669ZM14 46.6667H42V9.33335H28V21C28 22.2887 26.9553 23.3334 25.6667 23.3334H14V46.6667ZM18.6667 29.1667V31.5C18.6667 32.1443 19.189 32.6667 19.8333 32.6667H36.1667C36.811 32.6667 37.3333 32.1443 37.3333 31.5V29.1667C37.3333 28.5224 36.811 28 36.1667 28H19.8333C19.189 28 18.6667 28.5224 18.6667 29.1667ZM19.8333 37.3334H29.1667C29.811 37.3334 30.3333 37.8557 30.3333 38.5V40.8334C30.3333 41.4777 29.811 42 29.1667 42H19.8333C19.189 42 18.6667 41.4777 18.6667 40.8334V38.5C18.6667 37.8557 19.189 37.3334 19.8333 37.3334Z"
        />
    </svg>
);

export default FileIcon;
