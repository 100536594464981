import { FC } from "react";

interface SortingProps {
    rotate: boolean;
}

const SortingArrowIcon: FC<SortingProps> = (props: SortingProps) => {
    const { rotate } = props;
    return (
        <svg
            width="10"
            height="6"
            viewBox="0 0 10 6"
            style={{ verticalAlign: "middle" }}
            transform={rotate ? "rotate(180)" : ""}
        >
            <path
                d="M0.859996 1.05999C0.76534 0.966107 0.712097 0.838309 0.712097 0.704991C0.712097 0.571672 0.76534 0.443874 0.859996 0.349991L1.06 0.149991C1.15407 0.0523322 1.28441 -0.00197559 1.42 -9.44494e-06H8.58C8.71558 -0.00197559 8.84592 0.0523322 8.94 0.149991L9.14 0.349991C9.23465 0.443874 9.2879 0.571672 9.2879 0.704991C9.2879 0.838309 9.23465 0.966107 9.14 1.05999L5.35 4.84999C5.25926 4.94667 5.13258 5.00151 5 5.00151C4.86741 5.00151 4.74073 4.94667 4.65 4.84999L0.859996 1.05999Z"
                fill="#0E212F"
            />
        </svg>
    );
};

export default SortingArrowIcon;
