import LoadingBar from "components/LoadingBar/LoadingBar";
import {
    ChangeEvent,
    ComponentType,
    FC,
    FormEvent,
    useEffect,
    useRef,
    useState,
} from "react";
import { Group, GroupSettings } from "main/add-members/types";
import Analytics, { Events } from "main/utils/Analytics";
import { Button } from "components/button/Button";
import { useGroups } from "main/add-members/hooks";
import { Link } from "components/Link";
import { Input } from "components/input/Input";
import SearchIcon from "icons/svgs/search.svg";

interface GroupSelectionFormProps {
    provider: string;
    loading: boolean;
    groups: Group[];
    hasNextPage: boolean;
    loadNextPage: () => void;
    searchGroups: (query: string | null) => void;
}

const GroupSelectionForm: FC<GroupSelectionFormProps> = (
    props: GroupSelectionFormProps
) => {
    const {
        provider,
        loading,
        groups,
        hasNextPage,
        loadNextPage,
        searchGroups,
    } = props;

    const [displayGroups, setdisplayGroups] = useState<GroupSettings>({});
    const [isSearching, setIsSearching] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState<GroupSettings>({});
    const [cachedGroupsState, setCachedGroupsState] = useState({
        cachedGroups: {},
        cachedHasNextPage: false,
    });
    const [submitButtonDisabled, setSubmitButtonDisabled] =
        useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string | null>(null);

    const { loading: submitting, fetchMembersInGroup } = useGroups();
    const oktaSetupDocs = `https://docs.iverify.io/sso-okta-groups`;

    const containerRef = useRef(null);

    const handleScroll = () => {
        const list = containerRef.current as unknown as HTMLUListElement;
        if (
            (hasNextPage || cachedGroupsState.cachedHasNextPage) &&
            list &&
            list.scrollHeight - list.scrollTop === list.clientHeight
        ) {
            loadNextPage();
        }
    };

    useEffect(() => {
        const list = containerRef.current as unknown as HTMLUListElement;
        list.addEventListener("scroll", handleScroll);
        return () => {
            list.removeEventListener("scroll", handleScroll);
        };
    });

    const updateGroups = (groupsByName: GroupSettings) => {
        const updatedGroups = Object.assign(
            groupsByName,
            ...Object.keys(groupsByName)
                .filter((key) =>
                    Object.prototype.hasOwnProperty.call(selectedGroups, key)
                )
                .map((key) => ({ [key]: selectedGroups[key] }))
        );

        setdisplayGroups(updatedGroups);
        if (!searchQuery) {
            setCachedGroupsState({
                cachedGroups: updatedGroups,
                cachedHasNextPage: hasNextPage,
            });
        }
    };

    useEffect(() => {
        const groupsByName = groups.reduce(
            (acc: GroupSettings, group: Group) => {
                const unselectedGroup = { ...group, selected: false };
                return { ...acc, [group.name]: unselectedGroup };
            },
            {}
        );
        updateGroups(groupsByName);
    }, [groups]);

    useEffect(() => {
        setSubmitButtonDisabled(
            Object.values(selectedGroups).every((g) => !g.selected)
        );
    }, [selectedGroups]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (searchQuery !== null) {
            const getData = setTimeout(() => {
                if (
                    searchQuery === "" &&
                    Object.keys(cachedGroupsState.cachedGroups).length > 0
                ) {
                    updateGroups(cachedGroupsState.cachedGroups);
                } else {
                    searchGroups(searchQuery);
                    setIsSearching(true);
                }
            }, 500);
            return () => clearTimeout(getData);
        }
        return () => {};
    }, [searchQuery]);

    const checkboxChanged = (e: ChangeEvent<HTMLInputElement>) => {
        const updatedGroups = { ...displayGroups };
        updatedGroups[e.target.name].selected = e.target.checked;
        if (e.target.checked) {
            setSelectedGroups({
                ...selectedGroups,
                [e.target.name]: updatedGroups[e.target.name],
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
            const { [e.target.name]: _, ...rest } = selectedGroups;
            setSelectedGroups(rest);
        }
    };

    const submitGroupsClicked = (e: FormEvent) => {
        e.preventDefault();
        Analytics.event(Events.submit_groups);
        fetchMembersInGroup(displayGroups);
    };

    const GroupSearch = (
        <Input
            Icon={SearchIcon as unknown as ComponentType}
            className="mb-0 !bg-white placeholder:italic z-[1]"
            placeholder="Search groups"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery ?? ""}
        />
    );

    const GroupSelector = (
        <div className="-mt-7 pt-5">
            <ul className="ivy-list mt-0 pt-2" ref={containerRef}>
                <div className="mt-2 h-1">
                    {!loading && Object.keys(displayGroups).length === 0 ? (
                        <li className="mvxs phxs color-mid-m-1">
                            Nothing found
                        </li>
                    ) : (
                        loading &&
                        (!hasNextPage || isSearching) && <LoadingBar />
                    )}
                </div>
                {Object.entries(displayGroups).map(([name, group]) => (
                    <li key={group.name} className="mvxs phxs">
                        <label className="form-row color-black">
                            <input
                                type="checkbox"
                                name={group.name}
                                checked={group.selected}
                                onChange={checkboxChanged}
                            />
                            {name}
                        </label>
                    </li>
                ))}
                {loading && hasNextPage && <LoadingBar />}
            </ul>
        </div>
    );

    return (
        <>
            <div className="special-case-title color-action-lt proper-case mtxl mbm">
                Add Team Members: {provider}
            </div>

            <div className="title-three flex-row">
                <div className="mas color-mid-p-1">1. SSO Settings</div>
                <div className="mas">2. Select Groups</div>
                <div className="mas color-mid-p-1">3. Send Invitations</div>
            </div>

            <p className="paragraph-one text-center">
                Select the user groups you want to have access to the iVerify
                app. You will be able to confirm data before sending
                invitations.
            </p>
            <form className="width-small pt-5">
                {GroupSearch}
                {GroupSelector}
                <Button
                    className="max-width"
                    disabled={loading || submitButtonDisabled || submitting}
                    onClick={submitGroupsClicked}
                    label={submitting ? "Fetching Members..." : "Add Groups"}
                />
            </form>

            {provider === "okta" && (
                <p className="paragraph-two mtm text-center">
                    Need help?
                    <br />
                    Please reference&nbsp;
                    <Link to={{ pathname: oktaSetupDocs }}>
                        Okta Groups Setup Documentation
                    </Link>
                </p>
            )}
            <p className="paragraph-three mvl">
                Please note: we will deduplicate users that are within multiple
                groups.
            </p>
        </>
    );
};

export default GroupSelectionForm;
