import { IconProps } from "main/app/types";
import React from "react";

const CheckmarkIcon: React.FC<IconProps> = ({
    color = "#09AA71",
    ...props
}: IconProps) => (
    <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
        <path
            fill={color}
            d="M17.373 6.303 8.54 15.137a.417.417 0 0 1-.592 0l-4.492-4.492a.417.417 0 0 1 0-.592l.584-.583a.417.417 0 0 1 .591 0l3.609 3.608 7.958-7.958a.425.425 0 0 1 .592 0l.583.592a.417.417 0 0 1 0 .591Z"
        />
    </svg>
);

export default CheckmarkIcon;
