import { IconProps } from "main/app/types";
import Tooltip from "components/Tooltip/Tooltip";
import { FC } from "react";

interface TooltipProps {
    color?: string;
}

const TooltipDefaultProps: TooltipProps = {
    color: "#B02145",
};

const TableAlertIcon: FC<TooltipProps> = (props: TooltipProps) => {
    const { color } = props;
    return (
        <div>
            <svg width="18" height="18" viewBox="0 0 18 18">
                <path
                    d="M13.61 0.88L17.12 4.39C17.6779 4.94716 17.994 5.70156 18 6.49V11.49C18.0046 12.2825 17.6954 13.0447 17.14 13.61L13.63 17.12C13.0679 17.6828 12.3054 17.9993 11.51 18H6.51C5.71748 18.0046 4.95533 17.6954 4.39 17.14L0.88 13.63C0.31723 13.0679 0.000700208 12.3054 0 11.51V6.51C0.000700208 5.71462 0.31723 4.95207 0.88 4.39L4.37 0.88C4.93207 0.31723 5.69462 0.000700208 6.49 0H11.49C12.2854 0.000700208 13.0479 0.31723 13.61 0.88ZM15.71 12.19C15.8947 12.0037 15.9989 11.7523 16 11.49L16.02 6.51C16.0189 6.24766 15.9147 5.99626 15.73 5.81L12.21 2.29C12.0237 2.10526 11.7723 2.0011 11.51 2H6.51C6.24766 2.0011 5.99626 2.10526 5.81 2.29L2.29 5.79C2.10526 5.97626 2.0011 6.22766 2 6.49V11.49C2.0011 11.7523 2.10526 12.0037 2.29 12.19L5.79 15.71C5.97626 15.8947 6.22766 15.9989 6.49 16H11.49C11.7523 15.9989 12.0037 15.8947 12.19 15.71L15.71 12.19ZM9.5 11H8.5C8.22386 11 8 11.2239 8 11.5V12.5C8 12.7761 8.22386 13 8.5 13H9.5C9.77614 13 10 12.7761 10 12.5V11.5C10 11.2239 9.77614 11 9.5 11ZM8.32 5H9.68C9.82369 4.99896 9.96088 5.05979 10.0566 5.16698C10.1523 5.27417 10.1972 5.41735 10.18 5.56L9.78 8.78C9.76474 8.90624 9.65716 9.00092 9.53 9H8.47C8.34284 9.00092 8.23526 8.90624 8.22 8.78L7.82 5.56C7.80275 5.41735 7.84771 5.27417 7.94342 5.16698C8.03912 5.05979 8.17631 4.99896 8.32 5Z"
                    fill={color}
                />
            </svg>
        </div>
    );
};
TableAlertIcon.defaultProps = TooltipDefaultProps;

interface TableWarningIconProps {
    deviceName?: string;
}

const TableWarningIcon: FC<TableWarningIconProps> = (
    props: TableWarningIconProps
) => {
    const { deviceName } = props;

    const icon = (
        <svg width="23" height="19" viewBox="0 0 23 19">
            <path
                d="M11.8128 0.37L21.9052 17.63C22.0351 17.8667 22.0351 18.1533 21.9052 18.39L21.7652 18.62C21.6355 18.8573 21.3854 19.0035 21.115 19H0.890216C0.619837 19.0035 0.369684 18.8573 0.240062 18.62L0.100029 18.39C-0.0298638 18.1533 -0.0298638 17.8667 0.100029 17.63L10.2124 0.37C10.3492 0.141211 10.596 0.0008087 10.8626 0H11.1626C11.4293 0.0008087 11.676 0.141211 11.8128 0.37ZM11.0026 3L2.79067 17H19.2045L11.0026 3ZM10.5025 13C10.2263 13 10.0024 13.2239 10.0024 13.5V14.5C10.0024 14.7761 10.2263 15 10.5025 15H11.5027C11.7789 15 12.0028 14.7761 12.0028 14.5V13.5C12.0028 13.2239 11.7789 13 11.5027 13H10.5025ZM10.2224 10.78L9.82233 7.56C9.80508 7.41735 9.85005 7.27417 9.94578 7.16698C10.0415 7.05979 10.1787 6.99896 10.3224 7H11.6828C11.8265 6.99896 11.9637 7.05979 12.0594 7.16698C12.1552 7.27417 12.2001 7.41735 12.1829 7.56L11.7828 10.78C11.7675 10.9062 11.6599 11.0009 11.5327 11H10.4725C10.3453 11.0009 10.2377 10.9062 10.2224 10.78Z"
                fill="#D68029"
            />
        </svg>
    );

    return (
        <div
            data-tooltip-content={`Latest iOS is not available for ${deviceName}`}
        >
            {icon}
            <Tooltip />
        </div>
    );
};

const FormErrorIcon: FC<IconProps> = (props) => (
    <svg width="18" height="18" viewBox="0 0 18 18" {...props}>
        <path
            d="M0.666687 8.99996C0.666687 4.39759 4.39765 0.666626 9.00002 0.666626C11.2102 0.666626 13.3298 1.5446 14.8926 3.1074C16.4554 4.67021 17.3334 6.78982 17.3334 8.99996C17.3334 13.6023 13.6024 17.3333 9.00002 17.3333C4.39765 17.3333 0.666687 13.6023 0.666687 8.99996ZM2.33335 8.99996C2.33335 12.6819 5.31812 15.6666 9.00002 15.6666C10.7681 15.6666 12.4638 14.9642 13.7141 13.714C14.9643 12.4638 15.6667 10.7681 15.6667 8.99996C15.6667 5.31806 12.6819 2.33329 9.00002 2.33329C5.31812 2.33329 2.33335 5.31806 2.33335 8.99996ZM8.58335 10.6666C8.35324 10.6666 8.16669 10.8532 8.16669 11.0833V11.9166C8.16669 12.1467 8.35324 12.3333 8.58335 12.3333H9.41669C9.64681 12.3333 9.83335 12.1467 9.83335 11.9166V11.0833C9.83335 10.8532 9.64681 10.6666 9.41669 10.6666H8.58335ZM8.43335 5.66662H9.56669C9.68643 5.66576 9.80076 5.71645 9.88051 5.80577C9.96026 5.8951 9.99773 6.01441 9.98335 6.13329L9.65002 8.81662C9.63731 8.92183 9.54765 9.00072 9.44169 8.99996H8.55835C8.45239 9.00072 8.36273 8.92183 8.35002 8.81662L8.01669 6.13329C8.00232 6.01441 8.03978 5.8951 8.11953 5.80577C8.19929 5.71645 8.31361 5.66576 8.43335 5.66662Z"
            fill="#B02145"
        />
    </svg>
);

const AlertSuccessIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM10.73 15.35L16.35 9.73C16.5341 9.53685 16.5341 9.23315 16.35 9.04L15.82 8.51C15.6256 8.31943 15.3144 8.31943 15.12 8.51L10.38 13.25L8.88 11.76C8.78927 11.6633 8.66259 11.6085 8.53 11.6085C8.39741 11.6085 8.27073 11.6633 8.18 11.76L7.65 12.29C7.55534 12.3839 7.5021 12.5117 7.5021 12.645C7.5021 12.7783 7.55534 12.9061 7.65 13L10.03 15.35C10.1207 15.4467 10.2474 15.5015 10.38 15.5015C10.5126 15.5015 10.6393 15.4467 10.73 15.35Z"
            fill="#09AA71"
        />
    </svg>
);

const AlertWarningIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9052 19.63L12.8128 2.37C12.676 2.14121 12.4293 2.00081 12.1627 2H11.8626C11.596 2.00081 11.3493 2.14121 11.2124 2.37L1.10004 19.63C0.970151 19.8667 0.970151 20.1533 1.10004 20.39L1.24008 20.62C1.3697 20.8573 1.61985 21.0035 1.89023 21H22.115C22.3854 21.0035 22.6356 20.8573 22.7652 20.62L22.9052 20.39C23.0351 20.1533 23.0351 19.8667 22.9052 19.63ZM13.0029 16.5C13.0029 16.7761 12.779 17 12.5027 17H11.5025C11.2263 17 11.0024 16.7761 11.0024 16.5V15.5C11.0024 15.2239 11.2263 15 11.5025 15H12.5027C12.779 15 13.0029 15.2239 13.0029 15.5V16.5ZM12.5327 13C12.6599 13.0009 12.7675 12.9062 12.7828 12.78L13.1829 9.56C13.2002 9.41735 13.1552 9.27417 13.0595 9.16698C12.9637 9.05979 12.8265 8.99896 12.6828 9H11.3225C11.1787 8.99896 11.0415 9.05979 10.9458 9.16698C10.8501 9.27417 10.8051 9.41735 10.8223 9.56L11.2224 12.78C11.2377 12.9062 11.3453 13.0009 11.4725 13H12.5327Z"
            fill="#D68029"
        />
    </svg>
);

const AlertErrorIcon: FC = () => (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM13 15.5C13 15.7761 12.7761 16 12.5 16H11.5C11.2239 16 11 15.7761 11 15.5V14.5C11 14.2239 11.2239 14 11.5 14H12.5C12.7761 14 13 14.2239 13 14.5V15.5ZM12.53 12C12.6572 12.0009 12.7647 11.9062 12.78 11.78L13.18 8.56C13.1972 8.41735 13.1523 8.27417 13.0566 8.16698C12.9609 8.05979 12.8237 7.99896 12.68 8H11.32C11.1763 7.99896 11.0391 8.05979 10.9434 8.16698C10.8477 8.27417 10.8028 8.41735 10.82 8.56L11.22 11.78C11.2353 11.9062 11.3428 12.0009 11.47 12H12.53Z"
            fill="#B02145"
        />
    </svg>
);

const AlertIcon: FC<IconProps> = ({ color }: IconProps) => (
    <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM11 13.5C11 13.7761 10.7761 14 10.5 14H9.5C9.22386 14 9 13.7761 9 13.5V10.5C9 10.2239 9.22386 10 9.5 10H10.5C10.7761 10 11 10.2239 11 10.5V13.5ZM10.5 8C10.7761 8 11 7.77614 11 7.5V6.5C11 6.22386 10.7761 6 10.5 6H9.5C9.22386 6 9 6.22386 9 6.5V7.5C9 7.77614 9.22386 8 9.5 8H10.5Z"
            fill={color}
        />
    </svg>
);

const AlertBannerIcon: FC = () => <AlertIcon color="#FFF" />;

const AlertInfoIcon: FC = () => <AlertIcon color="#007DB2" />;

export {
    AlertBannerIcon,
    AlertErrorIcon,
    AlertInfoIcon,
    AlertSuccessIcon,
    AlertWarningIcon,
    FormErrorIcon,
    TableAlertIcon,
    TableWarningIcon,
};
