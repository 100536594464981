import { IconProps } from "main/app/types";
import React from "react";

const EyeOpenIcon: React.FC<IconProps> = ({
    color = "#007DB2",
    ...props
}: IconProps) => (
    <svg width="22" height="20" viewBox="0 0 22 20" {...props}>
        <path
            fill={color}
            fillRule="evenodd"
            d="M10.993 2c4.834-.051 9.201 2.99 10.994 7.657.018.114.018.23 0 .343a.958.958 0 0 1-.066.377c-1.795 4.63-6.126 7.652-10.928 7.623-4.807.025-9.14-3.007-10.927-7.646A.958.958 0 0 1 0 10c.001-.128.024-.256.066-.377 1.794-4.63 6.126-7.651 10.927-7.622Zm-8.718 8c1.61 3.486 4.998 5.706 8.717 5.715 3.72-.009 7.107-2.229 8.718-5.714-1.605-3.49-4.996-5.712-8.718-5.712-3.721 0-7.112 2.222-8.717 5.712Zm8.72-2.285c1.214 0 2.199 1.023 2.199 2.285 0 1.263-.985 2.286-2.199 2.286S8.796 11.263 8.796 10c0-1.262.985-2.285 2.2-2.285ZM6.595 10c0-2.525 1.969-4.571 4.397-4.571 2.429 0 4.398 2.046 4.398 4.57 0 2.525-1.97 4.572-4.398 4.572s-4.397-2.046-4.397-4.571Z"
            clipRule="evenodd"
        />
    </svg>
);

export default EyeOpenIcon;
