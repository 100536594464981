import GroupSelectionForm from "main/add-members/sso/GroupSelectionForm";
import { useEffect, useState } from "react";
import { useGroups, useSSOProvider } from "main/add-members/hooks";

const GroupSelectionPage: React.FC = () => {
    const {
        loading: groupLoading,
        groups,
        fetchSsoGroups,
        hasNextPage,
    } = useGroups();
    const { fetchSsoProvider } = useSSOProvider();
    const [provider, setProvider] = useState<string>("");

    useEffect(() => {
        fetchSsoGroups();
    }, []);

    useEffect(() => {
        fetchSsoProvider().then(setProvider);
    }, [fetchSsoProvider]);

    return (
        <div className="flex-col flex-vc">
            <GroupSelectionForm
                loading={groupLoading}
                groups={groups}
                provider={provider}
                loadNextPage={fetchSsoGroups}
                hasNextPage={hasNextPage}
                searchGroups={(searchQuery) =>
                    fetchSsoGroups(searchQuery ?? "")
                }
            />
        </div>
    );
};

export default GroupSelectionPage;
