import usePageTitle from "main/utils/usePageTitle";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "main/app/types";
import SsoConfigForm from "components/SsoConfigForm/SsoConfigForm";
import { FC, useEffect } from "react";
import { AppRoutes } from "main/app/App";

interface SSOSettingsProps {
    provider: string;
    slug: string;
    redirectUrl: string;
}

export const DisconnectedSsoSettingsPage: FC<SSOSettingsProps> = (
    props: SSOSettingsProps
) => {
    usePageTitle("Provide Your SSO Details");
    const navigate = useNavigate();

    const { provider, slug, redirectUrl } = props;

    useEffect(() => {
        if (!provider) {
            navigate(AppRoutes.ssoSelectionPath);
        }
    }, [provider]);

    return (
        <>
            <div className="special-case-title color-action-lt proper-case mtxl mbm">
                Add Team Members: {provider}
            </div>

            <div className="title-three flex-row">
                <div className="mas">1. SSO Settings</div>
                <div className="mas color-mid-p-1">2. Select Groups</div>
                <div className="mas color-mid-p-1">3. Send Invitations</div>
            </div>

            <p className="paragraph-one mtm mbl">
                You will be able to select from SSO groups once configured
            </p>

            <SsoConfigForm
                provider={provider}
                slug={slug}
                redirectUrl={redirectUrl}
            />
        </>
    );
};

const SsoSettingsPage: () => JSX.Element = () => {
    const location = useLocation();
    const provider = location.state as string;
    const session = useSelector((state: ApplicationState) => state.session);
    const { slug, redirectUrl } = session.organization;

    return (
        <div className="flex-col flex-vc">
            <DisconnectedSsoSettingsPage
                provider={provider}
                slug={slug}
                redirectUrl={redirectUrl}
            />
        </div>
    );
};

export default SsoSettingsPage;
