import purify from "dompurify";

// eslint-disable-next-line import/prefer-default-export
export const withEngSuffix = (str: string, count: number): string =>
    count === 1 ? str : `${str}s`;

/**
 * @param dateStr - The date to be formatted. Accepts either a Unix timestamp (as a number)
 *                  or an ISO 8601 date (as a string).
 * @param locale - The locale to use for formatting the date. Defaults to "en-US".
 * @param formatOptions - Optional settings to specify which parts of the date to include.
 *                        If not provided, defaults to showing the full date and time.
 */
export const toHumanReadableDate = (
    dateStr: string | number,
    formatOptions?: {
        year?: boolean;
        month?: boolean;
        day?: boolean;
        time?: boolean;
    },
    locale = "en-US"
): string => {
    const dateObj = new Date(dateStr);
    const defaultOptions: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    };

    if (formatOptions) {
        if (formatOptions.time === false) {
            defaultOptions.hour = undefined;
            defaultOptions.minute = undefined;
        } else {
            defaultOptions.year =
                formatOptions.year === false ? undefined : defaultOptions.year;
            defaultOptions.month =
                formatOptions.month === false
                    ? undefined
                    : defaultOptions.month;
            defaultOptions.day =
                formatOptions.day === false ? undefined : defaultOptions.day;
        }
    }

    return new Intl.DateTimeFormat(locale, defaultOptions).format(dateObj);
};

export const isBeforeCurrentDate = (dateStr: string): boolean => {
    const dateObj = new Date(dateStr);
    const now = new Date();
    return dateObj.getTime() < now.getTime();
};

export const cleanTextInput = (text: string): string =>
    purify.sanitize(text, { USE_PROFILES: { html: false } }).trim();
