import { Button } from "components/button/Button";

export interface MemberListProps {
    ssoMemberPreviewCount: number;
    displayMembers: DisplayMember[];
    setDisplayMembers: (member: DisplayMember[]) => void;
}

export interface DisplayMember {
    id?: string;
    name: string;
    email: string;
    duplicate: boolean;
    allowedDupe: boolean;
}

export const MemberList: React.FC<MemberListProps> = (
    props: MemberListProps
) => {
    const { ssoMemberPreviewCount, displayMembers, setDisplayMembers } = props;
    const hasDuplicates = displayMembers.some(
        (m) => m.duplicate && !m.allowedDupe
    );

    const handleDuplicateMembers = (member: DisplayMember) => {
        setDisplayMembers(
            displayMembers.map((m) => {
                if (m.id === member.id) {
                    return { ...m, allowedDupe: !m.allowedDupe };
                }
                return m;
            })
        );
    };
    const handleAllDuplicateMembers = (allowedDupe: boolean) => {
        setDisplayMembers(
            displayMembers.map((m) => {
                if (m.duplicate) {
                    return { ...m, allowedDupe };
                }
                return m;
            })
        );
    };

    return (
        <ul className="ivy-list phs">
            <li className="flex-row mvxs h-7">
                <div className="basis-5/12 md:basis-1/4 paragraph-two">
                    Names
                </div>
                <div className="basis-5/12 paragraph-two text-center">
                    Email
                </div>
                <div className="basis-1/12 md:basis-1/4 paragraph-two text-center" />
                <div className="basis-1/12 md:basis-1/4 paragraph-two text-right">
                    {hasDuplicates && (
                        <Button
                            appearance="link"
                            label="Undo all"
                            onClick={() => handleAllDuplicateMembers(true)}
                        />
                    )}
                </div>
            </li>

            {displayMembers.length > 0 &&
                displayMembers?.map((member) => (
                    <li
                        className={`flex-row mvxs h-6${
                            member.duplicate && !member.allowedDupe
                                ? " color-mid-m-1"
                                : ""
                        }${member.duplicate ? " hover:cursor-pointer" : ""}`}
                        {...(member.duplicate && {
                            onClick: () => handleDuplicateMembers(member),
                        })}
                    >
                        <div
                            className="basis-5/12 md:basis-1/4 paragraph-two"
                            data-testid="member-name"
                        >
                            <span
                                className={
                                    member.duplicate && !member.allowedDupe
                                        ? "line-through"
                                        : ""
                                }
                            >
                                {member.name}
                            </span>
                        </div>
                        <div
                            className="basis-5/12 md:basis-2/4 text-center"
                            data-testid="member-email"
                        >
                            <span
                                className={
                                    member.duplicate && !member.allowedDupe
                                        ? "line-through"
                                        : ""
                                }
                            >
                                {member.email}
                            </span>
                        </div>
                        <div className="basis-1/12 md:basis-1/4 max-sm:hidden text-right">
                            {member.duplicate && (
                                <span className="color-mid-m-1">duplicate</span>
                            )}
                        </div>
                        <div
                            className="basis-2/12 md:basis-1/4 text-right"
                            data-testid="member-duplicate"
                        >
                            {member.duplicate && !member.allowedDupe && (
                                <Button
                                    appearance="link"
                                    label="Undo"
                                    onClick={() =>
                                        handleDuplicateMembers(member)
                                    }
                                />
                            )}
                        </div>
                    </li>
                ))}
            {ssoMemberPreviewCount > 0 && (
                <li className="color-mid-m-1 italic paragraph-two">
                    {ssoMemberPreviewCount} more rows...
                </li>
            )}
        </ul>
    );
};
