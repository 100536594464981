import { AnyAction } from "redux";
import initialState from "main/app/state";
import { AlertsState } from "./types";

export enum AlertsActions {
    FetchAlerts = "FetchAlerts",
    ClearPages = "ClearAlertsPages",
}

export default function AlertsReducer(
    state = initialState.alerts,
    action: AnyAction
): AlertsState {
    switch (action.type) {
        case AlertsActions.FetchAlerts: {
            return {
                ...state,
                paginatedAlerts: {
                    ...state.paginatedAlerts,
                    [action.page]: action.alerts,
                },
                totalPages: action.totalPages,
            };
        }

        case AlertsActions.ClearPages: {
            return {
                ...state,
                paginatedAlerts: {},
                totalPages: 0,
            };
        }

        default:
            return state;
    }
}
