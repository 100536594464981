import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import {
    ConfirmCardSetupData,
    Stripe,
    StripeElementChangeEvent,
    StripeElementStyle,
} from "@stripe/stripe-js";
import { Button } from "components/button/Button";
import { LabeledInput, LabelWithErrorMessage } from "components/input/Input";
import { AppRoutes } from "main/app/App";
import { KeyLookup } from "main/create-account/types";
import { SessionActions } from "main/login/sessionReducer";
import { useUpdatedPaymentMethod } from "main/settings/hooks";
import { paidStatuses, StripeSubscriptionStatus } from "main/settings/types";
import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import "./Stripe.scss";
import { useDispatch } from "react-redux";
import {
    displayErrorBanner,
    displaySuccessBanner,
} from "main/utils/displayBanner";
import { elementFont } from "components/CheckoutForm/types";
import Analytics, { Events } from "main/utils/Analytics";

/* eslint-disable react/no-unused-prop-types */
export interface CheckoutFormProps {
    name: string;
    onSaveBilling: () => void;
    noPaymentMethod: boolean;
    buttonLabel?: string;
}

export interface LoadedCheckoutFormProps extends CheckoutFormProps {
    stripePromise: Promise<Stripe | null>;
}

interface CheckoutFormState extends KeyLookup<string> {
    name: string;
    card: string;
}

const CheckoutForm: FC<CheckoutFormProps> = (props: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const { fetchStripeClientSecret, updateStripePaymentInfo } =
        useUpdatedPaymentMethod();

    const propsWithDefaults = { buttonLabel: "Save Billing", ...props };
    const {
        name: defaultName,
        onSaveBilling,
        noPaymentMethod,
        buttonLabel,
    } = propsWithDefaults;
    const [loading, setLoading] = useState<boolean>(false);

    const [inputs, setInputs] = useState<CheckoutFormState>({
        name: defaultName,
        card: "",
    });
    const [errors, setErrors] = useState<CheckoutFormState>({
        name: "",
        card: "",
    });
    const [disabled, setDisabled] = useState<boolean>(true);

    const checkStripeStatusBySubmit = (status: string): boolean => {
        const stripeStatus = status as StripeSubscriptionStatus;

        // probably entered card has no money
        if (!paidStatuses.includes(stripeStatus)) {
            displayErrorBanner(
                "Unable to update default payment method. Try again with another card"
            );
            return false;
        }

        dispatch({ type: SessionActions.PaymentUpdated });
        onSaveBilling();

        // if it was first payment at start
        if (noPaymentMethod) {
            window.location.href = AppRoutes.dashboardPath;
        } else {
            displaySuccessBanner(
                "Default payment method updated. Billing page may not update immediately."
            );
        }

        return true;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!stripe || !elements || loading) {
            return;
        }

        Analytics.event(Events.submit_payment_information);

        setLoading(true);
        fetchStripeClientSecret()
            .then(async (clientSecret: string) => {
                const cardElement = elements.getElement("card");

                const cardData: ConfirmCardSetupData = {
                    payment_method: {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        card: cardElement!,
                        billing_details: {
                            name: inputs.name,
                        },
                    },
                    return_url: `${window.location.origin}`,
                };

                const result = await stripe.confirmCardSetup(
                    clientSecret,
                    cardData
                );
                if (result.error) {
                    displayErrorBanner(
                        "Unable to update default payment method"
                    );
                    onSaveBilling();
                } else if (result.setupIntent.payment_method) {
                    await updateStripePaymentInfo(
                        result.setupIntent.payment_method as string
                    )
                        .then((response) => {
                            checkStripeStatusBySubmit(response.status);
                        })
                        .catch(() => {
                            displayErrorBanner(
                                "Unable to update default payment method"
                            );
                        });
                }
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((error: any) => {
                // eslint-disable-next-line no-console
                console.log("[unknown error]", error);
                displayErrorBanner("Unable to update default payment method");
                onSaveBilling();
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const style: StripeElementStyle = {
        base: {
            fontFamily: "Open Sans, sans-serif",
            "::placeholder": {
                color: "rgba(101, 132, 154, 0.8)",
            },
        },
        invalid: {
            color: "#b02145",
        },
    };

    const onChange = (e: ChangeEvent<HTMLFormElement>) => {
        const inputName = e.target.name;
        setErrors({ ...errors, [inputName]: "" });
        setInputs({ ...inputs, [inputName]: e.target.value });
    };

    const onStripeChange = (e: StripeElementChangeEvent) => {
        const inputName = e.elementType;
        const error = e.error ? e.error.message : "";
        const inputValue = e.complete ? "done" : "";
        setErrors({ ...errors, [inputName]: error });
        setInputs({ ...inputs, [inputName]: inputValue });
    };

    const validInputs = (): boolean =>
        (inputs.name && inputs.card && !errors.name && !errors.card) === true;

    useEffect(() => {
        setDisabled(!validInputs());
    }, [inputs, errors]);

    return (
        <>
            <form
                className="width-small"
                onChange={onChange}
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <LabeledInput
                    label="Name on Card"
                    name="name"
                    defaultValue={defaultName}
                    placeholder={defaultName}
                    error={errors.name}
                />
                <LabelWithErrorMessage label="Card Details" error={errors.card}>
                    <CardElement
                        options={{ style }}
                        className="stripe-element"
                        onChange={onStripeChange}
                    />
                </LabelWithErrorMessage>
                <Button
                    label={loading ? "Updating Billing..." : buttonLabel}
                    type="submit"
                    disabled={!stripe || disabled || loading}
                />
            </form>
        </>
    );
};

const LoadedCheckoutForm: FC<LoadedCheckoutFormProps> = (
    props: LoadedCheckoutFormProps
) => {
    const { stripePromise, ...rest } = props;

    return (
        <Elements stripe={stripePromise} options={elementFont}>
            <CheckoutForm {...rest} />
        </Elements>
    );
};

export default LoadedCheckoutForm;
