import { useMemo, useState } from "react";
import {
    EnrolledFilter,
    Filters,
    SeverityFilter,
    SeverityLevels,
    TableFilters,
} from "main/app/types";
import { useLocation, useNavigate } from "react-router-dom";
import useDidMountEffect from "main/utils/useDidMountEffect";
import MultiSelectInput from "components/MultiSelectInput";

export type FilterHook = {
    filterMenu: JSX.Element;
    filters: TableFilters;
};

export const useFilter = (): FilterHook => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const filterParams = {
        enrolled: Object.values(EnrolledFilter).includes(
            queryParams.get(Filters.ENROLLED) as EnrolledFilter
        )
            ? (queryParams.get(Filters.ENROLLED) as EnrolledFilter)
            : EnrolledFilter.NONE,

        severity: Object.values(SeverityLevels).reduce(
            (acc, curr) => ({
                ...acc,
                [curr]: queryParams.getAll(Filters.SEVERITY).includes(curr),
            }),
            {} as SeverityFilter
        ),
    };
    const [filters, setFilters] = useState<TableFilters>(filterParams);

    useDidMountEffect(() => {
        const query = new URLSearchParams(location.search);
        if (filters.enrolled === EnrolledFilter.NONE) {
            query.delete(Filters.ENROLLED);
        } else {
            query.set(Filters.ENROLLED, filters.enrolled as string);
        }

        if (filters.severity) {
            Object.entries(filters.severity).forEach(([key, value]) => {
                if (value) {
                    if (!query.getAll(Filters.SEVERITY).includes(key)) {
                        query.append(Filters.SEVERITY, key);
                    }
                } else if (query.getAll(Filters.SEVERITY).includes(key)) {
                    query.delete(Filters.SEVERITY, key);
                }
            });
        }

        navigate({
            search: query.toString(),
        });
    }, [filters]);

    const handleEnrolledFilter = (selected: Record<string, boolean>) => {
        const { enrolled, notEnrolled } = selected;
        if (enrolled && !notEnrolled) {
            setFilters({
                ...filters,
                enrolled: EnrolledFilter.ENROLLED,
            });
        } else if (!enrolled && notEnrolled) {
            setFilters({
                ...filters,
                enrolled: EnrolledFilter.NOT_ENROLLED,
            });
        } else {
            setFilters({
                ...filters,
                enrolled: EnrolledFilter.NONE,
            });
        }
    };

    const handleSeverityFilter = (selected: Record<string, boolean>) => {
        setFilters({
            ...filters,
            severity: selected as unknown as SeverityFilter,
        });
    };

    const filterMenu = useMemo(
        () => (
            <>
                <div className="dashboard-dropdown-label">FILTERS</div>
                <MultiSelectInput
                    title={
                        // eslint-disable-next-line no-nested-ternary
                        filters.enrolled === EnrolledFilter.NONE
                            ? "All devices"
                            : filters.enrolled === EnrolledFilter.ENROLLED
                                ? "Enrolled"
                                : "Not Enrolled"
                    }
                    onSelect={handleEnrolledFilter}
                    options={[
                        {
                            label: "Enrolled",
                            value: "enrolled",
                            selected:
                                filters.enrolled === EnrolledFilter.ENROLLED,
                        },
                        {
                            label: "Not enrolled",
                            value: "notEnrolled",
                            selected:
                                filters.enrolled ===
                                EnrolledFilter.NOT_ENROLLED,
                        },
                    ]}
                />
                <MultiSelectInput
                    title="Severity"
                    onSelect={handleSeverityFilter}
                    showSelectCount
                    options={[
                        {
                            label: "Critical",
                            value: SeverityLevels.CRITICAL,
                            selected:
                                filters.severity?.[SeverityLevels.CRITICAL],
                        },
                        {
                            label: "Fixes needed",
                            value: SeverityLevels.WARNING,
                            selected:
                                filters.severity?.[SeverityLevels.WARNING],
                        },
                        {
                            label: "Secure",
                            value: SeverityLevels.SECURE,
                            selected: filters.severity?.[SeverityLevels.SECURE],
                        },
                    ]}
                />
            </>
        ),
        [filters]
    );

    return { filterMenu, filters };
};
