import { Link } from "components/Link";
import Tooltip from "components/Tooltip/Tooltip";
import { Button } from "components/button/Button";
import { LabeledInput } from "components/input/Input";
import { useConfirmationModal } from "components/modal/Modal";
import CheckmarkIcon from "icons/ts/CheckmarkIcon";
import CopyIcon from "icons/ts/CopyIcon";
import EyeClosedIcon from "icons/ts/EyeClosedIcon";
import EyeOpenIcon from "icons/ts/EyeOpenIcon";
import { AppRoutes } from "main/app/App";
import { useEffect, useMemo, useState } from "react";
import ToggleInput from "components/ToggleInput";
import { useWebhookConfig } from "./hooks";

const WebhookSettings: React.FC = () => {
    const {
        loading: webhookLoading,
        webhookConfig,
        webhookError,
        getWebhookConfig,
        getWebhookSecret,
        saveWebhookConfig,
        deactivateWebhookConfig,
    } = useWebhookConfig();

    const [isSecretCopied, setIsSecretCopied] = useState<boolean>(false);
    const [showSecret, setShowSecret] = useState<boolean>(false);
    const [webhookToggle, setWebhookToggle] = useState<boolean>(false);

    const [webhookUrl, setWebhookUrl] = useState<string>(
        webhookConfig.url ?? ""
    );

    const deactivateWebhookModalProps = {
        title: "Deactivate Webhooks",
        message:
            "Are you sure you want to deactivate webhooks?\nYou will not be able to receive webhook notifications.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Deactivate",
        onCancelModal: () => setWebhookToggle(true),
    };

    const {
        showModal: showDeactivateWebhookModal,
        modal: deactivateWebhookModal,
    } = useConfirmationModal({
        ...deactivateWebhookModalProps,
    });

    useEffect(() => {
        getWebhookConfig();
    }, [getWebhookConfig]);

    useEffect(() => {
        if (webhookToggle && !(webhookConfig.url || webhookConfig.secret)) {
            getWebhookSecret();
            setShowSecret(true);
        } else if (!webhookToggle && webhookConfig.url) {
            showDeactivateWebhookModal();
        }
    }, [webhookToggle]);

    const handleSaveWebhookConfig = () => {
        saveWebhookConfig({
            ...webhookConfig,
            url: webhookUrl,
        });
    };

    useEffect(() => {
        if (webhookConfig.url) {
            setWebhookUrl(webhookConfig.url);
            setWebhookToggle(true);
        }
    }, [webhookConfig]);

    const handleWebhookSecretCopy = () => {
        navigator.clipboard.writeText(webhookConfig.secret as string);
        setIsSecretCopied(true);
    };

    const webhookInputIcons = useMemo(
        () => (
            <>
                {showSecret ? (
                    <EyeClosedIcon
                        data-testid="secret-eye-closed-icon"
                        onClick={() => setShowSecret(false)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                ) : (
                    <EyeOpenIcon
                        data-testid="secret-eye-open-icon"
                        onClick={() => setShowSecret(true)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                )}

                {isSecretCopied ? (
                    <CheckmarkIcon
                        data-testid="secret-checkmark-icon"
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="copied"
                        color="#09AA71"
                        className="absolute pl-1 -ml-9 mt-3"
                    />
                ) : (
                    <CopyIcon
                        data-testid="secret-copy-icon"
                        onClick={handleWebhookSecretCopy}
                        className="absolute pl-1 -ml-9 mt-3 hover:cursor-pointer"
                        color="#007DB2"
                    />
                )}
                <Tooltip
                    isOpen={isSecretCopied}
                    id="copy-tooltip"
                    afterShow={() =>
                        setTimeout(() => {
                            setIsSecretCopied(false);
                        }, 5000)
                    }
                />
            </>
        ),
        [webhookConfig.secret, showSecret, isSecretCopied]
    );

    const webhookSettings = useMemo(
        () => (
            <>
                <div className="flex-col my-3 rounded-md border border-light-m-1">
                    <div className="flex-row justify-between items-center px-5 py-4 color-bg-light-p-1">
                        <p className="heading-one">Webhook settings</p>
                        <ToggleInput
                            onChange={() => setWebhookToggle(!webhookToggle)}
                            name="webhook-status"
                            checked={webhookToggle}
                        />
                    </div>
                    {!webhookToggle ? (
                        <></>
                    ) : (
                        <div className="flex-row justify-between px-5 pt-4 gap-x-4">
                            <LabeledInput
                                className="basis-5/12"
                                name="webhook-secret"
                                label="Webhook Secret"
                                value={
                                    showSecret
                                        ? webhookConfig.secret ?? ""
                                        : "**********************************"
                                }
                                icon={webhookInputIcons}
                                error={
                                    <p className="color-mid-m-1 text-sm font-semibold">
                                        Read{" "}
                                        <Link
                                            to={{
                                                pathname:
                                                    AppRoutes.webhooksDocsPath,
                                            }}
                                        >
                                            Webhooks Setup Documentation
                                        </Link>
                                    </p>
                                }
                            />
                            <LabeledInput
                                className="basis-5/12"
                                name="webhook-url"
                                label="Webhook Url"
                                value={webhookUrl ?? ""}
                                onChange={(e) => setWebhookUrl(e.target.value)}
                                error={webhookError}
                            />
                            <Button
                                appearance="primary"
                                label={webhookLoading ? "Verifying..." : "Save"}
                                className="flex-auto mt-4 basis-2/12"
                                onClick={handleSaveWebhookConfig}
                                disabled={webhookLoading || !webhookUrl}
                            />
                        </div>
                    )}
                </div>
            </>
        ),
        [
            webhookConfig,
            webhookUrl,
            webhookError,
            webhookToggle,
            webhookLoading,
            webhookInputIcons,
        ]
    );

    return (
        <>
            {deactivateWebhookModal(deactivateWebhookConfig)}
            {webhookSettings}
        </>
    );
};

export default WebhookSettings;
