import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Analytics from "main/utils/Analytics";

export default function useAnalytics(): void {
    const location = useLocation();

    useEffect(() => {
        const { title } = document;
        const path = location.pathname;

        Analytics.pageview(title, path);
    }, [location]);
}
