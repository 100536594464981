import { FC, useEffect } from "react";

import { Button } from "components/button/Button";
import LoadingBar from "components/LoadingBar/LoadingBar";
import { useCustomerPlanDetails } from "main/settings/hooks";
import usePageTitle from "main/utils/usePageTitle";
import { useConfirmationModal } from "components/modal/Modal";

export interface PlanTabProps {
    status: string;
    statusMessage: string;
    loading: boolean;
    cancelSubscription: () => void;
    reactivateSubscription: () => void;
    deleteOrganization: () => void;
}

export const DisconnectedPlanTab: FC<PlanTabProps> = (props: PlanTabProps) => {
    usePageTitle("Plan Settings");
    const {
        loading,
        status,
        statusMessage,
        cancelSubscription,
        reactivateSubscription,
        deleteOrganization,
    } = props;

    const cancelModalState = {
        title: "Cancel Plan",
        message:
            "Your plan will be canceled prior to the next current billing cycle.\n(No charge for trial accounts)",
        alert: "Please note: all organization users will lose iVerify access\nand their code will be invalidated at that time.",
        cancelLabel: "Stay Subscribed",
        confirmLabel: "Confirm Cancellation",
    };

    const { showModal: showCancelModal, modal: cancelModal } =
        useConfirmationModal({ ...cancelModalState });

    const deleteModalState = {
        title: "Delete Organization",
        message:
            " Are you sure you want to delete your Organization? This action is permanent.\nThis action will delete all users, members, devices and scan data.",
        alert: "Please note: this takes place immediately and cannot be undone.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Delete Organization Data",
    };

    const { showModal: showDeleteModal, modal: deleteModal } =
        useConfirmationModal({ ...deleteModalState });

    const allowCancel = status && status !== "canceled" && status !== "none";
    const allowReactivation = status === "canceled";

    const DisplayedButton = () => {
        if (status && status !== "invoice") {
            const label = allowCancel
                ? "Cancel Subscription"
                : "Delete Organization";
            const action = allowCancel ? showCancelModal : showDeleteModal;
            const reactivate = allowReactivation ? (
                <Button
                    className="fit-content mrs"
                    appearance="primary"
                    disabled={loading}
                    label="Reactivate Subscription"
                    onClick={reactivateSubscription}
                />
            ) : (
                <></>
            );

            return (
                <div>
                    {reactivate}
                    <Button
                        className="fit-content ivy-button--tertiary-alert"
                        disabled={loading}
                        label={label}
                        onClick={action}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <>
            {cancelModal(cancelSubscription)}
            {deleteModal(deleteOrganization)}
            {loading && <LoadingBar />}
            <div className="plm">
                <div className="flex-col flex-vs ptm">
                    <div className="heading-one pbs">Plan Information</div>
                    <div className="paragraph-three pbm color-dark-2">
                        {statusMessage}
                    </div>
                    <DisplayedButton />
                </div>
            </div>
        </>
    );
};

const PlanTab: FC = () => {
    const {
        status,
        statusMessage,
        loading,
        fetchCustomerPlanDetails,
        cancelSubscription,
        reactivateSubscription,
        deleteOrganization,
    } = useCustomerPlanDetails();

    useEffect(() => {
        fetchCustomerPlanDetails();
    }, [fetchCustomerPlanDetails]);

    return (
        <DisconnectedPlanTab
            loading={loading}
            status={status}
            statusMessage={statusMessage}
            cancelSubscription={cancelSubscription}
            reactivateSubscription={reactivateSubscription}
            deleteOrganization={deleteOrganization}
        />
    );
};

export default PlanTab;
