import { AxiosError } from "axios";
import { useCallback, useState } from "react";
import { apiClient } from "main/utils/ApiClient";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";

type ResetPasswordHook = {
    loading: boolean;
    resetPassword: (email: string) => void;
};

export const useResetPassword = (): ResetPasswordHook => {
    const { loading, execute: resetPasswordCall } = useLoadingCall(
        apiClient.resetPassword
    );

    const resetPassword = useCallback((email: string) => {
        resetPasswordCall(email).catch((e: AxiosError) => {
            handleResponse(e, "Failed to request password reset.");
        });
    }, []);

    return { loading, resetPassword };
};

type PasswordUpdateHook = {
    loading: boolean;
    reset: boolean;
    updatePassword: (requestToken: string, password: string) => void;
};

export const usePasswordUpdate = (): PasswordUpdateHook => {
    const [reset, setReset] = useState<boolean>(false);
    const { loading, execute: updatePasswordCall } = useLoadingCall(
        apiClient.updatePassword
    );

    const updatePassword = useCallback(
        (requestToken: string, password: string) => {
            updatePasswordCall(requestToken, password)
                .then(() => {
                    setReset(true);
                })
                .catch((e: AxiosError) => {
                    handleResponse(e, "Failed to update password.");
                });
        },
        []
    );

    return { loading, reset, updatePassword };
};

export default useResetPassword;
