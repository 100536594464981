import { FC } from "react";

const LinkIcon: FC = () => (
    <svg
        width="42"
        height="56"
        viewBox="0 0 42 42"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42 10.8734V11.6667C42.002 14.1542 41.0109 16.5396 39.2467 18.2934L32.6667 24.8967C31.439 26.1126 29.461 26.1126 28.2333 24.8967L28 24.64C27.5553 24.1864 27.5553 23.4603 28 23.0067L36.0267 14.98C36.8831 14.0918 37.353 12.9004 37.3333 11.6667V10.8734C37.3343 9.62958 36.8388 8.43688 35.9567 7.56002L34.44 6.04335C33.5631 5.16126 32.3704 4.6657 31.1267 4.66668H30.3333C29.0896 4.6657 27.8969 5.16126 27.02 6.04335L18.9933 14C18.5397 14.4447 17.8137 14.4447 17.36 14L17.1033 13.7434C15.8874 12.5157 15.8874 10.5377 17.1033 9.31002L23.7067 2.68335C25.4719 0.944821 27.8559 -0.0205302 30.3333 1.62112e-05H31.1267C33.6028 -0.00193086 35.9782 0.980123 37.73 2.73002L39.27 4.27002C41.0199 6.02186 42.002 8.39725 42 10.8734ZM13.1833 25.5267L25.5267 13.1834C25.7457 12.9625 26.0439 12.8383 26.355 12.8383C26.6661 12.8383 26.9643 12.9625 27.1833 13.1834L28.8167 14.8167C29.0375 15.0357 29.1618 15.3339 29.1618 15.645C29.1618 15.9561 29.0375 16.2543 28.8167 16.4734L16.4733 28.8167C16.2543 29.0375 15.9561 29.1618 15.645 29.1618C15.3339 29.1618 15.0357 29.0375 14.8167 28.8167L13.1833 27.1834C12.9625 26.9643 12.8382 26.6661 12.8382 26.355C12.8382 26.0439 12.9625 25.7457 13.1833 25.5267ZM24.64 28C24.1864 27.5553 23.4603 27.5553 23.0067 28L15.0033 35.9567C14.1208 36.8445 12.9185 37.3406 11.6667 37.3334H10.8733C9.62957 37.3343 8.43686 36.8388 7.56 35.9567L6.04334 34.44C5.16124 33.5632 4.66568 32.3705 4.66667 31.1267V30.3333C4.66568 29.0896 5.16124 27.8969 6.04334 27.02L14.0233 18.9933C14.468 18.5397 14.468 17.8137 14.0233 17.36L13.7667 17.1033C12.539 15.8874 10.561 15.8874 9.33334 17.1033L2.75334 23.7067C0.989152 25.4604 -0.00196815 27.8458 2.93438e-06 30.3333V31.15C0.00423513 33.618 0.985819 35.9839 2.73 37.73L4.27 39.27C6.02184 41.0199 8.39724 42.002 10.8733 42H11.6667C14.1247 42.0144 16.4892 41.0586 18.2467 39.34L24.8967 32.69C26.1126 31.4624 26.1126 29.4843 24.8967 28.2567L24.64 28Z"
        />
    </svg>
);

export default LinkIcon;
