import IVerifyLogo from "icons/ts/iVerify";
import { AppRoutes } from "main/app/App";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

/**
 * The main header for the iVerify site.
 * There are a couple of elements that are unique in the design, and thus have one-off css
 * classes (`.iverify-name`, `.username`, and `.organization-name`)
 */
export const Header: FC = () => {
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        const pageClickEvent = (e: MouseEvent) => {
            if (
                dropdownRef.current !== null &&
                // @ts-ignore
                !dropdownRef.current.contains(e.target)
            ) {
                setShowDropdown(!showDropdown);
            }
        };
        if (showDropdown) {
            window.addEventListener("click", pageClickEvent);
        }

        return () => {
            window.removeEventListener("click", pageClickEvent);
        };
    }, [showDropdown, dropdownRef]);

    return (
        <header className="flex-row flex-vc flex-hb phm color-bg-dark max-h-vh-10">
            <div className="flex-ac">
                <Link to={AppRoutes.dashboardPath}>
                    <IVerifyLogo />
                    <div className="iverify-name">iVerify</div>
                </Link>
            </div>
        </header>
    );
};

export default Header;
