import FileIcon from "icons/ts/FileIcon";
import { AppRoutes } from "main/app/App";
import ConfigurationLayout from "main/ConfigurationLayout";
import Analytics, { Events } from "main/utils/Analytics";
import usePageTitle from "main/utils/usePageTitle";
import { useNavigate } from "react-router-dom";
import ManualAddIcon from "icons/ts/ManualAddIcon";
import { ManagementOptionButton } from "components/ManagementOptionButton/ManagementOptionButton";
import LinkIcon from "icons/ts/LinkIcon";
import { FC } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "main/app/types";

const MemberManagementPage: FC = (): JSX.Element => {
    usePageTitle("Select your Member Management Option");

    const navigate = useNavigate();

    const sso = useSelector(
        (state: ApplicationState) => !!state.session.organization.ssoProvider
    );

    const handleSsoSelected = () => {
        const path = sso
            ? AppRoutes.memberGroupsPath
            : AppRoutes.ssoSelectionPath;
        navigate(path);
    };

    const handleMemberUpload = () => {
        Analytics.event(Events.upload_members_non_sso);
        navigate(AppRoutes.memberUploadPath);
    };

    const handleManualMemberUpload = () => {
        Analytics.event(Events.upload_members_manual);
        navigate(AppRoutes.memberManualUploadPath);
    };

    return (
        <ConfigurationLayout>
            <div className="form-title pbl">Member Management Options</div>
            <div className="flex-row">
                <ManagementOptionButton
                    label="SSO"
                    description="Connect an existing SSO provider for import."
                    icon={<LinkIcon />}
                    onClick={handleSsoSelected}
                />

                <ManagementOptionButton
                    label="CSV"
                    description="Upload a populated CSV file for bulk import."
                    onClick={handleMemberUpload}
                    icon={<FileIcon />}
                />

                <ManagementOptionButton
                    label="Manual"
                    description="Enter individuals manually."
                    icon={<ManualAddIcon />}
                    onClick={handleManualMemberUpload}
                />
            </div>
        </ConfigurationLayout>
    );
};

export default MemberManagementPage;
