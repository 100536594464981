import { AlertsState } from "main/alerts/types";
import { DeviceRow } from "main/dashboard/types";
import React, { HTMLAttributes } from "react";

export interface IconProps extends HTMLAttributes<HTMLOrSVGElement> {
    rotate?: number;
    color?: string;
    body?: React.ReactNode | null;
}

export const enum SeverityType {
    Info = "INFO",
    Warning = "WARNING",
    Error = "ERROR",
}

export enum SeverityLevels {
    SECURE = "secure",
    CRITICAL = "critical",
    WARNING = "warning",
}

export enum PaginationQueryParams {
    PAGE = "page",
    PAGE_SIZE = "pageSize",
}

export enum Filters {
    ENROLLED = "enrolled",
    SEVERITY = "severity",
}

export type TableFilters = {
    enrolled?: EnrolledFilter;
    severity?: SeverityFilter;
};

export enum EnrolledFilter {
    NONE = "none",
    ENROLLED = "true",
    NOT_ENROLLED = "false",
}

export type SeverityFilter = {
    [key in SeverityLevels]: boolean;
};

export interface ApplicationState {
    session: SessionState;
    dashboard: DashboardState;
    alerts: AlertsState;
}

export interface DashboardState {
    paginatedDevices: PaginatedDevices;
    totalPages: number;
}

export interface PaginatedDevices {
    [key: number]: DeviceRow[];
}

export interface SessionState {
    user: UserState;
    organization: OrganizationState;
    portalPreferences: PortalPreferences;
    error?: ErrorState;
}

export interface UserState {
    name: string;
    email: string;
}

export interface OrganizationState {
    name: string;
    slug: string;
    ssoProvider: string;
    redirectUrl: string;
}

export interface PortalPreferences {
    orgId: number;
    apiAccess: boolean;
    threatHunterAccess: boolean;
}

export interface ApiConfiguration {
    token?: string;
    expiresAt?: string;
}

export interface EmailPreferences {
    orgId: number | null;
    weeklySummary: boolean | null;
    threatAlerts: boolean | null;
    enrollmentEmailsFrequency: EnrollmentEmailFrequency | null;
    outdatedOsEmailsFrequency: OutdatedOsEmailsFrequency | null;
}

export enum EnrollmentEmailFrequency {
    ONCE = "ONCE",
    EVERY_SECOND_DAY = "EVERY_SECOND_DAY",
    WEEKLY = "WEEKLY",
    NEVER = "NEVER",
}

export enum OutdatedOsEmailsFrequency {
    DAILY = "DAILY",
    EVERY_SECOND_DAY = "EVERY_SECOND_DAY",
    WEEKLY = "WEEKLY",
    NEVER = "NEVER",
}

export interface EmailTemplate {
    type: EmailTemplateType | undefined;
    subject: string | null;
    body: string | null;
}

export enum EmailTemplateType {
    // enum values should reflect values on the server's CustomEmailTemplate enum
    ORG_ENROLLMENT = "OrgEnrollment",
}

export interface WebhookConfig {
    secret: string | null;
    url: string | null;
}

export interface NotificationPreference {
    notificationType: NotificationType | null;
    topic: InternalNotificationTopic | null;
    deviceIntegrityPeriod: DeviceIntegrityPeriod | null;
    notificationFrequency: NotificationFrequency | null;
}

export enum NotificationType {
    PUSH = "PUSH",
    EMAIL = "EMAIL",
}

export enum InternalNotificationTopic {
    // enum values should reflect values on the server's InternalNotificationTopic enum
    OPEN_APP_TO_SCAN = "OpenAppToScan",
}

export enum DeviceIntegrityPeriod {
    THREE_DAYS = "THREE_DAYS",
    SEVEN_DAYS = "SEVEN_DAYS",
    TWO_WEEKS = "TWO_WEEKS",
    NEVER = "NEVER",
}

export enum NotificationFrequency {
    DAILY = "DAILY",
    EVERY_SECOND_DAY = "EVERY_SECOND_DAY",
    WEEKLY = "WEEKLY",
}

export interface ErrorState {
    type: string;
    severity?: SeverityType;
    message: string;
    description: string;
}

export enum ErrorType {
    UPDATE_PAYMENT = "update-payment",
    UPDATE_BILLING = "update-billing",
    ORG_CREATION_ERROR = "org-creation-error",
    OAUTH_ACCESS_DENIED = "oauth-access-denied",
    OAUTH_SERVER_ERROR = "oauth-server-error",
    OAUTH_UNAVAILABLE = "oauth-unavailable",
    OAUTH_UNAUTHORIZED = "oauth-unauthorized",
    SSO_OAUTH_ERROR = "sso-oauth-error",
    SSO_UNAUTHORIZED = "sso-unauthorized",
}

export const serverErrors = {
    [ErrorType.UPDATE_PAYMENT]: {
        message: "No payment method stored",
        description: "Add a payment method to continue.",
    },
    [ErrorType.UPDATE_BILLING]: {
        message: "Your account is currently inactive",
        description:
            "Your subscription was canceled. Reactivate your subscription to continue.",
    },
    [ErrorType.ORG_CREATION_ERROR]: {
        message: "Failed to create Organization",
        description:
            "Please try again. If the problem persists, contact support.",
    },
    [ErrorType.OAUTH_UNAUTHORIZED]: {
        message: "Failed to authenticate with your identity provider",
        description:
            "Please try again. If the problem persists, contact support.",
    },
    [ErrorType.OAUTH_SERVER_ERROR]: {
        message: "The SSO provider server is temporarily unavailable.",
        description:
            "Please try again after some time. If the problem persists, contact support.",
    },
    [ErrorType.OAUTH_UNAVAILABLE]: {
        message: "Failed to authenticate with your identity provider",
        description:
            "Please try again. If the problem persists, contact support.",
    },
    [ErrorType.OAUTH_ACCESS_DENIED]: {
        message: "The SSO provider denied the request",
        description:
            "Please try again. If the problem persists, contact support.",
    },
    [ErrorType.SSO_OAUTH_ERROR]: {
        message: "The SSO provider responded with an error",
        description:
            "Please try again. If the problem persists, contact support.",
    },
    [ErrorType.SSO_UNAUTHORIZED]: {
        message: "You are not authorized to access this organization",
        description: "Please contact your organization administrator.",
    },
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 50;
export const MAXIMUM_PAGE_SIZE = 100;

export enum ApiTokenExpiration {
    SIX_MONTHS = "SIX_MONTHS",
    ONE_YEAR = "ONE_YEAR",
    NEVER = "NEVER",
}

export interface ConditonalAccessConfig {
    tenant: string | null;
    clientId: string | null;
    clientSecret: string | null;
    userExtension: string | null;
    groupId: string | null;
    provider: ConditionalAccessProvider | null;
    active: boolean;
}

export enum ConditionalAccessProvider {
    OKTA = "OKTA",
    AZURE = "AZURE",
}

export enum AlertScanSeverityPolicy {
    CRITICAL_ONLY = "CRITICAL_ONLY",
    HIGH_AND_CRITICAL = "HIGH_AND_CRITICAL",
    NONE = "NONE",
}

export enum OutdatedOSPeriodPolicy {
    THREE_DAYS = "THREE_DAYS",
    SEVEN_DAYS = "SEVEN_DAYS",
    TWO_WEEKS = "TWO_WEEKS",
    NEVER = "NEVER",
}

export enum DeviceIntegrityPeriodPolicy {
    THREE_DAYS = "THREE_DAYS",
    SEVEN_DAYS = "SEVEN_DAYS",
    TWO_WEEKS = "TWO_WEEKS",
    NEVER = "NEVER",
}

export enum ScreenLockEnabledPolicy {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED",
}

export interface ConditionalAccessPolicy {
    alertSeverity: AlertScanSeverityPolicy;
    outdatedOSPeriod: OutdatedOSPeriodPolicy;
    deviceIntegrityPeriod: DeviceIntegrityPeriodPolicy;
    screenLockEnabled: boolean;
}
