import { DragEvent, ChangeEvent, FC, useRef, useState } from "react";
import usePageTitle from "main/utils/usePageTitle";
import Analytics, { Events } from "main/utils/Analytics";
import { PostUploadIcon, PreUploadIcon } from "icons/ts/UploadFileIcon";
import { Button } from "components/button/Button";
import { useParseCsvFile } from "main/add-members/hooks";

export type MemberFile = File | null | undefined;

export const MemberUploadForm: FC = (): JSX.Element => {
    usePageTitle("Import Your Members to Enroll");

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<MemberFile>(null);
    const { loading, setCsvFile } = useParseCsvFile();

    const preventDefault = (e: DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
    };

    const selectFile = (selectedFile: MemberFile) => {
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        Analytics.event(Events.upload_members_file_select);
        selectFile(fileInputRef.current?.files?.item(0));
    };

    const handleFileDrop = (e: DragEvent<HTMLLabelElement>) => {
        e.preventDefault();
        Analytics.event(Events.upload_members_file_drop);
        selectFile(e.dataTransfer.files?.item(0));
    };

    const uploadClicked = () => {
        Analytics.event(Events.upload_csv_button);
        if (file !== undefined && file !== null) {
            setCsvFile(file);
        }
    };

    const DisplayedFilename = () => (
        <p
            className={`heading-five text-center mts ${
                file ? "color-dark-p-2" : "color-mid-p-1"
            }`}
        >
            {file?.name ?? "No File Added"}
        </p>
    );

    const TemplateLink = () => (
        <a
            className="color-action-lt mbl"
            href="data:text/csv;charset=UTF-8,Full%20Name%2CEmail%0AJohn%20Smith%2Cperson%40yourOrganization.com"
            download="member_template.csv"
            onClick={() => Analytics.event(Events.download_csv_template)}
        >
            Example Template
        </a>
    );

    return (
        <>
            <div className="special-case-title color-action-lt proper-case mtxl mbm">
                Add Team Members
            </div>

            <div className="title-three flex-row mbs">
                <div className="mhs">1. Upload CSV</div>
                <div className="mhs color-mid-p-1">2. Send Invitations</div>
            </div>

            <p className="paragraph-one text-center mbs">
                You will be able to confirm data before sending invitations.
            </p>

            <div className="paragraph-three">
                CSV must include ‘fullname’ and ‘email’ columns.
            </div>

            <TemplateLink />

            <label
                className="flex-col flex-hc"
                onDragOver={preventDefault}
                onDragEnter={preventDefault}
                onDragLeave={preventDefault}
                onDrop={handleFileDrop}
            >
                {file ? <PostUploadIcon /> : <PreUploadIcon />}

                <DisplayedFilename />

                <input
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleFileSelect}
                    type="file"
                    accept="text/csv,.csv"
                />
            </label>

            <Button
                className="flex-vc mts"
                disabled={loading || !file}
                label={loading ? "Uploading..." : "Upload"}
                onClick={uploadClicked}
            />
        </>
    );
};

const MemberUploadPage: FC = () => (
    <div className="flex-col flex-vc">
        <MemberUploadForm />
    </div>
);

export default MemberUploadPage;
