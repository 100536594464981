/* eslint-disable react/jsx-props-no-spreading */
import { Cell, UseRowSelectInstanceProps } from "react-table";
import TableCheckbox from "main/dashboard/components/TableCheckbox";
import Badge from "components/Badge";
import InfoIcon from "icons/ts/InfoIcon";
import {
    AlertRow,
    AlertStatus,
    SeverityLevel,
    TableColumn,
    TableRowJSX,
    AlertDescription,
} from "./types";

const customSortFunction = (
    rowA: TableRowJSX,
    rowB: TableRowJSX,
    columnId: string
): number => {
    const valueA = rowA?.values[columnId]?.props["data-sort"];
    const valueB = rowB?.values[columnId]?.props["data-sort"];

    if (valueA < valueB) {
        return -1;
    }
    if (valueA > valueB) {
        return 1;
    }
    return 0;
};

const severity = (alert: AlertRow): JSX.Element => {
    const severityLevel =
        SeverityLevel[alert.severity as keyof typeof SeverityLevel];

    const severityColorMap = {
        [SeverityLevel.CRITICAL]: "color-bg-critical-lt",
        [SeverityLevel.HIGH]: "color-bg-critical-dt",
        [SeverityLevel.SUSPICIOUS]: "color-bg-action-m-2-lt",
        [SeverityLevel.LOW]: "color-bg-action-lt",
        DEFAULT: "color-bg-dark-p-1",
    };

    const color = severityColorMap[severityLevel] || severityColorMap.DEFAULT;

    return (
        <Badge data-sort={alert.severity} text={severityLevel} color={color} />
    );
};

export const alertDescriptionTooltipMessage = (alert: AlertRow): string => {
    const parsedDescription = JSON.parse(
        alert.alertDescription
    ) as AlertDescription[];
    const descriptions = Array.isArray(parsedDescription)
        ? parsedDescription
            .map(
                (alertDescription: AlertDescription) =>
                    `<li>${alertDescription.threat}</li>`
            )
            .join("")
        : "";
    return `<p>Threat details:</p><ul class="list-disc pl-3">${descriptions}</ul>`;
};

const alertName = (alert: AlertRow): JSX.Element => (
    <span data-sort={alert.alertName} className="flex-row items-center pr-4">
        <span
            data-tooltip-id="table-cell-tooltip"
            data-tooltip-html={
                alert.alertDescription.length
                    ? alertDescriptionTooltipMessage(alert)
                    : null
            }
        >
            <InfoIcon color="#004F70" className="-mb-0.5" />
        </span>
        {alert.alertName}
    </span>
);

const scanTime = (alert: AlertRow): string => {
    const date = new Date(alert.scanTime);
    const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "numeric",
    };

    return date.toLocaleDateString("en-US", options);
};

const deviceName = (alert: AlertRow): string => {
    if (alert.memberName === null) {
        return "Unknown";
    }
    return `${alert.memberName} ${alert.device}`;
};

const alertsTableColumns: Array<TableColumn> = [
    {
        accessor: "selection",
        id: "selection",
        Header({
            getToggleAllRowsSelectedProps,
        }: UseRowSelectInstanceProps<AlertRow>): JSX.Element {
            return (
                <TableCheckbox
                    data-testid="all-row-checkbox"
                    {...getToggleAllRowsSelectedProps()}
                />
            );
        },
        Cell: ({ row }: Cell<AlertRow>): JSX.Element => (
            <TableCheckbox
                data-testid={`${row.id}-row-checkbox`}
                {...row.getToggleRowSelectedProps()}
            />
        ),
        disableSortBy: true,
        canSort: false,
    },
    {
        accessor: (alert: AlertRow): JSX.Element => alertName(alert),
        id: "alertName",
        Header: "Name",
    },
    {
        accessor: (alert: AlertRow): JSX.Element => severity(alert),
        id: "severity",
        Header: "Severity",
        sortType: customSortFunction,
    },
    {
        accessor: (alert: AlertRow): string =>
            AlertStatus[alert.alertStatus as keyof typeof AlertStatus],
        id: "alertStatus",
        Header: "Status",
    },
    {
        accessor: (alert: AlertRow): string => deviceName(alert),
        id: "device",
        Header: "Device",
    },
    {
        accessor: (alert: AlertRow): string => scanTime(alert),
        id: "scanTime",
        Header: "Scan Time",
    },
];

export default alertsTableColumns;
