import { Link } from "react-router-dom";

import TitleLayout from "main/layouts/TitleLayout";
import { AppRoutes } from "main/app/App";
import { FC, PropsWithChildren } from "react";

const PasswordPage: FC<PropsWithChildren<unknown>> = ({
    children,
}: PropsWithChildren<unknown>) => (
    <TitleLayout>
        {children}
        <div className="mtxl">
            <Link to={AppRoutes.loginPath}>Back to Sign In</Link>
        </div>
    </TitleLayout>
);

export default PasswordPage;
