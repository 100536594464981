import { FC, FormEvent } from "react";
import { Button } from "components/button/Button";
import { LabeledInput } from "components/input/Input";
import { SsoConfigFormProps } from "components/SsoConfigForm/types";
import { useSSO } from "components/SsoConfigForm/hooks";
import { useForm } from "components/form/useForm";
import { Link } from "components/Link";

export const SsoConfigForm: FC<SsoConfigFormProps> = (
    props: SsoConfigFormProps
) => {
    const { provider, slug, redirectUrl } = props;
    const { inputs, onChange, filled } = useForm({
        clientId: "",
        clientSecret: "",
        tenant: "",
    });

    const { loading, createSSO } = useSSO();

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        const { tenant, clientId, clientSecret } = inputs;
        createSSO({
            clientId: clientId as string,
            clientSecret: clientSecret as string,
            provider,
            slug,
            tenant: tenant as string,
        });
    };

    const tenantLabel = provider === "google" ? "DOMAIN" : "TENANT";
    const tenantPlaceholder =
        provider === "google"
            ? "Domain (e.g. iverify.io)"
            : "Tenant (full path)";

    const ssoSetupDocs = `https://docs.iverify.io/sso-${provider}`;

    return (
        <div className="flex-col">
            <form
                onSubmit={handleSubmit}
                onChange={onChange}
                className="width-sso-form"
                autoComplete="off"
            >
                <LabeledInput
                    label="Redirect URL"
                    name="redirectUrl"
                    value={redirectUrl}
                    disabled
                />

                <p className="paragraph-two mbs">
                    Provide the following integration details.
                </p>

                <LabeledInput
                    placeholder="Client ID"
                    label="Client ID"
                    name="clientId"
                />

                <LabeledInput
                    label="Client Secret"
                    name="clientSecret"
                    placeholder="Client Secret (all characters)"
                />

                <LabeledInput
                    label={tenantLabel}
                    name="tenant"
                    placeholder={tenantPlaceholder}
                />

                <Button
                    className="max-width mvs"
                    disabled={loading || !filled}
                    label={loading ? "Submitting..." : "Apply Settings"}
                />
            </form>

            <p className="paragraph-two mtm mbl text-center">
                Need help?
                <br />
                Please reference&nbsp;
                <Link to={{ pathname: ssoSetupDocs }}>
                    SSO Integration Setup Documentation
                </Link>
            </p>
        </div>
    );
};

export default SsoConfigForm;
