import {
    MutableRefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { apiClient } from "main/utils/ApiClient";
import LoadingBar from "components/LoadingBar/LoadingBar";
import { Button } from "components/button/Button";
import { LabeledInput } from "components/input/Input";
import { displaySuccessBanner } from "main/utils/displayBanner";
import handleResponse from "main/utils/HandleResponse";
import { useLoadingCall } from "main/utils/UseLoadingCall";
import { useConfirmationModal } from "components/modal/Modal";
import usePageTitle from "main/utils/usePageTitle";
import Analytics, { Events } from "main/utils/Analytics";
import { Link } from "components/Link";
import CheckmarkIcon from "icons/ts/CheckmarkIcon";
import CopyIcon from "icons/ts/CopyIcon";
import { Tooltip } from "react-tooltip";
import EyeOpenIcon from "icons/ts/EyeOpenIcon";
import EyeClosedIcon from "icons/ts/EyeClosedIcon";
import { AppRoutes } from "main/app/App";

export interface MdmTabProps {
    loading: boolean;
    mdmConfig: MdmConfiguration;
    updateMdmConfig: (enable: boolean) => void;
}

export interface MdmConfiguration {
    enabled?: boolean;
    token?: string;
}

export type MdmSettingsHook = {
    loading: boolean;
    mdmConfig: MdmConfiguration;
    fetchMdmConfig: () => void;
    updateMdmConfig: (enabled: boolean) => void;
};

export const useMdmConfig = (): MdmSettingsHook => {
    const [mdmConfig, setMdmConfig] = useState<MdmConfiguration>({});

    const { loading: fetchLoading, execute: fetch } = useLoadingCall(
        apiClient.getMdmConfig
    );

    const fetchMdmConfig = useCallback(() => {
        fetch()
            .then((config: MdmConfiguration) => {
                setMdmConfig(config);
            })
            .catch((e) => {
                handleResponse(e, "Failed to get MDM settings.");
            });
    }, [fetch]);

    const { loading: updateLoading, execute: update } = useLoadingCall(
        apiClient.updateMdmConfig
    );

    const updateMdmConfig = useCallback(
        (enable: boolean) => {
            update(enable)
                .then((config: MdmConfiguration) => {
                    setMdmConfig(config);
                    displaySuccessBanner(
                        "Successfully updated MDM configuration"
                    );
                })
                .catch((e) => {
                    handleResponse(e, "Failed to update MDM configuration.");
                });
        },
        [update]
    );

    return {
        loading: fetchLoading || updateLoading,
        mdmConfig,
        fetchMdmConfig,
        updateMdmConfig,
    };
};

export const DisconnectedMdmTab: React.FC<MdmTabProps> = (
    props: MdmTabProps
) => {
    const [isTokenCopied, setIsTokenCopied] = useState<boolean>(false);
    const [showToken, setShowToken] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(
        null
    ) as MutableRefObject<HTMLInputElement>;
    usePageTitle("MDM Settings");

    const { loading, updateMdmConfig, mdmConfig } = props;
    const { enabled, token } = mdmConfig ?? {};

    useEffect(() => {
        const handleDoubleClick = () => {
            inputRef.current.setSelectionRange(
                0,
                inputRef.current.value.length
            );
        };

        if (inputRef.current) {
            inputRef.current.addEventListener("dblclick", handleDoubleClick);
        }

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener(
                    "dblclick",
                    handleDoubleClick
                );
            }
        };
    }, [inputRef, token]);

    const modalProps = {
        title: "Disable MDM",
        message:
            "Are you sure you want to disable MDM?\nAll current members will be retained.",
        cancelLabel: "Cancel",
        confirmLabel: "Yes, Disable",
    };

    const { showModal, modal } = useConfirmationModal({ ...modalProps });
    const enableMdm = () => {
        updateMdmConfig(true);
        setShowToken(true);
        Analytics.event(Events.enable_mdm);
    };
    const disableMdm = () => {
        updateMdmConfig(false);
        Analytics.event(Events.disable_mdm);
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(token as string);
        setIsTokenCopied(true);
    };

    const inputIcons = useMemo(
        () => (
            <>
                {showToken ? (
                    <EyeClosedIcon
                        data-testid="eye-closed-icon"
                        onClick={() => setShowToken(false)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                ) : (
                    <EyeOpenIcon
                        data-testid="eye-open-icon"
                        onClick={() => setShowToken(true)}
                        className="absolute pl-1 -ml-[4.5rem] mt-3 hover:cursor-pointer"
                    />
                )}

                {isTokenCopied ? (
                    <CheckmarkIcon
                        data-testid="checkmark-icon"
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="copied"
                        color="#09AA71"
                        className="absolute pl-1 -ml-9 mt-3"
                    />
                ) : (
                    <CopyIcon
                        data-testid="copy-icon"
                        onClick={handleCopy}
                        className="absolute pl-1 -ml-9 mt-3 hover:cursor-pointer"
                        color="#007DB2"
                    />
                )}
                <Tooltip
                    isOpen={isTokenCopied}
                    id="copy-tooltip"
                    afterShow={() =>
                        setTimeout(() => {
                            setIsTokenCopied(false);
                        }, 5000)
                    }
                />
            </>
        ),
        [token, showToken, isTokenCopied]
    );

    const Body = () => {
        if (enabled === undefined) {
            return <></>;
        }

        if (enabled) {
            return (
                <>
                    <LabeledInput
                        ref={inputRef}
                        name="mdm-token"
                        label="MDM Token"
                        value={
                            showToken
                                ? token
                                : "**********************************"
                        }
                        icon={inputIcons}
                    />

                    <Button
                        appearance="alert"
                        label={loading ? "Disabling..." : "Disable MDM"}
                        onClick={showModal}
                        disabled={loading}
                    />
                </>
            );
        }

        return (
            <>
                <Button
                    label={loading ? "Enabling..." : "Enable MDM"}
                    onClick={enableMdm}
                    disabled={loading}
                />
            </>
        );
    };

    return (
        <>
            {modal(disableMdm)}
            {loading && <LoadingBar />}
            <div className="plm">
                <div className="flex-col flex-vs ptm width-medium">
                    <div className="heading-one pbm">MDM Configuration</div>
                    <Body />
                    <p className="paragraph-two mtm mbl text-center">
                        Need help?
                        <br />
                        Please reference&nbsp;
                        <Link to={{ pathname: AppRoutes.mdmSetupDocsPath }}>
                            MDM Setup Documentation
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
};

const MdmTab: React.FC = () => {
    const { loading, mdmConfig, fetchMdmConfig, updateMdmConfig } =
        useMdmConfig();

    useEffect(() => {
        fetchMdmConfig();
    }, [fetchMdmConfig]);

    return (
        <>
            <DisconnectedMdmTab
                loading={loading}
                mdmConfig={mdmConfig}
                updateMdmConfig={updateMdmConfig}
            />
        </>
    );
};

export default MdmTab;
