import { AxiosError } from "axios";
import { useState } from "react";
import { setRedirectToAddMembers } from "main/app/AppCookies";
import { apiClient } from "main/utils/ApiClient";
import { SsoConfigurationRequest } from "components/SsoConfigForm/types";
import handleResponse from "main/utils/HandleResponse";

type SsoHook = {
    loading: boolean;
    createSSO: (request: SsoConfigurationRequest) => void;
};

export const useSSO = (): SsoHook => {
    const [loading, setLoading] = useState<boolean>(false);

    const createSSO = (request: SsoConfigurationRequest) => {
        if (loading) {
            return;
        }

        setLoading(true);
        apiClient
            .sso(request)
            .then(() => {
                const { slug } = request;
                setRedirectToAddMembers(true);
                window.location.href = `${window.location.origin}/oauth/login?slug=${slug}`;
            })
            .catch((e: AxiosError) => {
                handleResponse(e, "Problem with SSO integration");
                setRedirectToAddMembers(false);
                setLoading(false);
            });
    };
    return { loading, createSSO };
};

export default useSSO;
