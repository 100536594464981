import { ApplicationState } from "main/app/types";

export const initialState: ApplicationState = {
    session: {
        user: {
            name: "",
            email: "",
        },
        organization: {
            name: "",
            slug: "",
            ssoProvider: "",
            redirectUrl: "",
        },
        portalPreferences: {
            orgId: 0,
            apiAccess: false,
            threatHunterAccess: false,
        },
    },
    dashboard: {
        paginatedDevices: {},
        totalPages: 0,
    },
    alerts: {
        paginatedAlerts: {},
        totalPages: 0,
    },
};

export default initialState;
