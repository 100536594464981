/* eslint-disable import/no-relative-parent-imports */
import { Button, PageButton } from "components/button/Button";
import ChevronIcon from "icons/ts/Chevron";
import { FC } from "react";
import { usePagination } from "../hooks";

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    paginate: (pageIndex: number) => void;
}

const Pagination: FC<PaginationProps> = (props: PaginationProps) => {
    const { currentPage, totalPages, paginate } = props;
    const { paginationRange } = usePagination({ currentPage, totalPages });

    return (
        <>
            <span className="lg:w-8 lg:shrink-0">
                <Button
                    appearance="icon"
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    <ChevronIcon rotate={90} color="#007db2" />
                </Button>
            </span>

            <ul
                className={`col-span-2 justify-self-center grid grid-cols-${paginationRange.length} gap-x-1`}
            >
                {paginationRange.map((page) => (
                    <li className="text-center">
                        {page === "..." ? (
                            <span>...</span>
                        ) : (
                            <PageButton
                                key={page}
                                currentPage={currentPage}
                                page={page as number}
                                paginate={paginate}
                            >
                                {page}
                            </PageButton>
                        )}
                    </li>
                ))}
            </ul>

            <span className="lg:w-8 lg:shrink-0">
                <Button
                    appearance="icon"
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <ChevronIcon rotate={-90} color="#007db2" />
                </Button>
            </span>
        </>
    );
};

export default Pagination;
