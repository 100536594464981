import { HeaderGroup } from "react-table";
import TableLoadingIndicator from "main/dashboard/components/TableLoadingIndicator";
import sortingArrow from "main/dashboard/components/sortingArrow";
import { AlertRow } from "./types";

const getHeaderCell = (headerGroup: HeaderGroup<AlertRow>): JSX.Element => {
    const {
        id,
        canSort,
        isSorted,
        isSortedDesc,
        getHeaderProps,
        getSortByToggleProps,
        render,
    } = headerGroup;

    // @ts-ignore
    const { key, onClick } = getHeaderProps(getSortByToggleProps());

    return (
        <th className={`${id} border-y-0`} key={key} onClick={onClick}>
            <div className="flex flex-row">
                <>
                    <span>
                        <>{render("Header")}</>
                    </span>
                    {canSort && sortingArrow(isSorted, isSortedDesc)}
                </>
            </div>
        </th>
    );
};

const getTableHeader = (
    headerGroups: Array<HeaderGroup<AlertRow>>,
    loading: boolean
): JSX.Element => (
    <thead className="color-bg-light-m-1 sticky -top-[0.1px] z-10">
        {headerGroups.map((headers) => {
            const { key } = headers.getHeaderGroupProps();
            return (
                <>
                    <tr key={key}>
                        {headers.headers.map((cell: HeaderGroup<AlertRow>) =>
                            getHeaderCell(cell)
                        )}
                    </tr>
                    <TableLoadingIndicator loading={loading} />
                </>
            );
        })}
    </thead>
);

export default getTableHeader;
