/* eslint-disable react/no-array-index-key */
import LoadingBar from "components/LoadingBar/LoadingBar";
import { useAddAdmin, useOrgAdmin, useResendInvite } from "main/settings/hooks";
import { User } from "main/settings/types";
import usePageTitle from "main/utils/usePageTitle";
import { FC, FormEvent, MouseEvent, useEffect, useRef, useState } from "react";
import useEmailInput from "components/EmailInput/EmailInput";
import { LabeledInput } from "components/input/Input";
import AddIcon from "icons/ts/AddIcon";
import { useForm } from "components/form/useForm";
import { useSelector } from "react-redux";
import { ApplicationState } from "main/app/types";
import { Button } from "components/button/Button";
import Analytics, { Events } from "main/utils/Analytics";
import moment, { now } from "moment/moment";

export const SECONDS_TO_ACCEPT_INVITE = 60 * 60 * 24; // seconds * minutes * hours * days

export interface AdminTabProps {
    admins: User[];
    ssoEnabled: boolean;
    loading: boolean;
}

export const DisconnectedAdminTab: FC<AdminTabProps> = (
    props: AdminTabProps
) => {
    usePageTitle("Settings Admin Tab");
    const { admins: adminProps, ssoEnabled, loading } = props;
    const [admins, updateAdmins] = useState<User[]>([]);

    useEffect(() => {
        updateAdmins(adminProps);
    }, [adminProps, updateAdmins]);

    const { inputs, onChange, filled, valid, setValid, reset } = useForm({
        name: "",
        email: "",
    });

    const { emailInput, error: emailError } = useEmailInput();

    const { loading: addAdminsLoading, addAdmin } = useAddAdmin();
    const addAdminFormRef = useRef<HTMLFormElement>(null);
    const { loading: resendAdminInviteLoading, resendInvite } =
        useResendInvite();

    const onAddSuccess = (name: string, email: string) => {
        const inviteAccepted = false;
        const inviteSentDate = moment().toISOString();
        updateAdmins((prev) => [
            ...prev,
            { name, email, inviteAccepted, inviteSentDate },
        ]);
    };

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        Analytics.event(Events.add_additional_admin);

        const name = inputs.name as string;
        const email = inputs.email as string;
        addAdmin(name, email, onAddSuccess);
        reset();

        addAdminFormRef.current?.reset();
    };

    const resendClicked = (e: MouseEvent, name: string, email: string) => {
        e.preventDefault();
        Analytics.event(Events.resend_invitation);

        resendInvite(name, email, (_a: string, _b: string) => {});
    };

    const resendButton = (user: User): JSX.Element => {
        const button = (
            <Button
                disabled={resendAdminInviteLoading}
                appearance="link"
                className="color-action-lt rubik flex-row flex-ha"
                aria-label="resend-invite-button"
                style={{ marginBottom: 12 }}
                onClick={(e) => resendClicked(e, user.name, user.email)}
                label="Resend"
            />
        );

        if (user.inviteAccepted) {
            return <></>;
        }

        if (user.inviteSentDate == null || user.inviteSentDate === "") {
            return <>{button}</>;
        }

        const inviteSentMoment = moment(user.inviteSentDate);
        const inviteSentAgo = moment.duration(inviteSentMoment.diff(now()));
        const secondsAgo = inviteSentAgo.asSeconds();
        if (secondsAgo <= 0 && -1 * secondsAgo < SECONDS_TO_ACCEPT_INVITE) {
            const prefix = "Invite was sent ";
            return (
                <>
                    <p
                        aria-label="invite-sent-label"
                        className="paragraph-five"
                        style={{ marginBottom: 12 }}
                    >
                        {prefix + inviteSentMoment.fromNow()}
                    </p>
                </>
            );
        }

        return <>{button}</>;
    };

    useEffect(() => {
        setValid(filled && emailError === "");
    }, [filled, emailError]);

    const addAdminForm = (
        <div className="plm">
            <div className="flex-col flex-vs ptm">
                <div className="heading-four pbs">ADD ADMINS</div>
                <form
                    data-testid="add-admin-form"
                    onSubmit={handleSubmit}
                    onInput={onChange}
                    className="width-medium"
                    ref={addAdminFormRef}
                >
                    <div className="flex-row items-center">
                        <LabeledInput
                            placeholder="Full Name"
                            label="Full Name"
                            name="name"
                            type="text"
                            className="mrs flex-2"
                        />
                        <div className="flex-3 mrs">{emailInput}</div>
                        <Button
                            disabled={!valid || addAdminsLoading}
                            name="add-admin"
                            className="ivy-button--icon pbxs hover:border-none focus:border-none"
                            aria-label="Add"
                        >
                            <AddIcon />
                        </Button>
                    </div>
                </form>

                <p>
                    If you need to remove an admin user, please email us
                    at&nbsp;
                    <a href="mailto:support@iverify.io">support@iverify.io</a>
                </p>
            </div>
        </div>
    );

    return (
        <>
            {loading && <LoadingBar />}
            <div className="plm">
                <div className="flex-col flex-vs ptm">
                    <div className="heading-one pbs">Administrators</div>
                    {admins.map((user: User, index: number) => (
                        <div
                            className="flex-row width-large"
                            key={`admin_user_${index}`}
                        >
                            <div
                                data-testid={`username_${index}`}
                                className="paragraph-one color-black prm flex-1"
                            >
                                {user.name}
                            </div>
                            <div
                                data-testid={`email_${index}`}
                                className={
                                    !ssoEnabled && !user.inviteAccepted
                                        ? "paragraph-one flex-2 color-mid-m-1"
                                        : "paragraph-one flex-2 color-black"
                                }
                            >
                                {user.email}
                                {!ssoEnabled && resendButton(user)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {addAdminForm}
        </>
    );
};

const AdminTab: FC = () => {
    const session = useSelector((state: ApplicationState) => state.session);
    const { admins, loading, fetchOrgAdmins } = useOrgAdmin();

    useEffect(() => {
        fetchOrgAdmins();
    }, [fetchOrgAdmins]);

    return (
        <DisconnectedAdminTab
            admins={admins}
            ssoEnabled={!!session.organization.ssoProvider}
            loading={loading}
        />
    );
};

export default AdminTab;
