/* eslint-disable class-methods-use-this */
import {
    AnalyticsEngine,
    Parameters,
    Event,
    Events,
} from "main/utils/AnalyticsEngine";
import env from "env";

export { Events };

class GoogleAnalytics implements AnalyticsEngine {
    load() {
        const head: HTMLHeadElement = document.getElementsByTagName("head")[0];

        const gtagScript = document.createElement("script");
        gtagScript.async = true;
        gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${env.GA_ID}`;
        head.appendChild(gtagScript);

        const gtagInitScript = document.createElement("script");
        gtagInitScript.innerHTML = `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${env.GA_ID}', {
                    send_page_view: false
                });`.trim();
        head.appendChild(gtagInitScript);
    }

    pageview(title: string, path: string): void {
        gtag("event", "page_view", {
            page_title: title,
            page_path: path.replaceAll(
                /update-password\/.*/g,
                "update-password/"
            ),
        });
    }

    event(event: Event, parameters?: Parameters): void {
        gtag("event", event, parameters);
    }
}

const Analytics: AnalyticsEngine = new GoogleAnalytics();

export default Analytics;
