import { IconProps } from "main/app/types";
import { FC } from "react";

const SuccessIcon: FC<IconProps> = (props) => (
    <svg width="18" height="18" fill="none" {...props}>
        <path
            fill="#40C7A5"
            fillRule="evenodd"
            d="M9 .667a8.333 8.333 0 1 0 0 16.666A8.333 8.333 0 0 0 9 .667Zm0 15A6.667 6.667 0 1 1 9 2.332a6.667 6.667 0 0 1 0 13.333Zm2.6-9.592a.417.417 0 0 1 .583 0l.417.442a.417.417 0 0 1 0 .591l-4.683 4.683a.4.4 0 0 1-.584 0L5.375 9.817a.417.417 0 0 1 0-.592l.442-.442a.4.4 0 0 1 .583 0l1.25 1.242 3.95-3.95Z"
            clipRule="evenodd"
        />
    </svg>
);

export default SuccessIcon;
