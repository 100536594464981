import { FC } from "react";

const DeleteIcon: FC = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            clipRule="evenodd"
            d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 7.34784 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18ZM12.56 6.91L13.09 7.44C13.1867 7.53073 13.2415 7.65741 13.2415 7.79C13.2415 7.92259 13.1867 8.04927 13.09 8.14L11.24 10L13.09 11.86C13.1867 11.9507 13.2415 12.0774 13.2415 12.21C13.2415 12.3426 13.1867 12.4693 13.09 12.56L12.56 13.09C12.4693 13.1867 12.3426 13.2415 12.21 13.2415C12.0774 13.2415 11.9507 13.1867 11.86 13.09L10 11.24L8.14 13.09C8.04927 13.1867 7.92259 13.2415 7.79 13.2415C7.65741 13.2415 7.53073 13.1867 7.44 13.09L6.91 12.56C6.81332 12.4693 6.75848 12.3426 6.75848 12.21C6.75848 12.0774 6.81332 11.9507 6.91 11.86L8.76 10L6.91 8.14C6.81332 8.04927 6.75848 7.92259 6.75848 7.79C6.75848 7.65741 6.81332 7.53073 6.91 7.44L7.44 6.91C7.53073 6.81332 7.65741 6.75848 7.79 6.75848C7.92259 6.75848 8.04927 6.81332 8.14 6.91L10 8.76L11.86 6.91C11.9507 6.81332 12.0774 6.75848 12.21 6.75848C12.3426 6.75848 12.4693 6.81332 12.56 6.91Z"
            fill="#B02145"
        />
    </svg>
);

export default DeleteIcon;
