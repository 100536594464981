import { ButtonHTMLAttributes, FC, ReactChild } from "react";

import { Button } from "components/button/Button";

export interface SsoButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    icon: ReactChild;
    label: string;
}

export const SsoButton: FC<SsoButtonProps> = ({
    label,
    icon,
    ...props
}: SsoButtonProps) => (
    <Button appearance="jumbo" className="mhm" name={label} {...props}>
        <div className="flex-col flex-vc">
            {icon}
            <div className="heading-one rubik mvxs proper-case">{label}</div>
        </div>
    </Button>
);
