/* eslint-disable */

import { Link as DomLink, LinkProps } from "react-router-dom";
import { Event as IvyEvent, Parameters } from "main/utils/AnalyticsEngine";
import Analytics from "main/utils/Analytics";
import { LocationDescriptorObject } from "history";
import { FC, MouseEventHandler } from "react";

interface IvyLinkProps extends LinkProps {
    event?: IvyEvent | { event: IvyEvent; parameters: Parameters };
    parameters?: Parameters;
    newWindow?: Boolean;
}

const onMailToClick = (url: string) => {
    const mailToOnClick: MouseEventHandler = (e) => {
        e.preventDefault();

        window.location.href = String(url);
    };

    return mailToOnClick;
};

export const Link: FC<IvyLinkProps> = ({ ...props }: IvyLinkProps) => {
    if (
        typeof props.to != "string" &&
        "pathname" in (props.to as LocationDescriptorObject)
    ) {
        props.to = props.to.pathname as string;
        props = { ...props, target: "_blank", rel: "noopener noreferrer" };
    }

    if (typeof props.to === "string" && props.to.startsWith("mailto:")) {
        props = addOnClick(props, onMailToClick(props.to));
    }

    if (props.event !== undefined) {
        let sendEvent: () => void;

        if (props.parameters != undefined) {
            sendEvent = () =>
                Analytics.event(props.event as IvyEvent, props.parameters);
        } else {
            sendEvent = () => Analytics.event(props.event as IvyEvent);
        }

        props = addOnClick(props, sendEvent);
    }

    return <DomLink {...props} />;
};

function addOnClick(
    props: IvyLinkProps,
    onClick: MouseEventHandler
): IvyLinkProps {
    if (typeof props.onClick === "undefined") {
        return { ...props, onClick };
    }

    const existingOnClick = props.onClick;

    return {
        ...props,
        onClick: (e) => {
            existingOnClick(e);
            onClick(e);
        },
    };
}
