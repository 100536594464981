/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cell, UseRowSelectInstanceProps } from "react-table";

export interface AlertsState {
    paginatedAlerts: PaginatedAlerts;
    totalPages: number;
}

export interface PaginatedAlerts {
    [key: number]: AlertRow[];
}

export interface AlertRow {
    id: number;
    scanId: string;
    alertName: string;
    device: string;
    alertType: string;
    severity: string;
    created: string;
    alertStatus: string;
    scanTime: string;
    scanMethod: string;
    deviceName: string;
    alertDescription: string;
    memberName: string;
}

export interface AlertDescription {
    threat: string;
}

export type AlertsConsoleData = {
    alerts: AlertRow[];
    totalPages: number;
};

export type AlertTableFilters = {
    status?: StatusFilter;
};

export enum AlertFilters {
    STATUS = "status",
}

export enum StatusFilter {
    OPEN = "open",
    CLOSED = "closed",
    NONE = "none",
}

export enum AlertStatus {
    OPEN = "Open",
    CLOSED = "Closed",
}

export enum SeverityLevel {
    CRITICAL = "Critical",
    HIGH = "High",
    SUSPICIOUS = "Suspicious",
    LOW = "Low",
}

export enum AlertActions {
    SELECT = "select",
    CLOSE_ALERTS = "closeAlerts",
    OPEN_ALERTS = "openAlerts",
}

type HeaderFn = (
    headerProps: UseRowSelectInstanceProps<AlertRow>
) => JSX.Element;
type CellFn = (cell: Cell<AlertRow>) => JSX.Element;

// from https://react-table.tanstack.com/docs/api/useTable#column-options
export interface TableColumn {
    accessor: string | any;
    id: string; // used for css styling; Also required if accessor is a fn
    Header: string | HeaderFn | React.Component; // defaults to () => null
    Cell?: string | CellFn; // defaults to ({ value }) => String(value)
    canSort?: boolean; // Can the column be sorted
    sortType?: (
        rowA: TableRowJSX,
        rowB: TableRowJSX,
        columnId: string
    ) => number;
    defaultCanSort?: boolean;
    disableSortBy?: boolean;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
}

export interface TableRowJSX {
    values: Record<string, { props: { [key: string]: any } }>;
}
